
function AddNewPackage() {
  return (
    <>
      <div className="aiz-main-content">
        <div className="px-15px px-lg-25px">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="card">
                <div className="card-header">
                  <h5 className="mb-0 h6">Add New Package</h5>
                </div>
                <div className="card-body">
                  <form action="https://weddemoadmin.abaris.in/admin/packages" method="POST" encType="multipart/form-data">
                    <input type="hidden" name="_token" defaultValue="hq3uL6CkxFTUAe4mX5wEqlmtHPZPx6OuCEKMwlFv" />                        <div className="form-group row">
                      <label className="col-md-3 col-form-label" htmlFor="name">Name</label>
                      <div className="col-md-9">
                        <input type="text" name="name" id="name" className="form-control" placeholder="Package Name" required />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label" htmlFor="price">Price</label>
                      <div className="col-md-9">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              $                                    </span>
                          </div>
                          <input type="number" name="price" id="price" className="form-control" placeholder="Price" min={0} required />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label">Package Image</label>
                      <div className="col-md-9">
                        <div className="input-group input-group-sm" data-toggle="aizuploader" data-type="image">
                          <div className="input-group-prepend">
                            <div className="input-group-text bg-soft-secondary font-weight-medium">Browse</div>
                          </div>
                          <div className="form-control file-amount">Choose File</div>
                          <input type="hidden" name="package_image" className="selected-files" />
                        </div>
                        <div className="file-preview box" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label" htmlFor="express_interest">Express Interest</label>
                      <div className="col-md-9">
                        <input type="number" name="express_interest" id="express_interest" className="form-control" placeholder="Eg. 10" min={0} required />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label" htmlFor="photo_gallery">Gallery Photo Upload</label>
                      <div className="col-md-9">
                        <input type="number" name="photo_gallery" id="photo_gallery" className="form-control" placeholder="Eg. 10" required />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label" htmlFor="contact">Contact Info View</label>
                      <div className="col-md-9">
                        <input type="number" name="contact" id="contact" className="form-control" placeholder="Eg. 10" required />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label" htmlFor="profile_image_view">Profile Image View</label>
                      <div className="col-md-9">
                        <input type="number" name="profile_image_view" defaultValue={0} id="profile_image_view" className="form-control" readOnly />
                        <small>This will work when the profile picture privacy is set as only me.</small>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label" htmlFor="gallery_image_view">Gallery Image View</label>
                      <div className="col-md-9">
                        <input type="number" name="gallery_image_view" defaultValue={0} id="gallery_image_view" className="form-control" readOnly />
                        <small>This will work when the gallery image privacy is set as only me.</small>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label">Validity For</label>
                      <div className="col-md-9">
                        <div className="input-group">
                          <input type="number" name="validity" className="form-control" placeholder="Eg. 30" min={0} required />
                          <div className="input-group-prepend"><span className="input-group-text">Days</span></div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label">Auto Profile Matching Show</label>
                      <div className="col-md-8 mt-3">
                        <label className="aiz-switch aiz-switch-success mb-0">
                          <input type="checkbox" defaultChecked="checked" name="auto_profile_match" />
                          <span />
                        </label>
                      </div>
                    </div>
                    <div className="form-group mb-3 text-right">
                      <button type="submit" className="btn btn-primary">Add New Package</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
export default AddNewPackage;