import PremiumPackages from "../../Components/premiumPackages/PremiumPackages";


function PremiumPackagesPage() {
  return (
    <>
      <PremiumPackages/>
    </>
  )
}
export default PremiumPackagesPage;