

function EditPackageInfo() {
  return (
    <>
      <div className="aiz-main-content">
        <div className="px-15px px-lg-25px">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="card">
                <div className="card-header">
                  <h5 className="mb-0 h6">Edit Package Info</h5>
                </div>
                <div className="card-body">
                  <form action="https://weddemoadmin.abaris.in/admin/packages/4" method="POST" encType="multipart/form-data">
                    <input name="_method" type="hidden" defaultValue="PATCH" />
                    <input type="hidden" name="_token" defaultValue="eNJhbdkEMu4D498sV9cRd0FRXFdbQCRUEeNzC3zL" />                        <div className="form-group row">
                      <label className="col-md-3 col-form-label">Name</label>
                      <div className="col-md-9">
                        <input type="text" name="name" defaultValue="Gold Package" className="form-control" placeholder="Package Name" required />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label">Price</label>
                      <div className="col-md-9">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              $                                    </span>
                          </div>
                          <input type="number" name="price" defaultValue={50} className="form-control" placeholder="Price" min={0} required />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label" htmlFor="signinSrEmail">Package Image</label>
                      <div className="col-md-9">
                        <div className="input-group" data-toggle="aizuploader" data-type="image">
                          <div className="input-group-prepend">
                            <div className="input-group-text bg-soft-secondary font-weight-medium" >Browse</div>
                          </div>
                          <div className="form-control file-amount" >1 File selected</div>
                          <input type="hidden" name="package_image" className="selected-files" defaultValue={182} />

                          {/* MODAL */}

                          {/* MODAL END */}
                        </div>
                        <div className="file-preview box sm">
                          <div className="d-flex justify-content-between align-items-center mt-2 file-preview-item" data-id={182} title="Group 8175.png">
                            <div className="align-items-center align-self-stretch d-flex justify-content-center thumb">
                              <img src="//weddemoadmin.abaris.in/public/uploads/all/RMTKXAZIB1cN2RA8GLgbhs8DmGl0qj01ACRlEFCG.png" className="img-fit" />
                            </div>
                            <div className="col body">
                              <h6 className="d-flex">
                                <span className="text-truncate title">Group 8175</span>
                                <span className="ext">.png</span>
                              </h6>
                              <p>4 KB</p>
                            </div>
                            <div className="remove">
                              <button className="btn btn-sm btn-link remove-attachment" type="button">
                                <i className="la la-close" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label">Express Interest</label>
                      <div className="col-md-9">
                        <input type="number" name="express_interest" defaultValue={50} className="form-control" placeholder="Eg. 10" min={0} required />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label">Photo Gallery</label>
                      <div className="col-md-9">
                        <input type="text" name="photo_gallery" defaultValue={60} className="form-control" placeholder="Eg. 10" required />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label">Contact Info View</label>
                      <div className="col-md-9">
                        <input type="number" name="contact" defaultValue={15} className="form-control" placeholder="Eg. 10" required />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label" htmlFor="profile_image_view">Profile Image View</label>
                      <div className="col-md-9">
                        <input type="number" name="profile_image_view" defaultValue={0} id="profile_image_view" className="form-control" readOnly />
                        <small>This will work when the profile picture privacy is set as only me.</small>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label" htmlFor="gallery_image_view">Gallery Image View</label>
                      <div className="col-md-9">
                        <input type="number" name="gallery_image_view" defaultValue={0} id="gallery_image_view" className="form-control" readOnly />
                        <small>This will work when the gallery image privacy is set as only me.</small>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label">Validity for</label>
                      <div className="col-md-9">
                        <div className="input-group">
                          <input type="number" name="validity" defaultValue={90} className="form-control" placeholder="Eg. 30" min={0} required />
                          <div className="input-group-prepend"><span className="input-group-text">Days</span></div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label">Auto Profile Matching Show</label>
                      <div className="col-md-8 mt-3">
                        <label className="aiz-switch aiz-switch-success mb-0">
                          <input type="checkbox" name="auto_profile_match" defaultChecked />
                          <span />
                        </label>
                      </div>
                    </div>
                    <div className="form-group mb-3 text-right">
                      <button type="submit" className="btn btn-primary">Update Package Info</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <div className="bg-white text-center py-3 px-15px px-lg-25px mt-auto">
          <p className="mb-0">© testing v3.6</p>
        </div>
      </div>
    </>
  )
}
export default EditPackageInfo;