import React from 'react'

function MembersDetail() {
    return (
        <>
            <div className="aiz-main-content">
                <div className="px-15px px-lg-25px">
                    <div className="aiz-titlebar mt-2 mb-4">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h1 className="h3">Member Details</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="card">
                                <div className="card-body text-center">
                                    <span className="avatar avatar-xl m-3 center">
                                        <img src="https://weddemoadmin.abaris.in/public/assets/img/avatar-place.png" />
                                    </span>
                                    <p>Cairo Carney</p>
                                    <div className="pad-ver btn-groups">
                                        <a href="javascript:void(0);" onclick="package_info(47)" className="btn btn-info btn-sm add-tooltip">Package</a>
                                        <a href="javascript:void(0);" onclick="block_member(47)" className="btn btn-dark btn-sm add-tooltip">Block</a>
                                        <br /><br />
                                        <span className="badge badge-inline badge-success">Active Account</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            {/* Introduction */}
                            <div className="card">
                                <div className="card-header bg-dark text-white">
                                    <h5 className="mb-0 h6">Introduction</h5>
                                </div>
                                <div className="card-body">
                                    <p />
                                </div>
                            </div>
                            {/* Basic Information */}
                            <div className="card">
                                <div className="card-header bg-dark text-white">
                                    <h5 className="mb-0 h6">Basic Information</h5>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <tbody><tr>
                                            <th>First Name</th>
                                            <td>Cairo</td>
                                            <th>Last Name</th>
                                            <td>Carney</td>
                                        </tr>
                                            <tr>
                                                <th>Gender</th>
                                                <td>
                                                    Female
                                                </td>
                                                <th>Date Of Birth</th>
                                                <td>1997-09-15</td>
                                            </tr>
                                            <tr>
                                                <th>Email</th>
                                                <td />
                                                <th>Phone Number</th>
                                                <td>+1+1 (638) 415-7906</td>
                                            </tr>
                                            <tr>
                                                <th>marital_status</th>
                                                <td />
                                                <th>Number Of Children</th>
                                                <td />
                                            </tr>
                                            <tr>
                                                <th>On Behalf</th>
                                                <td>Sister</td>
                                            </tr>
                                        </tbody></table>
                                </div>
                            </div>
                            {/* Present Address */}
                            <div className="card">
                                <div className="card-header bg-dark text-white">
                                    <h5 className="mb-0 h6">Present Address</h5>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <tbody><tr>
                                            <th>City</th>
                                            <td />
                                            <th>State</th>
                                            <td />
                                        </tr>
                                            <tr>
                                                <th>Country</th>
                                                <td />
                                                <th>Postal Code</th>
                                                <td />
                                            </tr>
                                        </tbody></table>
                                </div>
                            </div>
                            {/* Education */}
                            <div className="card">
                                <div className="card-header bg-dark text-white">
                                    <h5 className="mb-0 h6">Education</h5>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <tbody><tr>
                                            <th>Degree</th>
                                            <th>Institution</th>
                                            <th>Start</th>
                                            <th>End</th>
                                            <th>Status</th>
                                        </tr>
                                        </tbody></table>
                                </div>
                            </div>
                            {/* Career */}
                            <div className="card">
                                <div className="card-header bg-dark text-white">
                                    <h5 className="mb-0 h6">Career</h5>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <tbody><tr>
                                            <th>designation</th>
                                            <th>company</th>
                                            <th>Start</th>
                                            <th>End</th>
                                            <th>Status</th>
                                        </tr>
                                        </tbody></table>
                                </div>
                            </div>
                            {/* Physical Attributes */}
                            <div className="card">
                                <div className="card-header bg-dark text-white">
                                    <h5 className="mb-0 h6">Physical Attributes</h5>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <tbody><tr>
                                            <th>Height</th>
                                            <td />
                                            <th>Weight</th>
                                            <td />
                                        </tr>
                                            <tr>
                                                <th>Eye Color</th>
                                                <td />
                                                <th>Hair Color</th>
                                                <td />
                                            </tr>
                                            <tr>
                                                <th>Complexion</th>
                                                <td />
                                                <th>Blood Group</th>
                                                <td />
                                            </tr>
                                            <tr>
                                                <th>Body Type</th>
                                                <td />
                                                <th>Body Art</th>
                                                <td />
                                            </tr>
                                            <tr>
                                                <th>Disability</th>
                                                <td />
                                            </tr>
                                        </tbody></table>
                                </div>
                            </div>
                            {/* Language */}
                            <div className="card">
                                <div className="card-header bg-dark text-white">
                                    <h5 className="mb-0 h6">Language</h5>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <tbody><tr>
                                            <th>Mother Tangue</th>
                                            <td>
                                            </td>
                                            <th>Known Languages</th>
                                            <td>
                                            </td>
                                        </tr>
                                        </tbody></table>
                                </div>
                            </div>
                            {/* Hobbies  */}
                            <div className="card">
                                <div className="card-header bg-dark text-white">
                                    <h5 className="mb-0 h6">Hobbies &amp; Interest</h5>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <tbody><tr>
                                            <th>Hobbies</th>
                                            <td />
                                            <th>Interests</th>
                                            <td />
                                        </tr>
                                            <tr>
                                                <th>Music</th>
                                                <td />
                                                <th>Books</th>
                                                <td />
                                            </tr>
                                            <tr>
                                                <th>Movies</th>
                                                <td />
                                                <th>TV Shows</th>
                                                <td />
                                            </tr>
                                            <tr>
                                                <th>Sports</th>
                                                <td />
                                                <th>Fitness Activities</th>
                                                <td />
                                            </tr>
                                            <tr>
                                                <th>Cuisines</th>
                                                <td />
                                                <th>Dress Styles</th>
                                                <td />
                                            </tr>
                                        </tbody></table>
                                </div>
                            </div>
                            {/* Personal Attitude & Behavior */}
                            <div className="card">
                                <div className="card-header bg-dark text-white">
                                    <h5 className="mb-0 h6">Personal Attitude &amp; Behavior</h5>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <tbody><tr>
                                            <th>Affection</th>
                                            <td />
                                            <th>Humor</th>
                                            <td />
                                        </tr>
                                            <tr>
                                                <th>Political Views</th>
                                                <td />
                                                <th>Religious Service</th>
                                                <td />
                                            </tr>
                                        </tbody></table>
                                </div>
                            </div>
                            {/* Residency Information */}
                            <div className="card">
                                <div className="card-header bg-dark text-white">
                                    <h5 className="mb-0 h6">Residency Information</h5>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <tbody><tr>
                                            <th>Birth Country</th>
                                            <td>
                                            </td>
                                            <th>Recidency Country</th>
                                            <td>
                                            </td>
                                        </tr>
                                            <tr>
                                                <th>Growup Country</th>
                                                <td>
                                                </td>
                                                <th>Immigration Status</th>
                                                <td />
                                            </tr>
                                        </tbody></table>
                                </div>
                            </div>
                            {/* Spiritual & Social Background */}
                            <div className="card">
                                <div className="card-header bg-dark text-white">
                                    <h5 className="mb-0 h6">Spiritual &amp; Social Background</h5>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <tbody><tr>
                                            <th>Religion</th>
                                            <td />
                                            <th>Caste</th>
                                            <td />
                                        </tr>
                                            <tr>
                                                <th>Sub Caste</th>
                                                <td />
                                                <th>Ethnicity</th>
                                                <td />
                                            </tr>
                                            <tr>
                                                <th>Personal Value</th>
                                                <td />
                                                <th>Family Value</th>
                                                <td />
                                            </tr>
                                            <tr>
                                                <th>Community Value</th>
                                                <td />
                                            </tr>
                                        </tbody></table>
                                </div>
                            </div>
                            {/* Life Style */}
                            <div className="card">
                                <div className="card-header bg-dark text-white">
                                    <h5 className="mb-0 h6">Life Style</h5>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <tbody><tr>
                                            <th>Diet</th>
                                            <td />
                                            <th>Drink</th>
                                            <td />
                                        </tr>
                                            <tr>
                                                <th>Smoke</th>
                                                <td />
                                                <th>Living With</th>
                                                <td />
                                            </tr>
                                        </tbody></table>
                                </div>
                            </div>
                            {/* Astronomic Information  */}
                            <div className="card">
                                <div className="card-header bg-dark text-white">
                                    <h5 className="mb-0 h6">Astronomic Information</h5>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <tbody><tr>
                                            <th>Sun Sign</th>
                                            <td />
                                            <th>Moon Sign</th>
                                            <td />
                                        </tr>
                                            <tr>
                                                <th>Time Of Birth</th>
                                                <td />
                                                <th>City Of Birth</th>
                                                <td />
                                            </tr>
                                        </tbody></table>
                                </div>
                            </div>
                            {/* Permanent Address */}
                            <div className="card">
                                <div className="card-header bg-dark text-white">
                                    <h5 className="mb-0 h6">Permanent Address</h5>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <tbody><tr>
                                            <th>City</th>
                                            <td />
                                            <th>State</th>
                                            <td />
                                        </tr>
                                            <tr>
                                                <th>Country</th>
                                                <td />
                                                <th>Postal Code</th>
                                                <td />
                                            </tr>
                                        </tbody></table>
                                </div>
                            </div>
                            {/* Family Information */}
                            <div className="card">
                                <div className="card-header bg-dark text-white">
                                    <h5 className="mb-0 h6">Family Information</h5>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <tbody><tr>
                                            <th>Father</th>
                                            <td />
                                            <th>Mother</th>
                                            <td />
                                        </tr>
                                            <tr>
                                                <th>Sibling</th>
                                                <td />
                                            </tr>
                                        </tbody></table>
                                </div>
                            </div>
                            {/* Partner Expectation */}
                            <div className="card">
                                <div className="card-header bg-dark text-white">
                                    <h5 className="mb-0 h6">Partner Expectation</h5>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <tbody><tr>
                                            <th>General</th>
                                            <td />
                                            <th>Residence Country</th>
                                            <td>
                                            </td>
                                        </tr>
                                            <tr>
                                                <th>Height</th>
                                                <td />
                                                <th>Weight</th>
                                                <td />
                                            </tr>
                                            <tr>
                                                <th>marital_status</th>
                                                <td />
                                                <th>Children Acceptable</th>
                                                <td />
                                            </tr>
                                            <tr>
                                                <th>Religion</th>
                                                <td />
                                                <th>Caste</th>
                                                <td />
                                            </tr>
                                            <tr>
                                                <th>Sub Caste</th>
                                                <td />
                                                <th>Language</th>
                                                <td />
                                            </tr>
                                            <tr>
                                                <th>Education</th>
                                                <td />
                                                <th>Profession</th>
                                                <td />
                                            </tr>
                                            <tr>
                                                <th>Smoking Acceptable</th>
                                                <td />
                                                <th>Drinking Acceptable</th>
                                                <td />
                                            </tr>
                                            <tr>
                                                <th>Diet</th>
                                                <td />
                                                <th>Body Type</th>
                                                <td />
                                            </tr>
                                            <tr>
                                                <th>Personal Value</th>
                                                <td />
                                                <th>Manglik</th>
                                                <td />
                                            </tr>
                                            <tr>
                                                <th>Preferred Country</th>
                                                <td>
                                                </td>
                                                <th>preferred_state_id</th>
                                                <td>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Family Value</th>
                                                <td />
                                                <th>Complexion</th>
                                                <td />
                                            </tr>
                                        </tbody></table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default MembersDetail