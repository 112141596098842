import ReferalEarning from "../../../Components/referal/referalEarning/ReferalEarning"


function ReferalEarningPage() {
    return (
        <>
        <ReferalEarning/>
        </>
    )
}
export default ReferalEarningPage