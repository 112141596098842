import Form from 'react-bootstrap/Form';

function SendSms() {
  return (
    <>
      <div className="aiz-main-content">
        <div className="px-15px px-lg-25px">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="card">
                <div className="card-header">
                  <h3 className="fs-18 mb-0">Send Bulk SMS</h3>
                </div>

                <form className="form-horizontal" action="https://weddemoadmin.abaris.in/admin/bulk-sms-send" method="POST" encType="multipart/form-data">
                  <input type="hidden" name="_token" defaultValue="hq3uL6CkxFTUAe4mX5wEqlmtHPZPx6OuCEKMwlFv" />                <div className="card-body">
                    <div className="form-group row">
                      <label className="col-sm-2 control-label" htmlFor="name">Mobile Users</label>
                      <div className="col-sm-10">

                        <select name="cars" id="cars" form="carform" className="dropdown  form-control aiz-">
                          <option className=''>
                            <div>helo</div>
                            <div>bro</div>
                          </option>
                          <option value={100200300}>Kimberley Lang - 100200300</option>
                          <option value={100200300}>Matthew Ryan - 100200300</option>
                          <option value={100200300}>Liam Noah - 100200300</option>
                          <option value={100200300}>Olivia Emma - 100200300</option>
                          <option value={100200300}>Idona Mccoy - 100200300</option>
                          <option value={100200300}>Tate Kennedy - 100200300</option>
                          <option value="015-76-XXXX">Lakendra J. Lowe - 015-76-XXXX</option>
                          <option value="209-406-1441">Jane R Lamy - 209-406-1441</option>
                          <option value={100200300}>Mark Henry - 100200300</option>
                          <option value="916-310-1896">Mitchell Johnson - 916-310-1896</option>
                          <option value="+">Wilson Arnold - +</option>
                          <option value="+">Robert L. Gardner - +</option>
                          <option value="+">Kathy T. Litton - +</option>
                          <option value="+">Ramona D. Mears - +</option>
                          <option value="+">Nicole Hruby - +</option>
                          <option value="+">Sylvia J. Love - +</option>
                          <option value="239-663-7517">Donna J. Perryman - 239-663-7517</option>
                          <option value="+1 (213) 692-1498">Derrick L. Lavender - +1 (213) 692-1498</option>
                          <option value="424-341-0156">Alex Reynolds - 424-341-0156</option>
                          <option value="605-795-6871">Tammy J. Nelson Nelson - 605-795-6871</option>
                          <option value="863-623-2972">Patrick S. Cloninger Cloninger - 863-623-2972</option>
                          <option value="+1+1 (638) 415-7906">Cairo Carney - +1+1 (638) 415-7906</option>
                        </select>


                        {/* <Form.Select className="dropdown  form-control aiz-">
                          
                          <option>Nothing selected</option>
                          <option value="1">Kimberley Lang - 100200300</option>
                          <option value="2">Matthew Ryan - 100200300</option>
                          <option value="3">Liam Noah - 100200300</option>
                        </Form.Select> */}

                        {/* <div className="dropdown bootstrap-select show-tick form-control aiz-">
                          <select className="form-control aiz-selectpicker" name="user_phones[]" multiple data-selected-text-format="count" data-actions-box="true" tabIndex={-98}>
                            <option value={100200300}>Kimberley Lang - 100200300</option>
                            <option value={100200300}>Matthew Ryan - 100200300</option>
                            <option value={100200300}>Liam Noah - 100200300</option>
                            <option value={100200300}>Olivia Emma - 100200300</option>
                            <option value={100200300}>Idona Mccoy - 100200300</option>
                            <option value={100200300}>Tate Kennedy - 100200300</option>
                            <option value="015-76-XXXX">Lakendra J. Lowe - 015-76-XXXX</option>
                            <option value="209-406-1441">Jane R Lamy - 209-406-1441</option>
                            <option value={100200300}>Mark Henry - 100200300</option>
                            <option value="916-310-1896">Mitchell Johnson - 916-310-1896</option>
                            <option value="+">Wilson Arnold - +</option>
                            <option value="+">Robert L. Gardner - +</option>
                            <option value="+">Kathy T. Litton - +</option>
                            <option value="+">Ramona D. Mears - +</option>
                            <option value="+">Nicole Hruby - +</option>
                            <option value="+">Sylvia J. Love - +</option>
                            <option value="239-663-7517">Donna J. Perryman - 239-663-7517</option>
                            <option value="+1 (213) 692-1498">Derrick L. Lavender - +1 (213) 692-1498</option>
                            <option value="424-341-0156">Alex Reynolds - 424-341-0156</option>
                            <option value="605-795-6871">Tammy J. Nelson Nelson - 605-795-6871</option>
                            <option value="863-623-2972">Patrick S. Cloninger Cloninger - 863-623-2972</option>
                            <option value="+1+1 (638) 415-7906">Cairo Carney - +1+1 (638) 415-7906</option>
                          </select>

                          <button type="button" className="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown" role="combobox" aria-owns="bs-select-1" aria-haspopup="listbox" aria-expanded="false" title="Nothing selected">
                            <div className="filter-option">
                              <div className="filter-option-inner">
                                <div className="filter-option-inner-inner">Nothing selected</div>
                              </div>
                            </div>
                          </button>

                          <div className="dropdown-menu ">
                            <div className="bs-actionsbox">
                              <div className="btn-group btn-group-sm btn-block">
                                <button type="button" className="actions-btn bs-select-all btn btn-light">Select All</button>
                                <button type="button" className="actions-btn bs-deselect-all btn btn-light">Deselect All</button>
                              </div>
                            </div>
                            <div className="inner show" role="listbox" id="bs-select-1" tabIndex={-1} aria-multiselectable="true">
                              <ul className="dropdown-menu inner show" role="presentation" />
                            </div>
                          </div>

                        </div> */}

                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 control-label" htmlFor="name">SMS content</label>
                      <div className="col-sm-10">
                        <textarea className="form-control" rows={5} name="content" required defaultValue={""} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-2 col-form-label">Template ID</label>
                      <div className="col-md-10">
                        <input type="text" name="template_id" className="form-control" placeholder="Template ID" />
                        <small className="form-text text-danger">**N.B : Template ID is Required Only for Fast2SMS DLT Manual **</small>
                      </div>
                    </div>
                    <div className="form-group mb-0 text-right">
                      <button type="submit" className="btn btn-primary">Send</button>
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
export default SendSms