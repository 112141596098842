import React from 'react'

function EditCityInfo() {
    return (
        <>
            <div className="aiz-main-content">
                <div className="px-15px px-lg-25px">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="mb-0 h6">Edit City Info</h5>
                                </div>
                                <div className="card-body">
                                    <form action="https://weddemoadmin.abaris.in/admin/cities/1" method="POST">
                                        <input name="_method" type="hidden" defaultValue="PATCH" />
                                        <input type="hidden" name="_token" defaultValue="cPIRXzSvxbL64b3stO03NULzK4ie7fIhzzG4YNVj" />                        <div className="form-group mb-3">
                                            <label htmlFor="name">Country</label>
                                            <div className="form-control aiz-">
                                                <select className="form-select" aria-label="Default select example">
                                                    <option selected>India</option>
                                                    <option value={1}>Afghanistan</option>
                                                    <option value={2}>Albania</option>
                                                    <option value={3}>Algeria</option>
                                                    <option value={4}>American Samoa</option>
                                                    <option value={5}>Andorra</option>
                                                    <option value={6}>Angola</option>
                                                    <option value={7}>Anguilla</option>
                                                    <option value={8}>Antarctica</option>
                                                    <option value={9}>Antigua And Barbuda</option>
                                                    <option value={10}>Argentina</option>
                                                    <option value={11}>Armenia</option>
                                                    <option value={12}>Aruba</option>
                                                    <option value={13}>Australia</option>
                                                    <option value={14}>Austria</option>
                                                    <option value={15}>Azerbaijan</option>
                                                    <option value={16}>Bahamas The</option>
                                                    <option value={17}>Bahrain</option>
                                                    <option value={18}>Bangladesh</option>
                                                    <option value={19}>Barbados</option>
                                                    <option value={20}>Belarus</option>
                                                    <option value={21}>Belgium</option>
                                                    <option value={22}>Belize</option>
                                                    <option value={23}>Benin</option>
                                                    <option value={24}>Bermuda</option>
                                                    <option value={25}>Bhutan</option>
                                                    <option value={26}>Bolivia</option>
                                                    <option value={27}>Bosnia and Herzegovina</option>
                                                    <option value={28}>Botswana</option>
                                                    <option value={29}>Bouvet Island</option>
                                                    <option value={30}>Brazil</option>
                                                    <option value={31}>British Indian Ocean Territory</option>
                                                    <option value={32}>Brunei</option>
                                                    <option value={33}>Bulgaria</option>
                                                    <option value={34}>Burkina Faso</option>
                                                    <option value={35}>Burundi</option>
                                                    <option value={36}>Cambodia</option>
                                                    <option value={37}>Cameroon</option>
                                                    <option value={38}>Canada</option>
                                                    <option value={39}>Cape Verde</option>
                                                    <option value={40}>Cayman Islands</option>
                                                    <option value={41}>Central African Republic</option>
                                                    <option value={42}>Chad</option>
                                                    <option value={43}>Chile</option>
                                                    <option value={44}>China</option>
                                                    <option value={45}>Christmas Island</option>
                                                    <option value={46}>Cocos (Keeling) Islands</option>
                                                    <option value={47}>Colombia</option>
                                                    <option value={48}>Comoros</option>
                                                    <option value={49}>Republic Of The Congo</option>
                                                    <option value={50}>Democratic Republic Of The Congo</option>
                                                    <option value={51}>Cook Islands</option>
                                                    <option value={52}>Costa Rica</option>
                                                    <option value={53}>Cote D'Ivoire (Ivory Coast)</option>
                                                    <option value={54}>Croatia (Hrvatska)</option>
                                                    <option value={55}>Cuba</option>
                                                    <option value={56}>Cyprus</option>
                                                    <option value={57}>Czech Republic</option>
                                                    <option value={58}>Denmark</option>
                                                    <option value={59}>Djibouti</option>
                                                    <option value={60}>Dominica</option>
                                                    <option value={61}>Dominican Republic</option>
                                                    <option value={62}>East Timor</option>
                                                    <option value={63}>Ecuador</option>
                                                    <option value={64}>Egypt</option>
                                                    <option value={65}>El Salvador</option>
                                                    <option value={66}>Equatorial Guinea</option>
                                                    <option value={67}>Eritrea</option>
                                                    <option value={68}>Estonia</option>
                                                    <option value={69}>Ethiopia</option>
                                                    <option value={70}>External Territories of Australia</option>
                                                    <option value={71}>Falkland Islands</option>
                                                    <option value={72}>Faroe Islands</option>
                                                    <option value={73}>Fiji Islands</option>
                                                    <option value={74}>Finland</option>
                                                    <option value={75}>France</option>
                                                    <option value={76}>French Guiana</option>
                                                    <option value={77}>French Polynesia</option>
                                                    <option value={78}>French Southern Territories</option>
                                                    <option value={79}>Gabon</option>
                                                    <option value={80}>Gambia The</option>
                                                    <option value={81}>Georgia</option>
                                                    <option value={82}>Germany</option>
                                                    <option value={83}>Ghana</option>
                                                    <option value={84}>Gibraltar</option>
                                                    <option value={85}>Greece</option>
                                                    <option value={86}>Greenland</option>
                                                    <option value={87}>Grenada</option>
                                                    <option value={88}>Guadeloupe</option>
                                                    <option value={89}>Guam</option>
                                                    <option value={90}>Guatemala</option>
                                                    <option value={91}>Guernsey and Alderney</option>
                                                    <option value={92}>Guinea</option>
                                                    <option value={93}>Guinea-Bissau</option>
                                                    <option value={94}>Guyana</option>
                                                    <option value={95}>Haiti</option>
                                                    <option value={96}>Heard and McDonald Islands</option>
                                                    <option value={97}>Honduras</option>
                                                    <option value={98}>Hong Kong S.A.R.</option>
                                                    <option value={99}>Hungary</option>
                                                    <option value={100}>Iceland</option>
                                                    <option value={101}>India</option>
                                                    <option value={102}>Indonesia</option>
                                                    <option value={103}>Iran</option>
                                                    <option value={104}>Iraq</option>
                                                    <option value={105}>Ireland</option>
                                                    <option value={106}>Israel</option>
                                                    <option value={107}>Italy</option>
                                                    <option value={108}>Jamaica</option>
                                                    <option value={109}>Japan</option>
                                                    <option value={110}>Jersey</option>
                                                    <option value={111}>Jordan</option>
                                                    <option value={112}>Kazakhstan</option>
                                                    <option value={113}>Kenya</option>
                                                    <option value={114}>Kiribati</option>
                                                    <option value={115}>Korea North</option>
                                                    <option value={116}>Korea South</option>
                                                    <option value={117}>Kuwait</option>
                                                    <option value={118}>Kyrgyzstan</option>
                                                    <option value={119}>Laos</option>
                                                    <option value={120}>Latvia</option>
                                                    <option value={121}>Lebanon</option>
                                                    <option value={122}>Lesotho</option>
                                                    <option value={123}>Liberia</option>
                                                    <option value={124}>Libya</option>
                                                    <option value={125}>Liechtenstein</option>
                                                    <option value={126}>Lithuania</option>
                                                    <option value={127}>Luxembourg</option>
                                                    <option value={128}>Macau S.A.R.</option>
                                                    <option value={129}>Macedonia</option>
                                                    <option value={130}>Madagascar</option>
                                                    <option value={131}>Malawi</option>
                                                    <option value={132}>Malaysia</option>
                                                    <option value={133}>Maldives</option>
                                                    <option value={134}>Mali</option>
                                                    <option value={135}>Malta</option>
                                                    <option value={136}>Man (Isle of)</option>
                                                    <option value={137}>Marshall Islands</option>
                                                    <option value={138}>Martinique</option>
                                                    <option value={139}>Mauritania</option>
                                                    <option value={140}>Mauritius</option>
                                                    <option value={141}>Mayotte</option>
                                                    <option value={142}>Mexico</option>
                                                    <option value={143}>Micronesia</option>
                                                    <option value={144}>Moldova</option>
                                                    <option value={145}>Monaco</option>
                                                    <option value={146}>Mongolia</option>
                                                    <option value={147}>Montserrat</option>
                                                    <option value={148}>Morocco</option>
                                                    <option value={149}>Mozambique</option>
                                                    <option value={150}>Myanmar</option>
                                                    <option value={151}>Namibia</option>
                                                    <option value={152}>Nauru</option>
                                                    <option value={153}>Nepal</option>
                                                    <option value={154}>Netherlands Antilles</option>
                                                    <option value={155}>Netherlands The</option>
                                                    <option value={156}>New Caledonia</option>
                                                    <option value={157}>New Zealand</option>
                                                    <option value={158}>Nicaragua</option>
                                                    <option value={159}>Niger</option>
                                                    <option value={160}>Nigeria</option>
                                                    <option value={161}>Niue</option>
                                                    <option value={162}>Norfolk Island</option>
                                                    <option value={163}>Northern Mariana Islands</option>
                                                    <option value={164}>Norway</option>
                                                    <option value={165}>Oman</option>
                                                    <option value={166}>Pakistan</option>
                                                    <option value={167}>Palau</option>
                                                    <option value={168}>Palestinian Territory Occupied</option>
                                                    <option value={169}>Panama</option>
                                                    <option value={170}>Papua new Guinea</option>
                                                    <option value={171}>Paraguay</option>
                                                    <option value={172}>Peru</option>
                                                    <option value={173}>Philippines</option>
                                                    <option value={174}>Pitcairn Island</option>
                                                    <option value={175}>Poland</option>
                                                    <option value={176}>Portugal</option>
                                                    <option value={177}>Puerto Rico</option>
                                                    <option value={178}>Qatar</option>
                                                    <option value={179}>Reunion</option>
                                                    <option value={180}>Romania</option>
                                                    <option value={181}>Russia</option>
                                                    <option value={182}>Rwanda</option>
                                                    <option value={183}>Saint Helena</option>
                                                    <option value={184}>Saint Kitts And Nevis</option>
                                                    <option value={185}>Saint Lucia</option>
                                                    <option value={186}>Saint Pierre and Miquelon</option>
                                                    <option value={187}>Saint Vincent And The Grenadines</option>
                                                    <option value={188}>Samoa</option>
                                                    <option value={189}>San Marino</option>
                                                    <option value={190}>Sao Tome and Principe</option>
                                                    <option value={191}>Saudi Arabia</option>
                                                    <option value={192}>Senegal</option>
                                                    <option value={193}>Serbia</option>
                                                    <option value={194}>Seychelles</option>
                                                    <option value={195}>Sierra Leone</option>
                                                    <option value={196}>Singapore</option>
                                                    <option value={197}>Slovakia</option>
                                                    <option value={198}>Slovenia</option>
                                                    <option value={199}>Smaller Territories of the UK</option>
                                                    <option value={200}>Solomon Islands</option>
                                                    <option value={201}>Somalia</option>
                                                    <option value={202}>South Africa</option>
                                                    <option value={203}>South Georgia</option>
                                                    <option value={204}>South Sudan</option>
                                                    <option value={205}>Spain</option>
                                                    <option value={206}>Sri Lanka</option>
                                                    <option value={207}>Sudan</option>
                                                    <option value={208}>Suriname</option>
                                                    <option value={209}>Svalbard And Jan Mayen Islands</option>
                                                    <option value={210}>Swaziland</option>
                                                    <option value={211}>Sweden</option>
                                                    <option value={212}>Switzerland</option>
                                                    <option value={213}>Syria</option>
                                                    <option value={214}>Taiwan</option>
                                                    <option value={215}>Tajikistan</option>
                                                    <option value={216}>Tanzania</option>
                                                    <option value={217}>Thailand</option>
                                                    <option value={218}>Togo</option>
                                                    <option value={219}>Tokelau</option>
                                                    <option value={220}>Tonga</option>
                                                    <option value={221}>Trinidad And Tobago</option>
                                                    <option value={222}>Tunisia</option>
                                                    <option value={223}>Turkey</option>
                                                    <option value={224}>Turkmenistan</option>
                                                    <option value={225}>Turks And Caicos Islands</option>
                                                    <option value={226}>Tuvalu</option>
                                                    <option value={227}>Uganda</option>
                                                    <option value={228}>Ukraine</option>
                                                    <option value={229}>United Arab Emirates</option>
                                                    <option value={230}>United Kingdom</option>
                                                    <option value={231}>United States</option>
                                                    <option value={232}>United States Minor Outlying Islands</option>
                                                    <option value={233}>Uruguay</option>
                                                    <option value={234}>Uzbekistan</option>
                                                    <option value={235}>Vanuatu</option>
                                                    <option value={236}>Vatican City State (Holy See)</option>
                                                    <option value={237}>Venezuela</option>
                                                    <option value={238}>Vietnam</option>
                                                    <option value={239}>Virgin Islands (British)</option>
                                                    <option value={240}>Virgin Islands (US)</option>
                                                    <option value={241}>Wallis And Futuna Islands</option>
                                                    <option value={242}>Western Sahara</option>
                                                    <option value={243}>Yemen</option>
                                                    <option value={244}>Yugoslavia</option>
                                                    <option value={245}>Zambia</option>
                                                    <option value={246}>Zimbabwe</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="name">State</label>
                                            <div className="form-control aiz-">
                                                <select className="form-select" aria-label="Default select example">
                                                    <option selected>Delhi</option>
                                                    <option value={10}>jharkhand</option>
                                                    <option value={11}>Bengal</option>
                                                    <option value={12}>Assam</option>
                                                    <option value={13}>Australia</option>
                                                    <option value={14}>Austria</option>
                                                    <option value={15}>Azerbaijan</option>
                                                    <option value={16}>Bahamas The</option>
                                                    <option value={17}>Bahrain</option>
                                                    <option value={18}>Bangladesh</option>
                                                    <option value={19}>Barbados</option>
                                                    <option value={20}>Belarus</option>
                                                    <option value={21}>Belgium</option>
                                                    <option value={22}>Belize</option>
                                                    <option value={23}>Benin</option>
                                                    <option value={24}>Bermuda</option>
                                                    <option value={25}>Bhutan</option>
                                                    <option value={26}>Bolivia</option>
                                                    <option value={27}>Bosnia and Herzegovina</option>
                                                    <option value={28}>Botswana</option>
                                                    <option value={29}>Bouvet Island</option>
                                                    <option value={30}>Brazil</option>
                                                    <option value={31}>British Indian Ocean Territory</option>
                                                    <option value={32}>Brunei</option>
                                                    <option value={33}>Bulgaria</option>
                                                    <option value={34}>Burkina Faso</option>
                                                    <option value={35}>Burundi</option>
                                                    <option value={36}>Cambodia</option>
                                                    <option value={37}>Cameroon</option>
                                                    <option value={38}>Canada</option>
                                                    <option value={39}>Cape Verde</option>
                                                    <option value={40}>Cayman Islands</option>
                                                    <option value={41}>Central African Republic</option>
                                                    <option value={42}>Chad</option>
                                                    <option value={43}>Chile</option>
                                                    <option value={44}>China</option>
                                                    <option value={45}>Christmas Island</option>
                                                    <option value={46}>Cocos (Keeling) Islands</option>
                                                    <option value={47}>Colombia</option>
                                                    <option value={48}>Comoros</option>
                                                    <option value={49}>Republic Of The Congo</option>
                                                    <option value={50}>Democratic Republic Of The Congo</option>
                                                    <option value={51}>Cook Islands</option>
                                                    <option value={52}>Costa Rica</option>
                                                    <option value={53}>Cote D'Ivoire (Ivory Coast)</option>
                                                    <option value={54}>Croatia (Hrvatska)</option>
                                                    <option value={55}>Cuba</option>
                                                    <option value={56}>Cyprus</option>
                                                    <option value={57}>Czech Republic</option>
                                                    <option value={58}>Denmark</option>
                                                    <option value={59}>Djibouti</option>
                                                    <option value={60}>Dominica</option>
                                                    <option value={61}>Dominican Republic</option>
                                                    <option value={62}>East Timor</option>
                                                    <option value={63}>Ecuador</option>
                                                    <option value={64}>Egypt</option>
                                                    <option value={65}>El Salvador</option>
                                                    <option value={66}>Equatorial Guinea</option>
                                                    <option value={67}>Eritrea</option>
                                                    <option value={68}>Estonia</option>
                                                    <option value={69}>Ethiopia</option>
                                                    <option value={70}>External Territories of Australia</option>
                                                    <option value={71}>Falkland Islands</option>
                                                    <option value={72}>Faroe Islands</option>
                                                    <option value={73}>Fiji Islands</option>
                                                    <option value={74}>Finland</option>
                                                    <option value={75}>France</option>
                                                    <option value={76}>French Guiana</option>
                                                    <option value={77}>French Polynesia</option>
                                                    <option value={78}>French Southern Territories</option>
                                                    <option value={79}>Gabon</option>
                                                    <option value={80}>Gambia The</option>
                                                    <option value={81}>Georgia</option>
                                                    <option value={82}>Germany</option>
                                                    <option value={83}>Ghana</option>
                                                    <option value={84}>Gibraltar</option>
                                                    <option value={85}>Greece</option>
                                                    <option value={86}>Greenland</option>
                                                    <option value={87}>Grenada</option>
                                                    <option value={88}>Guadeloupe</option>
                                                    <option value={89}>Guam</option>
                                                    <option value={90}>Guatemala</option>
                                                    <option value={91}>Guernsey and Alderney</option>
                                                    <option value={92}>Guinea</option>
                                                    <option value={93}>Guinea-Bissau</option>
                                                    <option value={94}>Guyana</option>
                                                    <option value={95}>Haiti</option>
                                                    <option value={96}>Heard and McDonald Islands</option>
                                                    <option value={97}>Honduras</option>
                                                    <option value={98}>Hong Kong S.A.R.</option>
                                                    <option value={99}>Hungary</option>
                                                    <option value={100}>Iceland</option>
                                                    <option value={101}>India</option>
                                                    <option value={102}>Indonesia</option>
                                                    <option value={103}>Iran</option>
                                                    <option value={104}>Iraq</option>
                                                    <option value={105}>Ireland</option>
                                                    <option value={106}>Israel</option>
                                                    <option value={107}>Italy</option>
                                                    <option value={108}>Jamaica</option>
                                                    <option value={109}>Japan</option>
                                                    <option value={110}>Jersey</option>
                                                    <option value={111}>Jordan</option>
                                                    <option value={112}>Kazakhstan</option>
                                                    <option value={113}>Kenya</option>
                                                    <option value={114}>Kiribati</option>
                                                    <option value={115}>Korea North</option>
                                                    <option value={116}>Korea South</option>
                                                    <option value={117}>Kuwait</option>
                                                    <option value={118}>Kyrgyzstan</option>
                                                    <option value={119}>Laos</option>
                                                    <option value={120}>Latvia</option>
                                                    <option value={121}>Lebanon</option>
                                                    <option value={122}>Lesotho</option>
                                                    <option value={123}>Liberia</option>
                                                    <option value={124}>Libya</option>
                                                    <option value={125}>Liechtenstein</option>
                                                    <option value={126}>Lithuania</option>
                                                    <option value={127}>Luxembourg</option>
                                                    <option value={128}>Macau S.A.R.</option>
                                                    <option value={129}>Macedonia</option>
                                                    <option value={130}>Madagascar</option>
                                                    <option value={131}>Malawi</option>
                                                    <option value={132}>Malaysia</option>
                                                    <option value={133}>Maldives</option>
                                                    <option value={134}>Mali</option>
                                                    <option value={135}>Malta</option>
                                                    <option value={136}>Man (Isle of)</option>
                                                    <option value={137}>Marshall Islands</option>
                                                    <option value={138}>Martinique</option>
                                                    <option value={139}>Mauritania</option>
                                                    <option value={140}>Mauritius</option>
                                                    <option value={141}>Mayotte</option>
                                                    <option value={142}>Mexico</option>
                                                    <option value={143}>Micronesia</option>
                                                    <option value={144}>Moldova</option>
                                                    <option value={145}>Monaco</option>
                                                    <option value={146}>Mongolia</option>
                                                    <option value={147}>Montserrat</option>
                                                    <option value={148}>Morocco</option>
                                                    <option value={149}>Mozambique</option>
                                                </select>

                                                {/* <select className="form-control aiz-selectpicker" name="state_id" data-live-search="true" id="state_id" required tabIndex={-98}><option value={1}>Andaman and Nicobar Islands</option><option value={2}>Andhra Pradesh</option><option value={3}>Arunachal Pradesh</option><option value={4}>Assam</option><option value={5}>Bihar</option><option value={6}>Chandigarh</option><option value={7}>Chhattisgarh</option><option value={8}>Dadra and Nagar Haveli</option><option value={9}>Daman and Diu</option><option value={10}>Delhi</option><option value={11}>Goa</option><option value={12}>Gujarat</option><option value={13}>Haryana</option><option value={14}>Himachal Pradesh</option><option value={15}>Jammu and Kashmir</option><option value={16}>Jharkhand</option><option value={17}>Karnataka</option><option value={18}>Kenmore</option><option value={19}>Kerala</option><option value={20}>Lakshadweep</option><option value={21}>Madhya Pradesh</option><option value={22}>Maharashtra</option><option value={23}>Manipur</option><option value={24}>Meghalaya</option><option value={25}>Mizoram</option><option value={26}>Nagaland</option><option value={27}>Narora</option><option value={28}>Natwar</option><option value={29}>Odisha</option><option value={30}>Paschim Medinipur</option><option value={31}>Pondicherry</option><option value={32}>Punjab</option><option value={33}>Rajasthan</option><option value={34}>Sikkim</option><option value={35}>Tamil Nadu</option><option value={36}>Telangana</option><option value={37}>Tripura</option><option value={38}>Uttar Pradesh</option><option value={39}>Uttarakhand</option><option value={40}>Vaishali</option><option value={41}>West Bengal</option>
                                                </select>
                                                <button type="button" className="btn dropdown-toggle btn-light" data-toggle="dropdown" role="combobox" aria-owns="bs-select-2" aria-haspopup="listbox" aria-expanded="false" data-id="state_id" title="Andaman and Nicobar Islands" fdprocessedid="7jfm48"><div className="filter-option"><div className="filter-option-inner"><div className="filter-option-inner-inner">Andaman and Nicobar Islands</div></div> </div>
                                                </button>
                                                <div className="dropdown-menu " style={{ overflow: 'hidden', maxHeight: 251 }}><div className="bs-searchbox"><input type="search" className="form-control" autoComplete="off" role="combobox" aria-label="Search" aria-controls="bs-select-2" aria-autocomplete="list" aria-activedescendant="bs-select-2-0" /></div><div className="inner show" role="listbox" id="bs-select-2" tabIndex={-1} style={{ overflowY: 'auto', maxHeight: 185 }}><ul className="dropdown-menu inner show" role="presentation" style={{ marginTop: 0, marginBottom: 0 }}><li className="selected active"><a role="option" className="dropdown-item active selected" id="bs-select-2-0" tabIndex={0} aria-setsize={41} aria-posinset={1} aria-selected="true"><span className="text">Andaman and Nicobar Islands</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-1" tabIndex={0}><span className="text">Andhra Pradesh</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-2" tabIndex={0}><span className="text">Arunachal Pradesh</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-3" tabIndex={0}><span className="text">Assam</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-4" tabIndex={0}><span className="text">Bihar</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-5" tabIndex={0}><span className="text">Chandigarh</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-6" tabIndex={0}><span className="text">Chhattisgarh</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-7" tabIndex={0}><span className="text">Dadra and Nagar Haveli</span>
                                                </a></li><li><a role="option" className="dropdown-item" id="bs-select-2-8" tabIndex={0}><span className="text">Daman and Diu</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-9" tabIndex={0}><span className="text">Delhi</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-10" tabIndex={0}><span className="text">Goa</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-11" tabIndex={0}><span className="text">Gujarat</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-12" tabIndex={0}><span className="text">Haryana</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-13" tabIndex={0}><span className="text">Himachal Pradesh</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-14" tabIndex={0}><span className="text">Jammu and Kashmir</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-15" tabIndex={0}><span className="text">Jharkhand</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-16" tabIndex={0}><span className="text">Karnataka</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-17" tabIndex={0}><span className="text">Kenmore</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-18" tabIndex={0}><span className="text">Kerala</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-19" tabIndex={0}><span className="text">Lakshadweep</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-20" tabIndex={0}><span className="text">Madhya Pradesh</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-21" tabIndex={0}><span className="text">Maharashtra</span>
                                                </a></li><li><a role="option" className="dropdown-item" id="bs-select-2-22" tabIndex={0}><span className="text">Manipur</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-23" tabIndex={0}><span className="text">Meghalaya</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-24" tabIndex={0}><span className="text">Mizoram</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-25" tabIndex={0}><span className="text">Nagaland</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-26" tabIndex={0}><span className="text">Narora</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-27" tabIndex={0}><span className="text">Natwar</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-28" tabIndex={0}><span className="text">Odisha</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-29" tabIndex={0}><span className="text">Paschim Medinipur</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-30" tabIndex={0}><span className="text">Pondicherry</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-31" tabIndex={0}><span className="text">Punjab</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-32" tabIndex={0}><span className="text">Rajasthan</span></a>
                                                    </li><li><a role="option" className="dropdown-item" id="bs-select-2-33" tabIndex={0}><span className="text">Sikkim</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-34" tabIndex={0}><span className="text">Tamil Nadu</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-35" tabIndex={0}><span className="text">Telangana</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-36" tabIndex={0}><span className="text">Tripura</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-37" tabIndex={0}><span className="text">Uttar Pradesh</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-38" tabIndex={0}><span className="text">Uttarakhand</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-39" tabIndex={0}><span className="text">Vaishali</span></a></li><li><a role="option" className="dropdown-item" id="bs-select-2-40" tabIndex={0}><span className="text">West Bengal</span></a></li></ul>
                                                </div>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="name">City Name</label>
                                            <input type="text" id="name" name="name" defaultValue="Bombuflat" className="form-control" required fdprocessedid="x2pys" />
                                        </div>
                                        <div className="form-group mb-3 text-right">
                                            <button type="submit" className="btn btn-primary" fdprocessedid="cg44ri">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer */}
                <div className="bg-white text-center py-3 px-15px px-lg-25px mt-auto">
                    <p className="mb-0">© testing v3.6</p>
                </div>
            </div>

        </>
    )
}

export default EditCityInfo