import React from 'react'
import FreeMembers from '../../../Components/members/freeMembers/FreeMembers'


function FreeMembersPage() {
  return (
    <FreeMembers />
  )
}

export default FreeMembersPage