import React from 'react'
import ThirdPartySetting from '../../../Components/settings/thirdPartySetting/ThirdPartySetting'

function ThirdPartySettingPage() {
    return (
        <>
            <ThirdPartySetting />
        </>
    )
}
export default ThirdPartySettingPage