import Currency from "../../../Components/currency/Currency"


function CurrencyPage() {
    return (
        <>
        <Currency/>
        </>
    )
}
export default CurrencyPage