import { Link } from "react-router-dom";

function Language() {
  return (
    <>
      <div className="aiz-main-content">
        <div className="px-15px px-lg-25px">
          <div className="row">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-header">
                  <h5 className="mb-0 h6">Default Language</h5>
                </div>
                <div className="card-body">
                  <form
                    action="https://weddemoadmin.abaris.in/admin/env_key_update"
                    method="POST"
                  >
                    <input
                      type="hidden"
                      name="_token"
                      defaultValue="V3eUAFlZ4RZMMqWQoLNyu8KJ5Be77CCNc7fweveP"
                    />{" "}
                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label className="col-from-label">
                          Default Language
                        </label>
                      </div>
                      <input
                        type="hidden"
                        name="types[]"
                        defaultValue="DEFAULT_LANGUAGE"
                      />

                      <div className="col-lg-6">
                        <select
                          className="form-select form-control aiz-selectpicker"
                          aria-label="Default select example"
                        >
                          <option selected>English</option>
                          <option value={1}>Bangla</option>
                          <option value={2}>polski</option>
                        </select>
                        {/* <div className="dropdown bootstrap-select form-control aiz-">
                          <select
                            className="form-control aiz-selectpicker"
                            name="DEFAULT_LANGUAGE"
                            tabIndex={-98}
                          >
                            <option value="en" selected>
                              English
                            </option>
                            <option value="bd">Bangla</option>
                            <option value="pl">polski</option>
                          </select>
                          <button
                            type="button"
                            className="btn dropdown-toggle btn-light"
                            data-toggle="dropdown"
                            role="combobox"
                            aria-owns="bs-select-1"
                            aria-haspopup="listbox"
                            aria-expanded="false"
                            title="English"
                          >
                            <div className="filter-option">
                              <div className="filter-option-inner">
                                <div className="filter-option-inner-inner">
                                  English
                                </div>
                              </div>{" "}
                            </div>
                          </button>
                          <div
                            className="dropdown-menu "
                            style={{ overflow: "hidden" }}
                          >
                            <div
                              className="inner show"
                              role="listbox"
                              id="bs-select-1"
                              tabIndex={-1}
                              aria-activedescendant="bs-select-1-0"
                              style={{ overflowY: "auto" }}
                            >
                              <ul
                                className="dropdown-menu inner show"
                                role="presentation"
                                style={{ marginTop: 0, marginBottom: 0 }}
                              >
                                <li className="selected active">
                                  <a
                                    role="option"
                                    className="dropdown-item active selected"
                                    id="bs-select-1-0"
                                    tabIndex={0}
                                    aria-setsize={3}
                                    aria-posinset={1}
                                    aria-selected="true"
                                  >
                                    <span className="text">English</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-1"
                                    tabIndex={0}
                                  >
                                    <span className="text">Bangla</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-2"
                                    tabIndex={0}
                                  >
                                    <span className="text">polski</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="col-lg-3">
                        <button type="submit" className="btn btn-primary">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className=" col-lg-7 ">
              <div className="card">
                <div className="card-header">
                  <h5 className="mb-0 h6">All Languages</h5>
                </div>
                <div className="card-body">
                  <table
                    className="table aiz-table mb-0 footable footable-1 breakpoint-xl"
                    style={{}}
                  >
                    <thead>
                      <tr className="footable-header">
                        <th
                          className="footable-first-visible"
                          style={{ display: "table-cell" }}
                        >
                          #
                        </th>
                        <th style={{ display: "table-cell" }}>Name</th>
                        <th style={{ display: "table-cell" }}>Code</th>
                        <th style={{ display: "table-cell" }}>RTL</th>
                        <th
                          className="text-right footable-last-visible"
                          width="20%"
                          style={{ display: "table-cell" }}
                        >
                          Options
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          className="footable-first-visible"
                          style={{ display: "table-cell" }}
                        >
                          1
                        </td>
                        <td style={{ display: "table-cell" }}>English</td>
                        <td style={{ display: "table-cell" }}>en</td>
                        <td style={{ display: "table-cell" }}>
                          <label className="aiz-switch aiz-switch-success mb-0">
                            <input
                              onchange="update_rtl_status(this)"
                              defaultValue={1}
                              type="checkbox"
                            />
                            <span className="slider round" />
                          </label>
                        </td>
                        <td
                          className="text-right footable-last-visible"
                          style={{ display: "table-cell" }}
                        >
                          <Link
                            className="btn btn-soft-info btn-icon btn-circle btn-sm"
                            to="/admin/inner/languages"
                            title="Translation"
                          >
                            <i className="las la-language" />
                          </Link>
                          <Link
                            className="btn btn-soft-primary btn-icon btn-circle btn-sm"
                            to="/admin/languages/edit"
                            title="Edit"
                          >
                            <i className="las la-edit" />
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="footable-first-visible"
                          style={{ display: "table-cell" }}
                        >
                          2
                        </td>
                        <td style={{ display: "table-cell" }}>Bangla</td>
                        <td style={{ display: "table-cell" }}>bd</td>
                        <td style={{ display: "table-cell" }}>
                          <label className="aiz-switch aiz-switch-success mb-0">
                            <input
                              onchange="update_rtl_status(this)"
                              defaultValue={2}
                              type="checkbox"
                            />
                            <span className="slider round" />
                          </label>
                        </td>
                        <td
                          className="text-right footable-last-visible"
                          style={{ display: "table-cell" }}
                        >
                          <Link
                            className="btn btn-soft-info btn-icon btn-circle btn-sm"
                            to="/admin/inner/languages"
                            title="Translation"
                          >
                            <i className="las la-language" />
                          </Link>
                          <Link
                            className="btn btn-soft-primary btn-icon btn-circle btn-sm"
                            to="/admin/languages/edit"
                            title="Edit"
                          >
                            <i className="las la-edit" />
                          </Link>
                          <a
                            href="javascript:void(0);"
                            className="btn btn-soft-danger btn-icon btn-circle btn-sm confirm-delete"
                            data-href="https://weddemoadmin.abaris.in/admin/languages/destroy/2"
                            title="Delete"
                          >
                            <i className="las la-trash" />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="footable-first-visible"
                          style={{ display: "table-cell" }}
                        >
                          3
                        </td>
                        <td style={{ display: "table-cell" }}>polski</td>
                        <td style={{ display: "table-cell" }}>pl</td>
                        <td style={{ display: "table-cell" }}>
                          <label className="aiz-switch aiz-switch-success mb-0">
                            <input
                              onchange="update_rtl_status(this)"
                              defaultValue={6}
                              type="checkbox"
                              defaultChecked
                            />
                            <span className="slider round" />
                          </label>
                        </td>
                        <td
                          className="text-right footable-last-visible"
                          style={{ display: "table-cell" }}
                        >
                          <Link
                            className="btn btn-soft-info btn-icon btn-circle btn-sm"
                            to="/admin/inner/languages"
                            title="Translation"
                          >
                            <i className="las la-language" />
                          </Link>
                          <a
                            className="btn btn-soft-primary btn-icon btn-circle btn-sm"
                            href="https://weddemoadmin.abaris.in/admin/languages/6/edit"
                            title="Edit"
                          >
                            <i className="las la-edit" />
                          </a>
                          <a
                            href="javascript:void(0);"
                            className="btn btn-soft-danger btn-icon btn-circle btn-sm confirm-delete"
                            data-href="https://weddemoadmin.abaris.in/admin/languages/destroy/6"
                            title="Delete"
                          >
                            <i className="las la-trash" />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="aiz-pagination"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="card">
                <div className="card-header">
                  <h5 className="mb-0 h6">Add New Language</h5>
                </div>
                <div className="card-body">
                  <form
                    action="https://weddemoadmin.abaris.in/admin/languages"
                    method="POST"
                  >
                    <input
                      type="hidden"
                      name="_token"
                      defaultValue="V3eUAFlZ4RZMMqWQoLNyu8KJ5Be77CCNc7fweveP"
                    />{" "}
                    <div className="form-group row">
                      <div className="col-lg-2">
                        <label className="control-label">Name</label>
                      </div>
                      <div className="col-lg-10">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Eg. English"
                          className="form-control "
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-2">
                        <label className="control-label">Code</label>
                      </div>
                      <div className="col-lg-10">
                        <select
                          class="form-select form-control aiz-selectpicker mb-2 mb-md-0"
                          aria-label="Default select example"
                        >
                          <option selected>AD</option>
                          <option value="2">AE</option>
                          <option value="3">AF</option>
                          <option value="2">AG</option>
                          <option value="3">AL</option>
                        </select>
                        {/* <div className="dropdown bootstrap-select form-control aiz- mb-2 mb-md-0 dropup">
                          <select
                            className="form-control aiz-selectpicker mb-2 mb-md-0"
                            name="code"
                            data-live-search="true"
                            tabIndex={-98}
                          >
                            <option
                              value="ad"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ad.png' class='mr-2'><span>AD</span></div>"
                            />
                            <option
                              value="ae"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ae.png' class='mr-2'><span>AE</span></div>"
                            />
                            <option
                              value="af"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/af.png' class='mr-2'><span>AF</span></div>"
                            />
                            <option
                              value="ag"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ag.png' class='mr-2'><span>AG</span></div>"
                            />
                            <option
                              value="ai"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ai.png' class='mr-2'><span>AI</span></div>"
                            />
                            <option
                              value="al"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/al.png' class='mr-2'><span>AL</span></div>"
                            />
                            <option
                              value="am"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/am.png' class='mr-2'><span>AM</span></div>"
                            />
                            <option
                              value="an"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/an.png' class='mr-2'><span>AN</span></div>"
                            />
                            <option
                              value="ao"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ao.png' class='mr-2'><span>AO</span></div>"
                            />
                            <option
                              value="ar"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ar.png' class='mr-2'><span>AR</span></div>"
                            />
                            <option
                              value="as"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/as.png' class='mr-2'><span>AS</span></div>"
                            />
                            <option
                              value="at"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/at.png' class='mr-2'><span>AT</span></div>"
                            />
                            <option
                              value="au"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/au.png' class='mr-2'><span>AU</span></div>"
                            />
                            <option
                              value="aw"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/aw.png' class='mr-2'><span>AW</span></div>"
                            />
                            <option
                              value="ax"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ax.png' class='mr-2'><span>AX</span></div>"
                            />
                            <option
                              value="az"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/az.png' class='mr-2'><span>AZ</span></div>"
                            />
                            <option
                              value="ba"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ba.png' class='mr-2'><span>BA</span></div>"
                            />
                            <option
                              value="bb"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/bb.png' class='mr-2'><span>BB</span></div>"
                            />
                            <option
                              value="bd"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/bd.png' class='mr-2'><span>BD</span></div>"
                            />
                            <option
                              value="be"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/be.png' class='mr-2'><span>BE</span></div>"
                            />
                            <option
                              value="bf"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/bf.png' class='mr-2'><span>BF</span></div>"
                            />
                            <option
                              value="bg"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/bg.png' class='mr-2'><span>BG</span></div>"
                            />
                            <option
                              value="bh"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/bh.png' class='mr-2'><span>BH</span></div>"
                            />
                            <option
                              value="bi"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/bi.png' class='mr-2'><span>BI</span></div>"
                            />
                            <option
                              value="bj"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/bj.png' class='mr-2'><span>BJ</span></div>"
                            />
                            <option
                              value="bm"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/bm.png' class='mr-2'><span>BM</span></div>"
                            />
                            <option
                              value="bn"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/bn.png' class='mr-2'><span>BN</span></div>"
                            />
                            <option
                              value="bo"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/bo.png' class='mr-2'><span>BO</span></div>"
                            />
                            <option
                              value="br"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/br.png' class='mr-2'><span>BR</span></div>"
                            />
                            <option
                              value="bs"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/bs.png' class='mr-2'><span>BS</span></div>"
                            />
                            <option
                              value="bt"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/bt.png' class='mr-2'><span>BT</span></div>"
                            />
                            <option
                              value="bv"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/bv.png' class='mr-2'><span>BV</span></div>"
                            />
                            <option
                              value="bw"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/bw.png' class='mr-2'><span>BW</span></div>"
                            />
                            <option
                              value="by"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/by.png' class='mr-2'><span>BY</span></div>"
                            />
                            <option
                              value="bz"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/bz.png' class='mr-2'><span>BZ</span></div>"
                            />
                            <option
                              value="ca"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ca.png' class='mr-2'><span>CA</span></div>"
                            />
                            <option
                              value="catalonia"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/catalonia.png' class='mr-2'><span>CATALONIA</span></div>"
                            />
                            <option
                              value="cc"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/cc.png' class='mr-2'><span>CC</span></div>"
                            />
                            <option
                              value="cd"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/cd.png' class='mr-2'><span>CD</span></div>"
                            />
                            <option
                              value="cf"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/cf.png' class='mr-2'><span>CF</span></div>"
                            />
                            <option
                              value="cg"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/cg.png' class='mr-2'><span>CG</span></div>"
                            />
                            <option
                              value="ch"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ch.png' class='mr-2'><span>CH</span></div>"
                            />
                            <option
                              value="ci"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ci.png' class='mr-2'><span>CI</span></div>"
                            />
                            <option
                              value="ck"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ck.png' class='mr-2'><span>CK</span></div>"
                            />
                            <option
                              value="cl"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/cl.png' class='mr-2'><span>CL</span></div>"
                            />
                            <option
                              value="cm"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/cm.png' class='mr-2'><span>CM</span></div>"
                            />
                            <option
                              value="cn"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/cn.png' class='mr-2'><span>CN</span></div>"
                            />
                            <option
                              value="co"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/co.png' class='mr-2'><span>CO</span></div>"
                            />
                            <option
                              value="cr"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/cr.png' class='mr-2'><span>CR</span></div>"
                            />
                            <option
                              value="cs"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/cs.png' class='mr-2'><span>CS</span></div>"
                            />
                            <option
                              value="cu"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/cu.png' class='mr-2'><span>CU</span></div>"
                            />
                            <option
                              value="cv"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/cv.png' class='mr-2'><span>CV</span></div>"
                            />
                            <option
                              value="cx"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/cx.png' class='mr-2'><span>CX</span></div>"
                            />
                            <option
                              value="cy"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/cy.png' class='mr-2'><span>CY</span></div>"
                            />
                            <option
                              value="cz"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/cz.png' class='mr-2'><span>CZ</span></div>"
                            />
                            <option
                              value="de"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/de.png' class='mr-2'><span>DE</span></div>"
                            />
                            <option
                              value="dj"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/dj.png' class='mr-2'><span>DJ</span></div>"
                            />
                            <option
                              value="dk"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/dk.png' class='mr-2'><span>DK</span></div>"
                            />
                            <option
                              value="dm"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/dm.png' class='mr-2'><span>DM</span></div>"
                            />
                            <option
                              value="do"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/do.png' class='mr-2'><span>DO</span></div>"
                            />
                            <option
                              value="dz"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/dz.png' class='mr-2'><span>DZ</span></div>"
                            />
                            <option
                              value="ec"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ec.png' class='mr-2'><span>EC</span></div>"
                            />
                            <option
                              value="ee"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ee.png' class='mr-2'><span>EE</span></div>"
                            />
                            <option
                              value="eg"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/eg.png' class='mr-2'><span>EG</span></div>"
                            />
                            <option
                              value="eh"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/eh.png' class='mr-2'><span>EH</span></div>"
                            />
                            <option
                              value="en"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/en.png' class='mr-2'><span>EN</span></div>"
                            />
                            <option
                              value="england"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/england.png' class='mr-2'><span>ENGLAND</span></div>"
                            />
                            <option
                              value="er"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/er.png' class='mr-2'><span>ER</span></div>"
                            />
                            <option
                              value="es"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/es.png' class='mr-2'><span>ES</span></div>"
                            />
                            <option
                              value="et"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/et.png' class='mr-2'><span>ET</span></div>"
                            />
                            <option
                              value="europeanunion"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/europeanunion.png' class='mr-2'><span>EUROPEANUNION</span></div>"
                            />
                            <option
                              value="fam"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/fam.png' class='mr-2'><span>FAM</span></div>"
                            />
                            <option
                              value="fi"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/fi.png' class='mr-2'><span>FI</span></div>"
                            />
                            <option
                              value="fj"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/fj.png' class='mr-2'><span>FJ</span></div>"
                            />
                            <option
                              value="fk"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/fk.png' class='mr-2'><span>FK</span></div>"
                            />
                            <option
                              value="fm"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/fm.png' class='mr-2'><span>FM</span></div>"
                            />
                            <option
                              value="fo"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/fo.png' class='mr-2'><span>FO</span></div>"
                            />
                            <option
                              value="fr"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/fr.png' class='mr-2'><span>FR</span></div>"
                            />
                            <option
                              value="ga"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ga.png' class='mr-2'><span>GA</span></div>"
                            />
                            <option
                              value="gb"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/gb.png' class='mr-2'><span>GB</span></div>"
                            />
                            <option
                              value="gd"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/gd.png' class='mr-2'><span>GD</span></div>"
                            />
                            <option
                              value="ge"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ge.png' class='mr-2'><span>GE</span></div>"
                            />
                            <option
                              value="gf"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/gf.png' class='mr-2'><span>GF</span></div>"
                            />
                            <option
                              value="gh"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/gh.png' class='mr-2'><span>GH</span></div>"
                            />
                            <option
                              value="gi"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/gi.png' class='mr-2'><span>GI</span></div>"
                            />
                            <option
                              value="gl"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/gl.png' class='mr-2'><span>GL</span></div>"
                            />
                            <option
                              value="gm"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/gm.png' class='mr-2'><span>GM</span></div>"
                            />
                            <option
                              value="gn"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/gn.png' class='mr-2'><span>GN</span></div>"
                            />
                            <option
                              value="gp"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/gp.png' class='mr-2'><span>GP</span></div>"
                            />
                            <option
                              value="gq"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/gq.png' class='mr-2'><span>GQ</span></div>"
                            />
                            <option
                              value="gr"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/gr.png' class='mr-2'><span>GR</span></div>"
                            />
                            <option
                              value="gs"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/gs.png' class='mr-2'><span>GS</span></div>"
                            />
                            <option
                              value="gt"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/gt.png' class='mr-2'><span>GT</span></div>"
                            />
                            <option
                              value="gu"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/gu.png' class='mr-2'><span>GU</span></div>"
                            />
                            <option
                              value="gw"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/gw.png' class='mr-2'><span>GW</span></div>"
                            />
                            <option
                              value="gy"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/gy.png' class='mr-2'><span>GY</span></div>"
                            />
                            <option
                              value="hk"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/hk.png' class='mr-2'><span>HK</span></div>"
                            />
                            <option
                              value="hm"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/hm.png' class='mr-2'><span>HM</span></div>"
                            />
                            <option
                              value="hn"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/hn.png' class='mr-2'><span>HN</span></div>"
                            />
                            <option
                              value="hr"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/hr.png' class='mr-2'><span>HR</span></div>"
                            />
                            <option
                              value="ht"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ht.png' class='mr-2'><span>HT</span></div>"
                            />
                            <option
                              value="hu"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/hu.png' class='mr-2'><span>HU</span></div>"
                            />
                            <option
                              value="id"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/id.png' class='mr-2'><span>ID</span></div>"
                            />
                            <option
                              value="ie"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ie.png' class='mr-2'><span>IE</span></div>"
                            />
                            <option
                              value="il"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/il.png' class='mr-2'><span>IL</span></div>"
                            />
                            <option
                              value="in"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/in.png' class='mr-2'><span>IN</span></div>"
                            />
                            <option
                              value="io"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/io.png' class='mr-2'><span>IO</span></div>"
                            />
                            <option
                              value="iq"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/iq.png' class='mr-2'><span>IQ</span></div>"
                            />
                            <option
                              value="ir"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ir.png' class='mr-2'><span>IR</span></div>"
                            />
                            <option
                              value="is"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/is.png' class='mr-2'><span>IS</span></div>"
                            />
                            <option
                              value="it"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/it.png' class='mr-2'><span>IT</span></div>"
                            />
                            <option
                              value="jm"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/jm.png' class='mr-2'><span>JM</span></div>"
                            />
                            <option
                              value="jo"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/jo.png' class='mr-2'><span>JO</span></div>"
                            />
                            <option
                              value="jp"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/jp.png' class='mr-2'><span>JP</span></div>"
                            />
                            <option
                              value="ke"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ke.png' class='mr-2'><span>KE</span></div>"
                            />
                            <option
                              value="kg"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/kg.png' class='mr-2'><span>KG</span></div>"
                            />
                            <option
                              value="kh"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/kh.png' class='mr-2'><span>KH</span></div>"
                            />
                            <option
                              value="ki"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ki.png' class='mr-2'><span>KI</span></div>"
                            />
                            <option
                              value="km"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/km.png' class='mr-2'><span>KM</span></div>"
                            />
                            <option
                              value="kn"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/kn.png' class='mr-2'><span>KN</span></div>"
                            />
                            <option
                              value="kp"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/kp.png' class='mr-2'><span>KP</span></div>"
                            />
                            <option
                              value="kr"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/kr.png' class='mr-2'><span>KR</span></div>"
                            />
                            <option
                              value="kw"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/kw.png' class='mr-2'><span>KW</span></div>"
                            />
                            <option
                              value="ky"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ky.png' class='mr-2'><span>KY</span></div>"
                            />
                            <option
                              value="kz"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/kz.png' class='mr-2'><span>KZ</span></div>"
                            />
                            <option
                              value="la"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/la.png' class='mr-2'><span>LA</span></div>"
                            />
                            <option
                              value="lb"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/lb.png' class='mr-2'><span>LB</span></div>"
                            />
                            <option
                              value="lc"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/lc.png' class='mr-2'><span>LC</span></div>"
                            />
                            <option
                              value="li"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/li.png' class='mr-2'><span>LI</span></div>"
                            />
                            <option
                              value="lk"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/lk.png' class='mr-2'><span>LK</span></div>"
                            />
                            <option
                              value="lr"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/lr.png' class='mr-2'><span>LR</span></div>"
                            />
                            <option
                              value="ls"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ls.png' class='mr-2'><span>LS</span></div>"
                            />
                            <option
                              value="lt"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/lt.png' class='mr-2'><span>LT</span></div>"
                            />
                            <option
                              value="lu"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/lu.png' class='mr-2'><span>LU</span></div>"
                            />
                            <option
                              value="lv"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/lv.png' class='mr-2'><span>LV</span></div>"
                            />
                            <option
                              value="ly"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ly.png' class='mr-2'><span>LY</span></div>"
                            />
                            <option
                              value="ma"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ma.png' class='mr-2'><span>MA</span></div>"
                            />
                            <option
                              value="mc"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/mc.png' class='mr-2'><span>MC</span></div>"
                            />
                            <option
                              value="md"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/md.png' class='mr-2'><span>MD</span></div>"
                            />
                            <option
                              value="me"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/me.png' class='mr-2'><span>ME</span></div>"
                            />
                            <option
                              value="mg"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/mg.png' class='mr-2'><span>MG</span></div>"
                            />
                            <option
                              value="mh"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/mh.png' class='mr-2'><span>MH</span></div>"
                            />
                            <option
                              value="mk"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/mk.png' class='mr-2'><span>MK</span></div>"
                            />
                            <option
                              value="ml"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ml.png' class='mr-2'><span>ML</span></div>"
                            />
                            <option
                              value="mm"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/mm.png' class='mr-2'><span>MM</span></div>"
                            />
                            <option
                              value="mn"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/mn.png' class='mr-2'><span>MN</span></div>"
                            />
                            <option
                              value="mo"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/mo.png' class='mr-2'><span>MO</span></div>"
                            />
                            <option
                              value="mp"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/mp.png' class='mr-2'><span>MP</span></div>"
                            />
                            <option
                              value="mq"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/mq.png' class='mr-2'><span>MQ</span></div>"
                            />
                            <option
                              value="mr"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/mr.png' class='mr-2'><span>MR</span></div>"
                            />
                            <option
                              value="ms"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ms.png' class='mr-2'><span>MS</span></div>"
                            />
                            <option
                              value="mt"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/mt.png' class='mr-2'><span>MT</span></div>"
                            />
                            <option
                              value="mu"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/mu.png' class='mr-2'><span>MU</span></div>"
                            />
                            <option
                              value="mv"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/mv.png' class='mr-2'><span>MV</span></div>"
                            />
                            <option
                              value="mw"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/mw.png' class='mr-2'><span>MW</span></div>"
                            />
                            <option
                              value="mx"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/mx.png' class='mr-2'><span>MX</span></div>"
                            />
                            <option
                              value="my"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/my.png' class='mr-2'><span>MY</span></div>"
                            />
                            <option
                              value="mz"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/mz.png' class='mr-2'><span>MZ</span></div>"
                            />
                            <option
                              value="na"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/na.png' class='mr-2'><span>NA</span></div>"
                            />
                            <option
                              value="nc"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/nc.png' class='mr-2'><span>NC</span></div>"
                            />
                            <option
                              value="ne"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ne.png' class='mr-2'><span>NE</span></div>"
                            />
                            <option
                              value="nf"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/nf.png' class='mr-2'><span>NF</span></div>"
                            />
                            <option
                              value="ng"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ng.png' class='mr-2'><span>NG</span></div>"
                            />
                            <option
                              value="ni"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ni.png' class='mr-2'><span>NI</span></div>"
                            />
                            <option
                              value="nl"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/nl.png' class='mr-2'><span>NL</span></div>"
                            />
                            <option
                              value="no"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/no.png' class='mr-2'><span>NO</span></div>"
                            />
                            <option
                              value="np"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/np.png' class='mr-2'><span>NP</span></div>"
                            />
                            <option
                              value="nr"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/nr.png' class='mr-2'><span>NR</span></div>"
                            />
                            <option
                              value="nu"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/nu.png' class='mr-2'><span>NU</span></div>"
                            />
                            <option
                              value="nz"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/nz.png' class='mr-2'><span>NZ</span></div>"
                            />
                            <option
                              value="om"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/om.png' class='mr-2'><span>OM</span></div>"
                            />
                            <option
                              value="pa"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/pa.png' class='mr-2'><span>PA</span></div>"
                            />
                            <option
                              value="pe"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/pe.png' class='mr-2'><span>PE</span></div>"
                            />
                            <option
                              value="pf"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/pf.png' class='mr-2'><span>PF</span></div>"
                            />
                            <option
                              value="pg"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/pg.png' class='mr-2'><span>PG</span></div>"
                            />
                            <option
                              value="ph"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ph.png' class='mr-2'><span>PH</span></div>"
                            />
                            <option
                              value="pk"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/pk.png' class='mr-2'><span>PK</span></div>"
                            />
                            <option
                              value="pl"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/pl.png' class='mr-2'><span>PL</span></div>"
                            />
                            <option
                              value="pm"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/pm.png' class='mr-2'><span>PM</span></div>"
                            />
                            <option
                              value="pn"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/pn.png' class='mr-2'><span>PN</span></div>"
                            />
                            <option
                              value="pr"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/pr.png' class='mr-2'><span>PR</span></div>"
                            />
                            <option
                              value="ps"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ps.png' class='mr-2'><span>PS</span></div>"
                            />
                            <option
                              value="pt"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/pt.png' class='mr-2'><span>PT</span></div>"
                            />
                            <option
                              value="pw"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/pw.png' class='mr-2'><span>PW</span></div>"
                            />
                            <option
                              value="py"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/py.png' class='mr-2'><span>PY</span></div>"
                            />
                            <option
                              value="qa"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/qa.png' class='mr-2'><span>QA</span></div>"
                            />
                            <option
                              value="re"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/re.png' class='mr-2'><span>RE</span></div>"
                            />
                            <option
                              value="ro"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ro.png' class='mr-2'><span>RO</span></div>"
                            />
                            <option
                              value="rs"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/rs.png' class='mr-2'><span>RS</span></div>"
                            />
                            <option
                              value="ru"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ru.png' class='mr-2'><span>RU</span></div>"
                            />
                            <option
                              value="rw"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/rw.png' class='mr-2'><span>RW</span></div>"
                            />
                            <option
                              value="sa"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/sa.png' class='mr-2'><span>SA</span></div>"
                            />
                            <option
                              value="sb"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/sb.png' class='mr-2'><span>SB</span></div>"
                            />
                            <option
                              value="sc"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/sc.png' class='mr-2'><span>SC</span></div>"
                            />
                            <option
                              value="scotland"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/scotland.png' class='mr-2'><span>SCOTLAND</span></div>"
                            />
                            <option
                              value="sd"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/sd.png' class='mr-2'><span>SD</span></div>"
                            />
                            <option
                              value="se"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/se.png' class='mr-2'><span>SE</span></div>"
                            />
                            <option
                              value="sg"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/sg.png' class='mr-2'><span>SG</span></div>"
                            />
                            <option
                              value="sh"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/sh.png' class='mr-2'><span>SH</span></div>"
                            />
                            <option
                              value="si"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/si.png' class='mr-2'><span>SI</span></div>"
                            />
                            <option
                              value="sj"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/sj.png' class='mr-2'><span>SJ</span></div>"
                            />
                            <option
                              value="sk"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/sk.png' class='mr-2'><span>SK</span></div>"
                            />
                            <option
                              value="sl"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/sl.png' class='mr-2'><span>SL</span></div>"
                            />
                            <option
                              value="sm"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/sm.png' class='mr-2'><span>SM</span></div>"
                            />
                            <option
                              value="sn"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/sn.png' class='mr-2'><span>SN</span></div>"
                            />
                            <option
                              value="so"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/so.png' class='mr-2'><span>SO</span></div>"
                            />
                            <option
                              value="sr"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/sr.png' class='mr-2'><span>SR</span></div>"
                            />
                            <option
                              value="st"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/st.png' class='mr-2'><span>ST</span></div>"
                            />
                            <option
                              value="sv"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/sv.png' class='mr-2'><span>SV</span></div>"
                            />
                            <option
                              value="sy"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/sy.png' class='mr-2'><span>SY</span></div>"
                            />
                            <option
                              value="sz"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/sz.png' class='mr-2'><span>SZ</span></div>"
                            />
                            <option
                              value="tc"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/tc.png' class='mr-2'><span>TC</span></div>"
                            />
                            <option
                              value="td"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/td.png' class='mr-2'><span>TD</span></div>"
                            />
                            <option
                              value="tf"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/tf.png' class='mr-2'><span>TF</span></div>"
                            />
                            <option
                              value="tg"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/tg.png' class='mr-2'><span>TG</span></div>"
                            />
                            <option
                              value="th"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/th.png' class='mr-2'><span>TH</span></div>"
                            />
                            <option
                              value="tj"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/tj.png' class='mr-2'><span>TJ</span></div>"
                            />
                            <option
                              value="tk"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/tk.png' class='mr-2'><span>TK</span></div>"
                            />
                            <option
                              value="tl"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/tl.png' class='mr-2'><span>TL</span></div>"
                            />
                            <option
                              value="tm"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/tm.png' class='mr-2'><span>TM</span></div>"
                            />
                            <option
                              value="tn"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/tn.png' class='mr-2'><span>TN</span></div>"
                            />
                            <option
                              value="to"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/to.png' class='mr-2'><span>TO</span></div>"
                            />
                            <option
                              value="tr"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/tr.png' class='mr-2'><span>TR</span></div>"
                            />
                            <option
                              value="tt"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/tt.png' class='mr-2'><span>TT</span></div>"
                            />
                            <option
                              value="tv"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/tv.png' class='mr-2'><span>TV</span></div>"
                            />
                            <option
                              value="tw"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/tw.png' class='mr-2'><span>TW</span></div>"
                            />
                            <option
                              value="tz"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/tz.png' class='mr-2'><span>TZ</span></div>"
                            />
                            <option
                              value="ua"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ua.png' class='mr-2'><span>UA</span></div>"
                            />
                            <option
                              value="ug"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ug.png' class='mr-2'><span>UG</span></div>"
                            />
                            <option
                              value="um"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/um.png' class='mr-2'><span>UM</span></div>"
                            />
                            <option
                              value="uy"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/uy.png' class='mr-2'><span>UY</span></div>"
                            />
                            <option
                              value="uz"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/uz.png' class='mr-2'><span>UZ</span></div>"
                            />
                            <option
                              value="va"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/va.png' class='mr-2'><span>VA</span></div>"
                            />
                            <option
                              value="vc"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/vc.png' class='mr-2'><span>VC</span></div>"
                            />
                            <option
                              value="ve"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ve.png' class='mr-2'><span>VE</span></div>"
                            />
                            <option
                              value="vg"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/vg.png' class='mr-2'><span>VG</span></div>"
                            />
                            <option
                              value="vi"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/vi.png' class='mr-2'><span>VI</span></div>"
                            />
                            <option
                              value="vn"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/vn.png' class='mr-2'><span>VN</span></div>"
                            />
                            <option
                              value="vu"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/vu.png' class='mr-2'><span>VU</span></div>"
                            />
                            <option
                              value="wales"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/wales.png' class='mr-2'><span>WALES</span></div>"
                            />
                            <option
                              value="wf"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/wf.png' class='mr-2'><span>WF</span></div>"
                            />
                            <option
                              value="ws"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ws.png' class='mr-2'><span>WS</span></div>"
                            />
                            <option
                              value="ye"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/ye.png' class='mr-2'><span>YE</span></div>"
                            />
                            <option
                              value="yt"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/yt.png' class='mr-2'><span>YT</span></div>"
                            />
                            <option
                              value="za"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/za.png' class='mr-2'><span>ZA</span></div>"
                            />
                            <option
                              value="zm"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/zm.png' class='mr-2'><span>ZM</span></div>"
                            />
                            <option
                              value="zw"
                              data-content="<div class=''><img src='https://weddemoadmin.abaris.in/public/assets/img/flags/zw.png' class='mr-2'><span>ZW</span></div>"
                            />
                            //{" "}
                          </select>
                          <button
                            type="button"
                            className="btn dropdown-toggle btn-light"
                            data-toggle="dropdown"
                            role="combobox"
                            aria-owns="bs-select-2"
                            aria-haspopup="listbox"
                            aria-expanded="false"
                            title="AD"
                          >
                            <div className="filter-option">
                              <div className="filter-option-inner">
                                <div className="filter-option-inner-inner">
                                  <div className>
                                    <img
                                      src="https://weddemoadmin.abaris.in/public/assets/img/flags/ad.png"
                                      className="mr-2"
                                    />
                                    <span>AD</span>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </button>
                          <div
                            className="dropdown-menu "
                            style={{ maxHeight: 251, overflow: "hidden" }}
                          >
                            <div className="bs-searchbox">
                              <input
                                type="search"
                                className="form-control"
                                autoComplete="off"
                                role="combobox"
                                aria-label="Search"
                                aria-controls="bs-select-2"
                                aria-autocomplete="list"
                                aria-activedescendant="bs-select-2-0"
                              />
                            </div>
                            <div
                              className="inner show"
                              role="listbox"
                              id="bs-select-2"
                              tabIndex={-1}
                              style={{ maxHeight: 185, overflowY: "auto" }}
                            >
                              <ul
                                className="dropdown-menu inner show"
                                role="presentation"
                                style={{ marginTop: 0, marginBottom: 0 }}
                              >
                                <li className="selected active">
                                  <a
                                    role="option"
                                    className="dropdown-item active selected"
                                    id="bs-select-2-0"
                                    tabIndex={0}
                                    aria-setsize={247}
                                    aria-posinset={1}
                                    aria-selected="true"
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ad.png"
                                          className="mr-2"
                                        />
                                        <span>AD</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-1"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ae.png"
                                          className="mr-2"
                                        />
                                        <span>AE</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-2"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/af.png"
                                          className="mr-2"
                                        />
                                        <span>AF</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-3"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ag.png"
                                          className="mr-2"
                                        />
                                        <span>AG</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-4"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ai.png"
                                          className="mr-2"
                                        />
                                        <span>AI</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-5"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/al.png"
                                          className="mr-2"
                                        />
                                        <span>AL</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-6"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/am.png"
                                          className="mr-2"
                                        />
                                        <span>AM</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-7"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/an.png"
                                          className="mr-2"
                                        />
                                        <span>AN</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-8"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ao.png"
                                          className="mr-2"
                                        />
                                        <span>AO</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-9"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ar.png"
                                          className="mr-2"
                                        />
                                        <span>AR</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-10"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/as.png"
                                          className="mr-2"
                                        />
                                        <span>AS</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-11"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/at.png"
                                          className="mr-2"
                                        />
                                        <span>AT</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-12"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/au.png"
                                          className="mr-2"
                                        />
                                        <span>AU</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-13"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/aw.png"
                                          className="mr-2"
                                        />
                                        <span>AW</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-14"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ax.png"
                                          className="mr-2"
                                        />
                                        <span>AX</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-15"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/az.png"
                                          className="mr-2"
                                        />
                                        <span>AZ</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-16"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ba.png"
                                          className="mr-2"
                                        />
                                        <span>BA</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-17"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/bb.png"
                                          className="mr-2"
                                        />
                                        <span>BB</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-18"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/bd.png"
                                          className="mr-2"
                                        />
                                        <span>BD</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-19"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/be.png"
                                          className="mr-2"
                                        />
                                        <span>BE</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-20"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/bf.png"
                                          className="mr-2"
                                        />
                                        <span>BF</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-21"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/bg.png"
                                          className="mr-2"
                                        />
                                        <span>BG</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-22"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/bh.png"
                                          className="mr-2"
                                        />
                                        <span>BH</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-23"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/bi.png"
                                          className="mr-2"
                                        />
                                        <span>BI</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-24"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/bj.png"
                                          className="mr-2"
                                        />
                                        <span>BJ</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-25"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/bm.png"
                                          className="mr-2"
                                        />
                                        <span>BM</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-26"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/bn.png"
                                          className="mr-2"
                                        />
                                        <span>BN</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-27"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/bo.png"
                                          className="mr-2"
                                        />
                                        <span>BO</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-28"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/br.png"
                                          className="mr-2"
                                        />
                                        <span>BR</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-29"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/bs.png"
                                          className="mr-2"
                                        />
                                        <span>BS</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-30"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/bt.png"
                                          className="mr-2"
                                        />
                                        <span>BT</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-31"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/bv.png"
                                          className="mr-2"
                                        />
                                        <span>BV</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-32"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/bw.png"
                                          className="mr-2"
                                        />
                                        <span>BW</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-33"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/by.png"
                                          className="mr-2"
                                        />
                                        <span>BY</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-34"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/bz.png"
                                          className="mr-2"
                                        />
                                        <span>BZ</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-35"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ca.png"
                                          className="mr-2"
                                        />
                                        <span>CA</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-36"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/catalonia.png"
                                          className="mr-2"
                                        />
                                        <span>CATALONIA</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-37"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/cc.png"
                                          className="mr-2"
                                        />
                                        <span>CC</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-38"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/cd.png"
                                          className="mr-2"
                                        />
                                        <span>CD</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-39"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/cf.png"
                                          className="mr-2"
                                        />
                                        <span>CF</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-40"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/cg.png"
                                          className="mr-2"
                                        />
                                        <span>CG</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-41"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ch.png"
                                          className="mr-2"
                                        />
                                        <span>CH</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-42"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ci.png"
                                          className="mr-2"
                                        />
                                        <span>CI</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-43"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ck.png"
                                          className="mr-2"
                                        />
                                        <span>CK</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-44"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/cl.png"
                                          className="mr-2"
                                        />
                                        <span>CL</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-45"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/cm.png"
                                          className="mr-2"
                                        />
                                        <span>CM</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-46"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/cn.png"
                                          className="mr-2"
                                        />
                                        <span>CN</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-47"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/co.png"
                                          className="mr-2"
                                        />
                                        <span>CO</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-48"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/cr.png"
                                          className="mr-2"
                                        />
                                        <span>CR</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-49"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/cs.png"
                                          className="mr-2"
                                        />
                                        <span>CS</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-50"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/cu.png"
                                          className="mr-2"
                                        />
                                        <span>CU</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-51"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/cv.png"
                                          className="mr-2"
                                        />
                                        <span>CV</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-52"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/cx.png"
                                          className="mr-2"
                                        />
                                        <span>CX</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-53"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/cy.png"
                                          className="mr-2"
                                        />
                                        <span>CY</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-54"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/cz.png"
                                          className="mr-2"
                                        />
                                        <span>CZ</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-55"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/de.png"
                                          className="mr-2"
                                        />
                                        <span>DE</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-56"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/dj.png"
                                          className="mr-2"
                                        />
                                        <span>DJ</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-57"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/dk.png"
                                          className="mr-2"
                                        />
                                        <span>DK</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-58"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/dm.png"
                                          className="mr-2"
                                        />
                                        <span>DM</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-59"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/do.png"
                                          className="mr-2"
                                        />
                                        <span>DO</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-60"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/dz.png"
                                          className="mr-2"
                                        />
                                        <span>DZ</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-61"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ec.png"
                                          className="mr-2"
                                        />
                                        <span>EC</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-62"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ee.png"
                                          className="mr-2"
                                        />
                                        <span>EE</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-63"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/eg.png"
                                          className="mr-2"
                                        />
                                        <span>EG</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-64"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/eh.png"
                                          className="mr-2"
                                        />
                                        <span>EH</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-65"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/en.png"
                                          className="mr-2"
                                        />
                                        <span>EN</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-66"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/england.png"
                                          className="mr-2"
                                        />
                                        <span>ENGLAND</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-67"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/er.png"
                                          className="mr-2"
                                        />
                                        <span>ER</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-68"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/es.png"
                                          className="mr-2"
                                        />
                                        <span>ES</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-69"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/et.png"
                                          className="mr-2"
                                        />
                                        <span>ET</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-70"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/europeanunion.png"
                                          className="mr-2"
                                        />
                                        <span>EUROPEANUNION</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-71"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/fam.png"
                                          className="mr-2"
                                        />
                                        <span>FAM</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-72"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/fi.png"
                                          className="mr-2"
                                        />
                                        <span>FI</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-73"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/fj.png"
                                          className="mr-2"
                                        />
                                        <span>FJ</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-74"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/fk.png"
                                          className="mr-2"
                                        />
                                        <span>FK</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-75"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/fm.png"
                                          className="mr-2"
                                        />
                                        <span>FM</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-76"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/fo.png"
                                          className="mr-2"
                                        />
                                        <span>FO</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-77"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/fr.png"
                                          className="mr-2"
                                        />
                                        <span>FR</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-78"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ga.png"
                                          className="mr-2"
                                        />
                                        <span>GA</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-79"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/gb.png"
                                          className="mr-2"
                                        />
                                        <span>GB</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-80"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/gd.png"
                                          className="mr-2"
                                        />
                                        <span>GD</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-81"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ge.png"
                                          className="mr-2"
                                        />
                                        <span>GE</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-82"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/gf.png"
                                          className="mr-2"
                                        />
                                        <span>GF</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-83"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/gh.png"
                                          className="mr-2"
                                        />
                                        <span>GH</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-84"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/gi.png"
                                          className="mr-2"
                                        />
                                        <span>GI</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-85"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/gl.png"
                                          className="mr-2"
                                        />
                                        <span>GL</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-86"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/gm.png"
                                          className="mr-2"
                                        />
                                        <span>GM</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-87"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/gn.png"
                                          className="mr-2"
                                        />
                                        <span>GN</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-88"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/gp.png"
                                          className="mr-2"
                                        />
                                        <span>GP</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-89"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/gq.png"
                                          className="mr-2"
                                        />
                                        <span>GQ</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-90"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/gr.png"
                                          className="mr-2"
                                        />
                                        <span>GR</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-91"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/gs.png"
                                          className="mr-2"
                                        />
                                        <span>GS</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-92"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/gt.png"
                                          className="mr-2"
                                        />
                                        <span>GT</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-93"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/gu.png"
                                          className="mr-2"
                                        />
                                        <span>GU</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-94"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/gw.png"
                                          className="mr-2"
                                        />
                                        <span>GW</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-95"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/gy.png"
                                          className="mr-2"
                                        />
                                        <span>GY</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-96"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/hk.png"
                                          className="mr-2"
                                        />
                                        <span>HK</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-97"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/hm.png"
                                          className="mr-2"
                                        />
                                        <span>HM</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-98"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/hn.png"
                                          className="mr-2"
                                        />
                                        <span>HN</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-99"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/hr.png"
                                          className="mr-2"
                                        />
                                        <span>HR</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-100"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ht.png"
                                          className="mr-2"
                                        />
                                        <span>HT</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-101"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/hu.png"
                                          className="mr-2"
                                        />
                                        <span>HU</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-102"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/id.png"
                                          className="mr-2"
                                        />
                                        <span>ID</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-103"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ie.png"
                                          className="mr-2"
                                        />
                                        <span>IE</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-104"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/il.png"
                                          className="mr-2"
                                        />
                                        <span>IL</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-105"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/in.png"
                                          className="mr-2"
                                        />
                                        <span>IN</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-106"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/io.png"
                                          className="mr-2"
                                        />
                                        <span>IO</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-107"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/iq.png"
                                          className="mr-2"
                                        />
                                        <span>IQ</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-108"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ir.png"
                                          className="mr-2"
                                        />
                                        <span>IR</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-109"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/is.png"
                                          className="mr-2"
                                        />
                                        <span>IS</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-110"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/it.png"
                                          className="mr-2"
                                        />
                                        <span>IT</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-111"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/jm.png"
                                          className="mr-2"
                                        />
                                        <span>JM</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-112"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/jo.png"
                                          className="mr-2"
                                        />
                                        <span>JO</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-113"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/jp.png"
                                          className="mr-2"
                                        />
                                        <span>JP</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-114"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ke.png"
                                          className="mr-2"
                                        />
                                        <span>KE</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-115"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/kg.png"
                                          className="mr-2"
                                        />
                                        <span>KG</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-116"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/kh.png"
                                          className="mr-2"
                                        />
                                        <span>KH</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-117"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ki.png"
                                          className="mr-2"
                                        />
                                        <span>KI</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-118"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/km.png"
                                          className="mr-2"
                                        />
                                        <span>KM</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-119"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/kn.png"
                                          className="mr-2"
                                        />
                                        <span>KN</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-120"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/kp.png"
                                          className="mr-2"
                                        />
                                        <span>KP</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-121"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/kr.png"
                                          className="mr-2"
                                        />
                                        <span>KR</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-122"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/kw.png"
                                          className="mr-2"
                                        />
                                        <span>KW</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-123"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ky.png"
                                          className="mr-2"
                                        />
                                        <span>KY</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-124"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/kz.png"
                                          className="mr-2"
                                        />
                                        <span>KZ</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-125"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/la.png"
                                          className="mr-2"
                                        />
                                        <span>LA</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-126"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/lb.png"
                                          className="mr-2"
                                        />
                                        <span>LB</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-127"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/lc.png"
                                          className="mr-2"
                                        />
                                        <span>LC</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-128"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/li.png"
                                          className="mr-2"
                                        />
                                        <span>LI</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-129"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/lk.png"
                                          className="mr-2"
                                        />
                                        <span>LK</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-130"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/lr.png"
                                          className="mr-2"
                                        />
                                        <span>LR</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-131"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ls.png"
                                          className="mr-2"
                                        />
                                        <span>LS</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-132"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/lt.png"
                                          className="mr-2"
                                        />
                                        <span>LT</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-133"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/lu.png"
                                          className="mr-2"
                                        />
                                        <span>LU</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-134"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/lv.png"
                                          className="mr-2"
                                        />
                                        <span>LV</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-135"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ly.png"
                                          className="mr-2"
                                        />
                                        <span>LY</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-136"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ma.png"
                                          className="mr-2"
                                        />
                                        <span>MA</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-137"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/mc.png"
                                          className="mr-2"
                                        />
                                        <span>MC</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-138"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/md.png"
                                          className="mr-2"
                                        />
                                        <span>MD</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-139"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/me.png"
                                          className="mr-2"
                                        />
                                        <span>ME</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-140"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/mg.png"
                                          className="mr-2"
                                        />
                                        <span>MG</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-141"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/mh.png"
                                          className="mr-2"
                                        />
                                        <span>MH</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-142"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/mk.png"
                                          className="mr-2"
                                        />
                                        <span>MK</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-143"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ml.png"
                                          className="mr-2"
                                        />
                                        <span>ML</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-144"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/mm.png"
                                          className="mr-2"
                                        />
                                        <span>MM</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-145"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/mn.png"
                                          className="mr-2"
                                        />
                                        <span>MN</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-146"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/mo.png"
                                          className="mr-2"
                                        />
                                        <span>MO</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-147"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/mp.png"
                                          className="mr-2"
                                        />
                                        <span>MP</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-148"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/mq.png"
                                          className="mr-2"
                                        />
                                        <span>MQ</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-149"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/mr.png"
                                          className="mr-2"
                                        />
                                        <span>MR</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-150"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ms.png"
                                          className="mr-2"
                                        />
                                        <span>MS</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-151"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/mt.png"
                                          className="mr-2"
                                        />
                                        <span>MT</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-152"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/mu.png"
                                          className="mr-2"
                                        />
                                        <span>MU</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-153"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/mv.png"
                                          className="mr-2"
                                        />
                                        <span>MV</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-154"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/mw.png"
                                          className="mr-2"
                                        />
                                        <span>MW</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-155"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/mx.png"
                                          className="mr-2"
                                        />
                                        <span>MX</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-156"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/my.png"
                                          className="mr-2"
                                        />
                                        <span>MY</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-157"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/mz.png"
                                          className="mr-2"
                                        />
                                        <span>MZ</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-158"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/na.png"
                                          className="mr-2"
                                        />
                                        <span>NA</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-159"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/nc.png"
                                          className="mr-2"
                                        />
                                        <span>NC</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-160"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ne.png"
                                          className="mr-2"
                                        />
                                        <span>NE</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-161"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/nf.png"
                                          className="mr-2"
                                        />
                                        <span>NF</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-162"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ng.png"
                                          className="mr-2"
                                        />
                                        <span>NG</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-163"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ni.png"
                                          className="mr-2"
                                        />
                                        <span>NI</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-164"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/nl.png"
                                          className="mr-2"
                                        />
                                        <span>NL</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-165"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/no.png"
                                          className="mr-2"
                                        />
                                        <span>NO</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-166"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/np.png"
                                          className="mr-2"
                                        />
                                        <span>NP</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-167"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/nr.png"
                                          className="mr-2"
                                        />
                                        <span>NR</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-168"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/nu.png"
                                          className="mr-2"
                                        />
                                        <span>NU</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-169"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/nz.png"
                                          className="mr-2"
                                        />
                                        <span>NZ</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-170"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/om.png"
                                          className="mr-2"
                                        />
                                        <span>OM</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-171"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/pa.png"
                                          className="mr-2"
                                        />
                                        <span>PA</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-172"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/pe.png"
                                          className="mr-2"
                                        />
                                        <span>PE</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-173"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/pf.png"
                                          className="mr-2"
                                        />
                                        <span>PF</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-174"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/pg.png"
                                          className="mr-2"
                                        />
                                        <span>PG</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-175"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ph.png"
                                          className="mr-2"
                                        />
                                        <span>PH</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-176"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/pk.png"
                                          className="mr-2"
                                        />
                                        <span>PK</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-177"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/pl.png"
                                          className="mr-2"
                                        />
                                        <span>PL</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-178"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/pm.png"
                                          className="mr-2"
                                        />
                                        <span>PM</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-179"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/pn.png"
                                          className="mr-2"
                                        />
                                        <span>PN</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-180"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/pr.png"
                                          className="mr-2"
                                        />
                                        <span>PR</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-181"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ps.png"
                                          className="mr-2"
                                        />
                                        <span>PS</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-182"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/pt.png"
                                          className="mr-2"
                                        />
                                        <span>PT</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-183"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/pw.png"
                                          className="mr-2"
                                        />
                                        <span>PW</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-184"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/py.png"
                                          className="mr-2"
                                        />
                                        <span>PY</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-185"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/qa.png"
                                          className="mr-2"
                                        />
                                        <span>QA</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-186"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/re.png"
                                          className="mr-2"
                                        />
                                        <span>RE</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-187"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ro.png"
                                          className="mr-2"
                                        />
                                        <span>RO</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-188"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/rs.png"
                                          className="mr-2"
                                        />
                                        <span>RS</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-189"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ru.png"
                                          className="mr-2"
                                        />
                                        <span>RU</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-190"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/rw.png"
                                          className="mr-2"
                                        />
                                        <span>RW</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-191"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/sa.png"
                                          className="mr-2"
                                        />
                                        <span>SA</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-192"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/sb.png"
                                          className="mr-2"
                                        />
                                        <span>SB</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-193"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/sc.png"
                                          className="mr-2"
                                        />
                                        <span>SC</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-194"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/scotland.png"
                                          className="mr-2"
                                        />
                                        <span>SCOTLAND</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-195"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/sd.png"
                                          className="mr-2"
                                        />
                                        <span>SD</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-196"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/se.png"
                                          className="mr-2"
                                        />
                                        <span>SE</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-197"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/sg.png"
                                          className="mr-2"
                                        />
                                        <span>SG</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-198"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/sh.png"
                                          className="mr-2"
                                        />
                                        <span>SH</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-199"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/si.png"
                                          className="mr-2"
                                        />
                                        <span>SI</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-200"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/sj.png"
                                          className="mr-2"
                                        />
                                        <span>SJ</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-201"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/sk.png"
                                          className="mr-2"
                                        />
                                        <span>SK</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-202"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/sl.png"
                                          className="mr-2"
                                        />
                                        <span>SL</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-203"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/sm.png"
                                          className="mr-2"
                                        />
                                        <span>SM</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-204"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/sn.png"
                                          className="mr-2"
                                        />
                                        <span>SN</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-205"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/so.png"
                                          className="mr-2"
                                        />
                                        <span>SO</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-206"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/sr.png"
                                          className="mr-2"
                                        />
                                        <span>SR</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-207"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/st.png"
                                          className="mr-2"
                                        />
                                        <span>ST</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-208"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/sv.png"
                                          className="mr-2"
                                        />
                                        <span>SV</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-209"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/sy.png"
                                          className="mr-2"
                                        />
                                        <span>SY</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-210"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/sz.png"
                                          className="mr-2"
                                        />
                                        <span>SZ</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-211"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/tc.png"
                                          className="mr-2"
                                        />
                                        <span>TC</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-212"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/td.png"
                                          className="mr-2"
                                        />
                                        <span>TD</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-213"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/tf.png"
                                          className="mr-2"
                                        />
                                        <span>TF</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-214"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/tg.png"
                                          className="mr-2"
                                        />
                                        <span>TG</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-215"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/th.png"
                                          className="mr-2"
                                        />
                                        <span>TH</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-216"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/tj.png"
                                          className="mr-2"
                                        />
                                        <span>TJ</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-217"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/tk.png"
                                          className="mr-2"
                                        />
                                        <span>TK</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-218"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/tl.png"
                                          className="mr-2"
                                        />
                                        <span>TL</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-219"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/tm.png"
                                          className="mr-2"
                                        />
                                        <span>TM</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-220"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/tn.png"
                                          className="mr-2"
                                        />
                                        <span>TN</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-221"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/to.png"
                                          className="mr-2"
                                        />
                                        <span>TO</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-222"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/tr.png"
                                          className="mr-2"
                                        />
                                        <span>TR</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-223"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/tt.png"
                                          className="mr-2"
                                        />
                                        <span>TT</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-224"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/tv.png"
                                          className="mr-2"
                                        />
                                        <span>TV</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-225"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/tw.png"
                                          className="mr-2"
                                        />
                                        <span>TW</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-226"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/tz.png"
                                          className="mr-2"
                                        />
                                        <span>TZ</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-227"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ua.png"
                                          className="mr-2"
                                        />
                                        <span>UA</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-228"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ug.png"
                                          className="mr-2"
                                        />
                                        <span>UG</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-229"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/um.png"
                                          className="mr-2"
                                        />
                                        <span>UM</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-230"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/uy.png"
                                          className="mr-2"
                                        />
                                        <span>UY</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-231"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/uz.png"
                                          className="mr-2"
                                        />
                                        <span>UZ</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-232"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/va.png"
                                          className="mr-2"
                                        />
                                        <span>VA</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-233"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/vc.png"
                                          className="mr-2"
                                        />
                                        <span>VC</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-234"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ve.png"
                                          className="mr-2"
                                        />
                                        <span>VE</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-235"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/vg.png"
                                          className="mr-2"
                                        />
                                        <span>VG</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-236"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/vi.png"
                                          className="mr-2"
                                        />
                                        <span>VI</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-237"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/vn.png"
                                          className="mr-2"
                                        />
                                        <span>VN</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-238"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/vu.png"
                                          className="mr-2"
                                        />
                                        <span>VU</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-239"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/wales.png"
                                          className="mr-2"
                                        />
                                        <span>WALES</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-240"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/wf.png"
                                          className="mr-2"
                                        />
                                        <span>WF</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-241"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ws.png"
                                          className="mr-2"
                                        />
                                        <span>WS</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-242"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/ye.png"
                                          className="mr-2"
                                        />
                                        <span>YE</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-243"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/yt.png"
                                          className="mr-2"
                                        />
                                        <span>YT</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-244"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/za.png"
                                          className="mr-2"
                                        />
                                        <span>ZA</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-245"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/zm.png"
                                          className="mr-2"
                                        />
                                        <span>ZM</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-246"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      <div className>
                                        <img
                                          src="https://weddemoadmin.abaris.in/public/assets/img/flags/zw.png"
                                          className="mr-2"
                                        />
                                        <span>ZW</span>
                                      </div>
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="form-group mb-3 text-right">
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
      </div>
    </>
  );
}
export default Language;
