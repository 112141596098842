import React from 'react'
import ServerStatus from '../../../Components/system/update/ServerStatus'

function ServerStatusPage() {
    return (
        <>
            <ServerStatus />
        </>
    )
}

export default ServerStatusPage