import React from 'react'
import AddNewMembers from '../../../../Components/members/freeMembers/addNewMembers/AddNewMembers';

function AddNewMembersPage() {
  return (
    <>
      <AddNewMembers />
    </>
  )
}

export default AddNewMembersPage;