import SetReferalCommission from "../../../Components/referal/setReferalCommission/SetReferalCommission"


function SetReferalCommissionPage() {
    return (
        <>
        
        <SetReferalCommission/>
        </>
    )
}
export default SetReferalCommissionPage