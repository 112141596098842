import React from 'react'
import DeletedMembers from '../../../Components/members/deletedMembers/DeletedMembers'

function DeletedMembersPage() {
    return (
        <>
            <DeletedMembers />
        </>
    )
}

export default DeletedMembersPage