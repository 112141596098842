
function SystemDefaultCurrency() {
    return (
        <>
        <div className="card">
                <div className="card-header">
                  <h5 className="mb-0 h6">System Default Currency</h5>
                </div>
                <div className="card-body">
                  <form
                    action="https://weddemoadmin.abaris.in/admin/settings/update"
                    method="POST"
                  >
                    <input
                      type="hidden"
                      name="_token"
                      defaultValue="V3eUAFlZ4RZMMqWQoLNyu8KJ5Be77CCNc7fweveP"
                    />{" "}
                    <div className="form-group row">
                      <input
                        type="hidden"
                        name="types[]"
                        defaultValue="system_default_currency"
                      />
                      <div className="col-lg-3">
                        <label className="control-label">
                          System Default Currency
                        </label>
                      </div>
                      <div className="col-lg-6">
                      <select class="form-select form-control aiz-selectpicker" aria-label="Default select example">
                      <option value={1} selected>
                              U.S. Dollar
                            </option>
                            <option value={2}>Australian Dollar</option>
                            <option value={5}>Brazilian Real</option>
                            <option value={6}>Canadian Dollar</option>
                            <option value={7}>Czech Koruna</option>
                            <option value={8}>Danish Krone</option>
                            <option value={9}>Euro</option>
                            <option value={10}>Hong Kong Dollar</option>
                            <option value={11}>Hungarian Forint</option>
                            <option value={12}>Israeli New Sheqel</option>
                            <option value={13}>Japanese Yen</option>
                            <option value={14}>Malaysian Ringgit</option>
                            <option value={15}>Mexican Peso</option>
                            <option value={16}>Norwegian Krone</option>
                            <option value={17}>New Zealand Dollar</option>
                            <option value={18}>Philippine Peso</option>
                            <option value={19}>Polish Zloty</option>
                            <option value={20}>Pound Sterling</option>
                            <option value={21}>Russian Ruble</option>
                            <option value={22}>Singapore Dollar</option>
                            <option value={23}>Swedish Krona</option>
                            <option value={24}>Swiss Franc</option>
                            <option value={26}>Thai Baht</option>
                            <option value={27}>Taka</option>
                            <option value={28}>Indian Rupee</option>
</select>
                        {/* <div className="dropdown bootstrap-select form-control aiz-">
                          <select
                            className="form-control aiz-selectpicker"
                            name="system_default_currency"
                            data-live-search="true"
                            tabIndex={-98}
                          >
                            <option value={1} selected>
                              U.S. Dollar
                            </option>
                            <option value={2}>Australian Dollar</option>
                            <option value={5}>Brazilian Real</option>
                            <option value={6}>Canadian Dollar</option>
                            <option value={7}>Czech Koruna</option>
                            <option value={8}>Danish Krone</option>
                            <option value={9}>Euro</option>
                            <option value={10}>Hong Kong Dollar</option>
                            <option value={11}>Hungarian Forint</option>
                            <option value={12}>Israeli New Sheqel</option>
                            <option value={13}>Japanese Yen</option>
                            <option value={14}>Malaysian Ringgit</option>
                            <option value={15}>Mexican Peso</option>
                            <option value={16}>Norwegian Krone</option>
                            <option value={17}>New Zealand Dollar</option>
                            <option value={18}>Philippine Peso</option>
                            <option value={19}>Polish Zloty</option>
                            <option value={20}>Pound Sterling</option>
                            <option value={21}>Russian Ruble</option>
                            <option value={22}>Singapore Dollar</option>
                            <option value={23}>Swedish Krona</option>
                            <option value={24}>Swiss Franc</option>
                            <option value={26}>Thai Baht</option>
                            <option value={27}>Taka</option>
                            <option value={28}>Indian Rupee</option>
                          </select>
                          <button
                            type="button"
                            className="btn dropdown-toggle btn-light"
                            data-toggle="dropdown"
                            role="combobox"
                            aria-owns="bs-select-1"
                            aria-haspopup="listbox"
                            aria-expanded="false"
                            title="U.S. Dollar"
                          >
                            <div className="filter-option">
                              <div className="filter-option-inner">
                                <div className="filter-option-inner-inner">
                                  U.S. Dollar
                                </div>
                              </div>{" "}
                            </div>
                          </button>
                          <div
                            className="dropdown-menu "
                            style={{ maxHeight: 251, overflow: "hidden" }}
                          >
                            <div className="bs-searchbox">
                              <input
                                type="search"
                                className="form-control"
                                autoComplete="off"
                                role="combobox"
                                aria-label="Search"
                                aria-controls="bs-select-1"
                                aria-autocomplete="list"
                                aria-activedescendant="bs-select-1-0"
                              />
                            </div>
                            <div
                              className="inner show"
                              role="listbox"
                              id="bs-select-1"
                              tabIndex={-1}
                              style={{ maxHeight: 185, overflowY: "auto" }}
                            >
                              <ul
                                className="dropdown-menu inner show"
                                role="presentation"
                                style={{ marginTop: 0, marginBottom: 0 }}
                              >
                                <li className="selected active">
                                  <a
                                    role="option"
                                    className="dropdown-item active selected"
                                    id="bs-select-1-0"
                                    tabIndex={0}
                                    aria-setsize={25}
                                    aria-posinset={1}
                                    aria-selected="true"
                                  >
                                    <span className="text">U.S. Dollar</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-1"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      Australian Dollar
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-2"
                                    tabIndex={0}
                                  >
                                    <span className="text">Brazilian Real</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-3"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      Canadian Dollar
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-4"
                                    tabIndex={0}
                                  >
                                    <span className="text">Czech Koruna</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-5"
                                    tabIndex={0}
                                  >
                                    <span className="text">Danish Krone</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-6"
                                    tabIndex={0}
                                  >
                                    <span className="text">Euro</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-7"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      Hong Kong Dollar
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-8"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      Hungarian Forint
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-9"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      Israeli New Sheqel
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-10"
                                    tabIndex={0}
                                  >
                                    <span className="text">Japanese Yen</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-11"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      Malaysian Ringgit
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-12"
                                    tabIndex={0}
                                  >
                                    <span className="text">Mexican Peso</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-13"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      Norwegian Krone
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-14"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      New Zealand Dollar
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-15"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      Philippine Peso
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-16"
                                    tabIndex={0}
                                  >
                                    <span className="text">Polish Zloty</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-17"
                                    tabIndex={0}
                                  >
                                    <span className="text">Pound Sterling</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-18"
                                    tabIndex={0}
                                  >
                                    <span className="text">Russian Ruble</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-19"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      Singapore Dollar
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-20"
                                    tabIndex={0}
                                  >
                                    <span className="text">Swedish Krona</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-21"
                                    tabIndex={0}
                                  >
                                    <span className="text">Swiss Franc</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-22"
                                    tabIndex={0}
                                  >
                                    <span className="text">Thai Baht</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-23"
                                    tabIndex={0}
                                  >
                                    <span className="text">Taka</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-1-24"
                                    tabIndex={0}
                                  >
                                    <span className="text">Indian Rupee</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="col-lg-3">
                        <button
                          className="btn btn-sm btn-primary"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
        </>
    )
}
export default SystemDefaultCurrency