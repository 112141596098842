import React from 'react'
import ReportedMembers from '../../../Components/members/reportedMembers/ReportedMembers'

function ReportedMembersPage() {
  return (
    <>
      <ReportedMembers />
    </>
  )
}

export default ReportedMembersPage