import HappyStories from "../../Components/happyStories/HappyStories"


function HappyStoriesPage() {
    return (
        <>
        <HappyStories/>
        </>
    )
}
export default HappyStoriesPage