import SocialMediaLogIn from "../../../Components/settings/socialMediaLogin/SocialMediaLogIn";

function SocialMediaLogInPage() {
  return (
    <>
      <SocialMediaLogIn />
    </>
  );
}
export default SocialMediaLogInPage;
