import React from 'react'
import AddonManager from '../../Components/addonManager/AddonManager'

function AddonManagerPage() {
    return (
        <>
            <AddonManager />
        </>
    )
}

export default AddonManagerPage