import React from 'react'
import Update from '../../../Components/system/update/Update'

function UpdatePage() {
    return (
        <>
            <Update />
        </>
    )
}

export default UpdatePage