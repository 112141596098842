import React from 'react'
import BulkMemberAdd from '../../../Components/members/bulkMemberAdd/BulkMemberAdd'

function BulkMemberAddPage() {
    return (
        <>
            <BulkMemberAdd />
        </>
    )
}

export default BulkMemberAddPage