

function SetCurrencyFormate() {
    return (
        <>
        <div className="card">
                <div className="card-header">
                  <h5 className="mb-0 h6">Set Currency Formats</h5>
                </div>
                <div className="card-body">
                  <form
                    action="https://weddemoadmin.abaris.in/admin/settings/update"
                    method="POST"
                  >
                    <input
                      type="hidden"
                      name="_token"
                      defaultValue="V3eUAFlZ4RZMMqWQoLNyu8KJ5Be77CCNc7fweveP"
                    />{" "}
                    <div className="form-group row">
                      <input
                        type="hidden"
                        name="types[]"
                        defaultValue="symbol_format"
                      />
                      <div className="col-lg-4">
                        <label className="control-label">Symbol Format</label>
                      </div>
                      <div className="col-lg-8">
                      <select className="form-select form-control aiz-selectpicker" aria-label="Default select example">
                      <option value={1}>[Symbol] [Amount]</option>
                            <option value={2} selected>
                              [Amount] [Symbol]
                            </option>
</select>
                        {/* <div className="dropdown bootstrap-select form-control aiz-">
                          <select
                            className="form-control aiz-selectpicker"
                            name="symbol_format"
                            tabIndex={-98}
                          >
                            <option value={1}>[Symbol] [Amount]</option>
                            <option value={2} selected>
                              [Amount] [Symbol]
                            </option>
                          </select>
                          <button
                            type="button"
                            className="btn dropdown-toggle btn-light"
                            data-toggle="dropdown"
                            role="combobox"
                            aria-owns="bs-select-2"
                            aria-haspopup="listbox"
                            aria-expanded="false"
                            title="[Amount] [Symbol]"
                          >
                            <div className="filter-option">
                              <div className="filter-option-inner">
                                <div className="filter-option-inner-inner">
                                  [Amount] [Symbol]
                                </div>
                              </div>{" "}
                            </div>
                          </button>
                          <div
                            className="dropdown-menu "
                            style={{ overflow: "hidden" }}
                          >
                            <div
                              className="inner show"
                              role="listbox"
                              id="bs-select-2"
                              tabIndex={-1}
                              aria-activedescendant="bs-select-2-1"
                              style={{ overflowY: "auto" }}
                            >
                              <ul
                                className="dropdown-menu inner show"
                                role="presentation"
                                style={{ marginTop: 0, marginBottom: 0 }}
                              >
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-2-0"
                                    tabIndex={0}
                                  >
                                    <span className="text">
                                      [Symbol] [Amount]
                                    </span>
                                  </a>
                                </li>
                                <li className="selected active">
                                  <a
                                    role="option"
                                    className="dropdown-item active selected"
                                    id="bs-select-2-1"
                                    tabIndex={0}
                                    aria-setsize={2}
                                    aria-posinset={2}
                                    aria-selected="true"
                                  >
                                    <span className="text">
                                      [Amount] [Symbol]
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="form-group row">
                      <input
                        type="hidden"
                        name="types[]"
                        defaultValue="decimal_separator"
                      />
                      <div className="col-lg-4">
                        <label className="control-label">
                          Decimal Separator
                        </label>    
                      </div>
                      <div className="col-lg-8">
                      <select className="form-select form-control aiz-selectpicker" aria-label="Default select example">
                      <option value={1} selected>
                              1,23,456.70
                            </option>
                            <option value={2}>1.23.456,70</option>
</select>
                        {/* <div className="dropdown bootstrap-select form-control aiz-">
                          <select
                            className="form-control aiz-selectpicker"
                            name="decimal_separator"
                            tabIndex={-98}
                          >
                            <option value={1} selected>
                              1,23,456.70
                            </option>
                            <option value={2}>1.23.456,70</option>
                          </select>
                          <button
                            type="button"
                            className="btn dropdown-toggle btn-light"
                            data-toggle="dropdown"
                            role="combobox"
                            aria-owns="bs-select-3"
                            aria-haspopup="listbox"
                            aria-expanded="false"
                            title="1,23,456.70"
                          >
                            <div className="filter-option">
                              <div className="filter-option-inner">
                                <div className="filter-option-inner-inner">
                                  1,23,456.70
                                </div>
                              </div>{" "}
                            </div>
                          </button>
                          <div
                            className="dropdown-menu "
                            style={{ overflow: "hidden" }}
                          >
                            <div
                              className="inner show"
                              role="listbox"
                              id="bs-select-3"
                              tabIndex={-1}
                              aria-activedescendant="bs-select-3-0"
                              style={{ overflowY: "auto" }}
                            >
                              <ul
                                className="dropdown-menu inner show"
                                role="presentation"
                                style={{ marginTop: 0, marginBottom: 0 }}
                              >
                                <li className="selected active">
                                  <a
                                    role="option"
                                    className="dropdown-item active selected"
                                    id="bs-select-3-0"
                                    tabIndex={0}
                                    aria-setsize={2}
                                    aria-posinset={1}
                                    aria-selected="true"
                                  >
                                    <span className="text">1,23,456.70</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-3-1"
                                    tabIndex={0}
                                  >
                                    <span className="text">1.23.456,70</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="form-group row">
                      <input
                        type="hidden"
                        name="types[]"
                        defaultValue="no_of_decimals"
                      />
                      <div className="col-lg-4">
                        <label className="control-label">No of decimals</label>
                      </div>
                      <div className="col-lg-8">
                      <select className="form-select" aria-label="Default select example">
                      <option value={0} selected>
                              12345
                            </option>
                            <option value={1}>1234.5</option>
                            <option value={2}>123.45</option>
                            <option value={3}>12.345</option>
</select>

                        {/* <div className="dropdown bootstrap-select form-control aiz- dropup">
                          <select
                            className="form-control aiz-selectpicker"
                            name="no_of_decimals"
                            tabIndex={-98}
                          >
                            <option value={0} selected>
                              12345
                            </option>
                            <option value={1}>1234.5</option>
                            <option value={2}>123.45</option>
                            <option value={3}>12.345</option>
                          </select>
                          <button
                            type="button"
                            className="btn dropdown-toggle btn-light"
                            data-toggle="dropdown"
                            role="combobox"
                            aria-owns="bs-select-4"
                            aria-haspopup="listbox"
                            aria-expanded="false"
                            title={12345}
                          >
                            <div className="filter-option">
                              <div className="filter-option-inner">
                                <div className="filter-option-inner-inner">
                                  12345
                                </div>
                              </div>{" "}
                            </div>
                          </button>
                          <div
                            className="dropdown-menu "
                            style={{ overflow: "hidden" }}
                          >
                            <div
                              className="inner show"
                              role="listbox"
                              id="bs-select-4"
                              tabIndex={-1}
                              aria-activedescendant="bs-select-4-0"
                              style={{ overflowY: "auto" }}
                            >
                              <ul
                                className="dropdown-menu inner show"
                                role="presentation"
                                style={{ marginTop: 0, marginBottom: 0 }}
                              >
                                <li className="selected active">
                                  <a
                                    role="option"
                                    className="dropdown-item active selected"
                                    id="bs-select-4-0"
                                    tabIndex={0}
                                    aria-setsize={4}
                                    aria-posinset={1}
                                    aria-selected="true"
                                  >
                                    <span className="text">12345</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-4-1"
                                    tabIndex={0}
                                  >
                                    <span className="text">1234.5</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-4-2"
                                    tabIndex={0}
                                  >
                                    <span className="text">123.45</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    role="option"
                                    className="dropdown-item"
                                    id="bs-select-4-3"
                                    tabIndex={0}
                                  >
                                    <span className="text">12.345</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="form-group mb-0 text-right">
                      <button type="submit" className="btn btn-sm btn-primary">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
        </>
    )
}
export default SetCurrencyFormate