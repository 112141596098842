import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";

import Tab from "react-bootstrap/Tab";
function EmailTemplet() {
  return (
    <>
      <div className="aiz-main-content">
        <div className="px-15px px-lg-25px">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="mb-0 h6">Email Templates</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="Account Oppening Email"
                    >
                      <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="Account Oppening Email">
                              Account Oppening Email
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link eventKey="Account Opening Email To Admin">
                              Account Opening Email To Admin
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link eventKey="Account Approval Email">
                              Account Approval Email
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link eventKey="Staff Account Opening Email">
                              Staff Account Opening Email
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link eventKey="Package Purchase Email">
                              Package Purchase Email
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link eventKey="Manual Payment Approval Email">
                              Manual Payment Approval Email
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link eventKey="Email On Express Interest">
                              Email On Express Interest
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link eventKey="Email On Accepting Interest">
                              Email On Accepting Interest
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link eventKey="Password Reset Email">
                              Password Reset Email
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link eventKey="Profile Picture View Request Accepted">
                              Profile Picture View Request Email
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link eventKey="Profile Picture View Request Accepted Email">
                              Profile Picture View Request Accepted Email
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link eventKey="Gallery Image View Request Email">
                              Gallery Image View Request Email
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link eventKey="Gallery Image View Request Accepted Email">
                              Gallery Image View Request Accepted Email
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>

                      <Col sm={9}>
                        <div className="tab-content" id="v-pills-tabContent">
                          <Tab.Content>
                            <Tab.Pane eventKey="Account Oppening Email">
                              <div
                                className="tab-content"
                                id="v-pills-tabContent"
                              >
                                <div
                                  className="tab-pane fade active show"
                                  id="v-pills-1"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="account_oppening_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Account Opening"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                          fdprocessedid="tdsdwf"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]],\n</p><p>Thank you for registering at our site:&nbsp;[[sitename]].\n</p><p>Your account type is : [[account_type]]\n</p><p>Email is: [[email]]\n</p><p>Password is : [[password]]\n</p><p>You will be able to log in from here : [[url]]\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. Best wishes.\n</p><p>Thanks,\n</p><p>[[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div
                                                className="note-control-selection"
                                                style={{ display: "none" }}
                                              >
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]],</p>
                                              <p>
                                                Thank you for registering at our
                                                site:&nbsp;[[sitename]].
                                              </p>
                                              <p>
                                                Your account type is :
                                                [[account_type]]
                                              </p>
                                              <p>Email is: [[email]]</p>
                                              <p>Password is : [[password]]</p>
                                              <p>
                                                You will be able to log in from
                                                here : [[url]]
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions. Best wishes.
                                              </p>
                                              <p>Thanks,</p>
                                              <p>[[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796736751"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796736751"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796736751"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796736751"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="note-popover popover in note-link-popover bottom"
                                            style={{ display: "none" }}
                                          >
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796736751"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796736751"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796736751"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796736751"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="note-popover popover in note-image-popover bottom"
                                            style={{ display: "none" }}
                                          >
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="note-popover popover in note-table-popover bottom"
                                            style={{ display: "none" }}
                                          >
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796736751"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796736751"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  {/* <span>
                                                    Indent on current paragraph
                                                  </span> */}
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        fdprocessedid="ztknp"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-2"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="account_opening_email_to_admin"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Member Registration"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Congratulation!\n</p><p>You have a new Registered Member in your system.\n</p><p>Member Name : [[member_name]]\n</p><p>Email is : [[email]]\n</p><p>Member Profile: [[profile_link]]\n</p><p>Thanks,\n</p><p>[[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Congratulation!</p>
                                              <p>
                                                You have a new Registered Member
                                                in your system.
                                              </p>
                                              <p>
                                                Member Name : [[member_name]]
                                              </p>
                                              <p>Email is : [[email]]</p>
                                              <p>
                                                Member Profile: [[profile_link]]
                                              </p>
                                              <p>Thanks,</p>
                                              <p>[[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737072"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737072"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737072"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737072"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737072"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737072"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737072"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737072"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737072"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737072"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-3"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="account_approval_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Member Approval"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Congratulations [[name]],\n</p><p>Your account has been approved for [[sitename]]</p><p>You will be able to log in from here : [[url]]\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. Best wishes.\n</p><p>Thanks,\n</p><p>[[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Congratulations [[name]],</p>
                                              <p>
                                                Your account has been approved
                                                for [[sitename]]
                                              </p>
                                              <p>
                                                You will be able to log in from
                                                here : [[url]]
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions. Best wishes.
                                              </p>
                                              <p>Thanks,</p>
                                              <p>[[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737193"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737193"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737193"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737193"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737193"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737193"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737193"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737193"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737193"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737193"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-4"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="staff_account_opening_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Account Opening"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                          fdprocessedid="5j7klnr"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]],\n</p><p>You have been added to&nbsp;[[site_name]] as Staff.\n</p><p>Your account role is : [[role_type]]\n</p><p>Email is : [[email]]\n</p><p>Password is : [[password]]\n</p><p>You will be able to log in from here : [[url]]\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. Best wishes.\n</p><p>Thanks,\n</p><p>[[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div
                                            className="note-dropzone"
                                            style={{
                                              width: "707.525px",
                                              height: "360.938px",
                                            }}
                                          >
                                            <div className="note-dropzone-message">
                                              Drag image or text here
                                            </div>
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]],</p>
                                              <p>
                                                You have been added
                                                to&nbsp;[[site_name]] as Staff.
                                              </p>
                                              <p>
                                                Your account role is :
                                                [[role_type]]
                                              </p>
                                              <p>Email is : [[email]]</p>
                                              <p>Password is : [[password]]</p>
                                              <p>
                                                You will be able to log in from
                                                here : [[url]]
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions. Best wishes.
                                              </p>
                                              <p>Thanks,</p>
                                              <p>[[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737334"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737334"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737334"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737334"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737334"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737334"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737334"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737334"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737334"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737334"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        fdprocessedid="x9tj29"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-6"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="manual_payment_approval_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Payment Approved"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\nManual payment for package purchases has been approved.\n</p><p>Your account type is : [[account_type]] </p><p>\nPayment code is: [[payment_code]]\n</p><p>Purchased Package is : [[package]] </p><p>\nThe amount is: $[[amount]] </p><p>\nPlease contact the&nbsp;administration&nbsp;team if you have any further questions. Best wishes. </p><p>\nThanks, </p><p>\n[[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                Manual payment for package
                                                purchases has been approved.
                                              </p>
                                              <p>
                                                Your account type is :
                                                [[account_type]]{" "}
                                              </p>
                                              <p>
                                                Payment code is:
                                                [[payment_code]]
                                              </p>
                                              <p>
                                                Purchased Package is :
                                                [[package]]{" "}
                                              </p>
                                              <p>The amount is: $[[amount]] </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions. Best wishes.{" "}
                                              </p>
                                              <p>Thanks, </p>
                                              <p>[[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737566"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737566"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737566"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737566"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737566"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737566"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737566"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737566"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737566"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737566"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-7"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="email_on_express_interest"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="You have a new Interest request"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                          fdprocessedid="kvcweg"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] has Expressed interest in you.\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div
                                            className="note-dropzone"
                                            style={{
                                              width: "707.525px",
                                              height: "360.938px",
                                            }}
                                          >
                                            <div className="note-dropzone-message">
                                              Drag image or text here
                                            </div>
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] has Expressed
                                                interest in you.
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737697"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737697"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737697"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737697"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737697"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737697"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737697"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737697"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737697"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737697"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        fdprocessedid="ib0cu"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-8"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="email_on_accepting_interest"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Interest Accepted"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] has accepted your interest. Now you can send messages to each other. </p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] has accepted
                                                your interest. Now you can send
                                                messages to each other.{" "}
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737868"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737868"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737868"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737868"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737868"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737868"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737868"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737868"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737868"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737868"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-9"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="password_reset_email"
                                    />
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Password Reset"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p></p><p>Hi [[name]], </p>Your Password reset Verification Code is : [[code]]<br><p><br></p><p>\nThanks,\n</p><p> [[from]]</p><p></p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p />
                                              <p>Hi [[name]], </p>Your Password
                                              reset Verification Code is :
                                              [[code]]
                                              <br />
                                              <p>
                                                <br />
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                              <p />
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737979"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737979"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737979"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737979"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737979"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737979"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737979"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737979"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737979"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737979"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-10"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="profile_picture_view_request_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Profile Picture View Request"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] sent you Profile Picture View Request.\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] sent you Profile
                                                Picture View Request.
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-167757967380810"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-167757967380810"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-167757967380810"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-167757967380810"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-167757967380810"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-167757967380810"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-167757967380810"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-167757967380810"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-167757967380810"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-167757967380810"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-11"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="profile_picture_view_request_accepted_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Profile Picture View Request Accepted"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] has accepted your Profile Picture View Request<br></p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] has accepted
                                                your Profile Picture View
                                                Request
                                                <br />
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-167757967381811"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-167757967381811"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-167757967381811"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-167757967381811"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-167757967381811"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-167757967381811"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-167757967381811"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-167757967381811"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-167757967381811"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-167757967381811"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-12"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="gallery_image_view_request_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Gallery Image View Request"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] sent you Gallery Image View Request.\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] sent you Gallery
                                                Image View Request.
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-167757967382812"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-167757967382812"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-167757967382812"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-167757967382812"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-167757967382812"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-167757967382812"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-167757967382812"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-167757967382812"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-167757967382812"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-167757967382812"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-13"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="gallery_image_view_request_accepted_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Gallery Image View Request Accepted."
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] has accepted your Gallery Image View Request<br></p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] has accepted
                                                your Gallery Image View Request
                                                <br />
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-167757967383813"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-167757967383813"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-167757967383813"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-167757967383813"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-167757967383813"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-167757967383813"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-167757967383813"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-167757967383813"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-167757967383813"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-167757967383813"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="Account Opening Email To Admin">
                              <div
                                className="tab-content"
                                id="v-pills-tabContent"
                              >
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-1"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="account_oppening_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Account Opening"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                          fdprocessedid="tdsdwf"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]],\n</p><p>Thank you for registering at our site:&nbsp;[[sitename]].\n</p><p>Your account type is : [[account_type]]\n</p><p>Email is: [[email]]\n</p><p>Password is : [[password]]\n</p><p>You will be able to log in from here : [[url]]\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. Best wishes.\n</p><p>Thanks,\n</p><p>[[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div
                                                className="note-control-selection"
                                                style={{ display: "none" }}
                                              >
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]],</p>
                                              <p>
                                                Thank you for registering at our
                                                site:&nbsp;[[sitename]].
                                              </p>
                                              <p>
                                                Your account type is :
                                                [[account_type]]
                                              </p>
                                              <p>Email is: [[email]]</p>
                                              <p>Password is : [[password]]</p>
                                              <p>
                                                You will be able to log in from
                                                here : [[url]]
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions. Best wishes.
                                              </p>
                                              <p>Thanks,</p>
                                              <p>[[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796736751"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796736751"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796736751"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796736751"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="note-popover popover in note-link-popover bottom"
                                            style={{ display: "none" }}
                                          >
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796736751"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796736751"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796736751"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796736751"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="note-popover popover in note-image-popover bottom"
                                            style={{ display: "none" }}
                                          >
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="note-popover popover in note-table-popover bottom"
                                            style={{ display: "none" }}
                                          >
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796736751"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796736751"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        fdprocessedid="ztknp"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade active show"
                                  id="v-pills-2"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="account_opening_email_to_admin"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Member Registration"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                          fdprocessedid="wze4zl"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Congratulation!\n</p><p>You have a new Registered Member in your system.\n</p><p>Member Name : [[member_name]]\n</p><p>Email is : [[email]]\n</p><p>Member Profile: [[profile_link]]\n</p><p>Thanks,\n</p><p>[[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div
                                            className="note-dropzone"
                                            style={{
                                              width: "707.525px",
                                              height: "360.938px",
                                            }}
                                          >
                                            <div className="note-dropzone-message">
                                              Drag image or text here
                                            </div>
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Congratulation!</p>
                                              <p>
                                                You have a new Registered Member
                                                in your system.
                                              </p>
                                              <p>
                                                Member Name : [[member_name]]
                                              </p>
                                              <p>Email is : [[email]]</p>
                                              <p>
                                                Member Profile: [[profile_link]]
                                              </p>
                                              <p>Thanks,</p>
                                              <p>[[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737072"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737072"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737072"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737072"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737072"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737072"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737072"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737072"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737072"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737072"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        fdprocessedid="5u49yx"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-3"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="account_approval_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Member Approval"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Congratulations [[name]],\n</p><p>Your account has been approved for [[sitename]]</p><p>You will be able to log in from here : [[url]]\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. Best wishes.\n</p><p>Thanks,\n</p><p>[[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Congratulations [[name]],</p>
                                              <p>
                                                Your account has been approved
                                                for [[sitename]]
                                              </p>
                                              <p>
                                                You will be able to log in from
                                                here : [[url]]
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions. Best wishes.
                                              </p>
                                              <p>Thanks,</p>
                                              <p>[[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737193"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737193"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737193"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737193"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737193"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737193"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737193"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737193"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737193"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737193"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-4"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="staff_account_opening_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Account Opening"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                          fdprocessedid="5j7klnr"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]],\n</p><p>You have been added to&nbsp;[[site_name]] as Staff.\n</p><p>Your account role is : [[role_type]]\n</p><p>Email is : [[email]]\n</p><p>Password is : [[password]]\n</p><p>You will be able to log in from here : [[url]]\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. Best wishes.\n</p><p>Thanks,\n</p><p>[[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div
                                            className="note-dropzone"
                                            style={{
                                              width: "707.525px",
                                              height: "360.938px",
                                            }}
                                          >
                                            <div className="note-dropzone-message">
                                              Drag image or text here
                                            </div>
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]],</p>
                                              <p>
                                                You have been added
                                                to&nbsp;[[site_name]] as Staff.
                                              </p>
                                              <p>
                                                Your account role is :
                                                [[role_type]]
                                              </p>
                                              <p>Email is : [[email]]</p>
                                              <p>Password is : [[password]]</p>
                                              <p>
                                                You will be able to log in from
                                                here : [[url]]
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions. Best wishes.
                                              </p>
                                              <p>Thanks,</p>
                                              <p>[[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737334"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737334"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737334"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737334"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737334"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737334"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737334"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737334"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737334"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737334"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        fdprocessedid="x9tj29"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                               
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-6"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="manual_payment_approval_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Payment Approved"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\nManual payment for package purchases has been approved.\n</p><p>Your account type is : [[account_type]] </p><p>\nPayment code is: [[payment_code]]\n</p><p>Purchased Package is : [[package]] </p><p>\nThe amount is: $[[amount]] </p><p>\nPlease contact the&nbsp;administration&nbsp;team if you have any further questions. Best wishes. </p><p>\nThanks, </p><p>\n[[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                Manual payment for package
                                                purchases has been approved.
                                              </p>
                                              <p>
                                                Your account type is :
                                                [[account_type]]{" "}
                                              </p>
                                              <p>
                                                Payment code is:
                                                [[payment_code]]
                                              </p>
                                              <p>
                                                Purchased Package is :
                                                [[package]]{" "}
                                              </p>
                                              <p>The amount is: $[[amount]] </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions. Best wishes.{" "}
                                              </p>
                                              <p>Thanks, </p>
                                              <p>[[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737566"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737566"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737566"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737566"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737566"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737566"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737566"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737566"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737566"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737566"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-7"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="email_on_express_interest"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="You have a new Interest request"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                          fdprocessedid="kvcweg"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] has Expressed interest in you.\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div
                                            className="note-dropzone"
                                            style={{
                                              width: "707.525px",
                                              height: "360.938px",
                                            }}
                                          >
                                            <div className="note-dropzone-message">
                                              Drag image or text here
                                            </div>
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] has Expressed
                                                interest in you.
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737697"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737697"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737697"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737697"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737697"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737697"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737697"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737697"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737697"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737697"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        fdprocessedid="ib0cu"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-8"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="email_on_accepting_interest"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Interest Accepted"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] has accepted your interest. Now you can send messages to each other. </p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] has accepted
                                                your interest. Now you can send
                                                messages to each other.{" "}
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737868"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737868"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737868"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737868"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737868"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737868"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737868"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737868"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737868"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737868"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-9"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="password_reset_email"
                                    />
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Password Reset"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p></p><p>Hi [[name]], </p>Your Password reset Verification Code is : [[code]]<br><p><br></p><p>\nThanks,\n</p><p> [[from]]</p><p></p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p />
                                              <p>Hi [[name]], </p>Your Password
                                              reset Verification Code is :
                                              [[code]]
                                              <br />
                                              <p>
                                                <br />
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                              <p />
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737979"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737979"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737979"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737979"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737979"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737979"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737979"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737979"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737979"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737979"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-10"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="profile_picture_view_request_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Profile Picture View Request"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] sent you Profile Picture View Request.\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] sent you Profile
                                                Picture View Request.
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-167757967380810"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-167757967380810"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-167757967380810"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-167757967380810"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-167757967380810"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-167757967380810"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-167757967380810"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-167757967380810"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-167757967380810"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-167757967380810"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-11"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="profile_picture_view_request_accepted_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Profile Picture View Request Accepted"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] has accepted your Profile Picture View Request<br></p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] has accepted
                                                your Profile Picture View
                                                Request
                                                <br />
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-167757967381811"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-167757967381811"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-167757967381811"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-167757967381811"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-167757967381811"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-167757967381811"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-167757967381811"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-167757967381811"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-167757967381811"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-167757967381811"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-12"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="gallery_image_view_request_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Gallery Image View Request"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] sent you Gallery Image View Request.\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] sent you Gallery
                                                Image View Request.
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-167757967382812"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-167757967382812"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-167757967382812"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-167757967382812"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-167757967382812"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-167757967382812"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-167757967382812"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-167757967382812"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-167757967382812"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-167757967382812"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-13"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="gallery_image_view_request_accepted_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Gallery Image View Request Accepted."
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] has accepted your Gallery Image View Request<br></p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] has accepted
                                                your Gallery Image View Request
                                                <br />
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-167757967383813"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-167757967383813"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-167757967383813"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-167757967383813"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-167757967383813"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-167757967383813"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-167757967383813"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-167757967383813"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-167757967383813"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-167757967383813"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="Account Approval Email">
                              <div
                                className="tab-pane fade active show"
                                id="v-pills-3"
                                role="tabpanel"
                                aria-labelledby="v-pills-tab-1"
                              >
                                <form
                                  action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                  method="POST"
                                >
                                  <input
                                    type="hidden"
                                    name="_token"
                                    defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                  />{" "}
                                  <input
                                    type="hidden"
                                    name="identifier"
                                    defaultValue="account_approval_email"
                                  />
                                  <div className="form-group row">
                                    <div className="col-md-2">
                                      <label className="col-from-label">
                                        Activation
                                      </label>
                                    </div>
                                    <div className="col-md-10">
                                      <label className="aiz-switch aiz-switch-success mb-0">
                                        <input
                                          defaultValue={1}
                                          name="status"
                                          type="checkbox"
                                          defaultChecked
                                        />
                                        <span className="slider round" />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-2 col-form-label">
                                      Subject
                                    </label>
                                    <div className="col-md-10">
                                      <input
                                        type="text"
                                        name="subject"
                                        defaultValue="Member Approval"
                                        className="form-control"
                                        placeholder="Subject"
                                        required
                                        fdprocessedid="928t7j"
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-2 col-form-label">
                                      Email Body
                                    </label>
                                    <div className="col-md-10">
                                      <textarea
                                        name="body"
                                        className="form-control aiz-text-editor"
                                        data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                        placeholder="Type.."
                                        data-min-height={300}
                                        required
                                        style={{ display: "none" }}
                                        defaultValue={
                                          "<p>Congratulations [[name]],\n</p><p>Your account has been approved for [[sitename]]</p><p>You will be able to log in from here : [[url]]\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. Best wishes.\n</p><p>Thanks,\n</p><p>[[from]]</p>"
                                        }
                                      />
                                      <div className="note-editor note-frame card">
                                        <div className="note-dropzone">
                                          <div className="note-dropzone-message" />
                                        </div>
                                        <div
                                          className="note-toolbar card-header"
                                          role="toolbar"
                                        >
                                          <div className="note-btn-group btn-group note-font">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-bold"
                                              tabIndex={-1}
                                              title
                                              aria-label="Bold (CTRL+B)"
                                              data-original-title="Bold (CTRL+B)"
                                            >
                                              <i className="note-icon-bold" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-underline"
                                              tabIndex={-1}
                                              title
                                              aria-label="Underline (CTRL+U)"
                                              data-original-title="Underline (CTRL+U)"
                                            >
                                              <i className="note-icon-underline" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-italic"
                                              tabIndex={-1}
                                              title
                                              aria-label="Italic (CTRL+I)"
                                              data-original-title="Italic (CTRL+I)"
                                            >
                                              <i className="note-icon-italic" />
                                            </button>
                                          </div>
                                          <div className="note-btn-group btn-group note-para">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                              data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                            >
                                              <i className="note-icon-unorderedlist" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                              data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                            >
                                              <i className="note-icon-orderedlist" />
                                            </button>
                                          </div>
                                          <div className="note-btn-group btn-group note-view">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Undo (CTRL+Z)"
                                              data-original-title="Undo (CTRL+Z)"
                                            >
                                              <i className="note-icon-undo" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Redo (CTRL+Y)"
                                              data-original-title="Redo (CTRL+Y)"
                                            >
                                              <i className="note-icon-redo" />
                                            </button>
                                          </div>
                                        </div>
                                        <div className="note-editing-area">
                                          <div
                                            className="note-placeholder"
                                            style={{ display: "none" }}
                                          >
                                            Type..
                                          </div>
                                          <div className="note-handle">
                                            <div className="note-control-selection">
                                              <div className="note-control-selection-bg" />
                                              <div className="note-control-holder note-control-nw" />
                                              <div className="note-control-holder note-control-ne" />
                                              <div className="note-control-holder note-control-sw" />
                                              <div className="note-control-sizing note-control-se" />
                                              <div className="note-control-selection-info" />
                                            </div>
                                          </div>
                                          <textarea
                                            className="note-codable"
                                            aria-multiline="true"
                                            defaultValue={""}
                                          />
                                          <div
                                            className="note-editable card-block"
                                            contentEditable="true"
                                            role="textbox"
                                            aria-multiline="true"
                                            spellCheck="true"
                                            autoCorrect="true"
                                            style={{ height: 300 }}
                                          >
                                            <p>Congratulations [[name]],</p>
                                            <p>
                                              Your account has been approved for
                                              [[sitename]]
                                            </p>
                                            <p>
                                              You will be able to log in from
                                              here : [[url]]
                                            </p>
                                            <p>
                                              Please contact
                                              the&nbsp;administration&nbsp;team
                                              if you have any further questions.
                                              Best wishes.
                                            </p>
                                            <p>Thanks,</p>
                                            <p>[[from]]</p>
                                          </div>
                                        </div>
                                        <output
                                          className="note-status-output"
                                          role="status"
                                          aria-live="polite"
                                        />
                                        <div
                                          className="note-statusbar"
                                          role="status"
                                        >
                                          <output
                                            className="note-status-output"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-resizebar"
                                            aria-label="Resize"
                                          >
                                            <div className="note-icon-bar" />
                                            <div className="note-icon-bar" />
                                            <div className="note-icon-bar" />
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal link-dialog"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Link"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Link
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group">
                                                  <label
                                                    htmlFor="note-dialog-link-txt-16775796737193"
                                                    className="note-form-label"
                                                  >
                                                    Text to display
                                                  </label>
                                                  <input
                                                    id="note-dialog-link-txt-16775796737193"
                                                    className="note-link-text form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                                <div className="form-group note-form-group">
                                                  <label
                                                    htmlFor="note-dialog-link-url-16775796737193"
                                                    className="note-form-label"
                                                  >
                                                    To what URL should this link
                                                    go?
                                                  </label>
                                                  <input
                                                    id="note-dialog-link-url-16775796737193"
                                                    className="note-link-url form-control note-form-control note-input"
                                                    type="text"
                                                    defaultValue="http://"
                                                  />
                                                </div>
                                                <div className="form-check sn-checkbox-open-in-new-window">
                                                  <label className="form-check-label">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      defaultChecked
                                                      aria-label="Open in new window"
                                                      aria-checked="true"
                                                    />{" "}
                                                    Open in new window
                                                  </label>
                                                </div>
                                                <div className="form-check sn-checkbox-use-protocol">
                                                  <label className="form-check-label">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      defaultChecked
                                                      aria-label="Use default protocol"
                                                      aria-checked="true"
                                                    />{" "}
                                                    Use default protocol
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                  defaultValue="Insert Link"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-link-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <span>
                                              <a target="_blank" />
                                              &nbsp;
                                            </span>
                                            <div className="note-btn-group btn-group note-link">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Edit"
                                                data-original-title="Edit"
                                              >
                                                <i className="note-icon-link" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unlink"
                                                data-original-title="Unlink"
                                              >
                                                <i className="note-icon-chain-broken" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Image"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Image
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group note-group-select-from-files">
                                                  <label
                                                    htmlFor="note-dialog-image-file-16775796737193"
                                                    className="note-form-label"
                                                  >
                                                    Select from files
                                                  </label>
                                                  <input
                                                    id="note-dialog-image-file-16775796737193"
                                                    className="note-image-input form-control-file note-form-control note-input"
                                                    type="file"
                                                    name="files"
                                                    accept="image/*"
                                                    multiple="multiple"
                                                  />
                                                </div>
                                                <div className="form-group note-group-image-url">
                                                  <label
                                                    htmlFor="note-dialog-image-url-16775796737193"
                                                    className="note-form-label"
                                                  >
                                                    Image URL
                                                  </label>
                                                  <input
                                                    id="note-dialog-image-url-16775796737193"
                                                    className="note-image-url form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                  defaultValue="Insert Image"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-image-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <div className="note-btn-group btn-group note-resize">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize full"
                                                data-original-title="Resize full"
                                              >
                                                <span className="note-fontsize-10">
                                                  100%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize half"
                                                data-original-title="Resize half"
                                              >
                                                <span className="note-fontsize-10">
                                                  50%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize quarter"
                                                data-original-title="Resize quarter"
                                              >
                                                <span className="note-fontsize-10">
                                                  25%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Original size"
                                                data-original-title="Original size"
                                              >
                                                <i className="note-icon-rollback" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-float">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Float Left"
                                                data-original-title="Float Left"
                                              >
                                                <i className="note-icon-float-left" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Float Right"
                                                data-original-title="Float Right"
                                              >
                                                <i className="note-icon-float-right" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Remove float"
                                                data-original-title="Remove float"
                                              >
                                                <i className="note-icon-rollback" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-remove">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Remove Image"
                                                data-original-title="Remove Image"
                                              >
                                                <i className="note-icon-trash" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-table-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <div className="note-btn-group btn-group note-add">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add row below"
                                                data-original-title="Add row below"
                                              >
                                                <i className="note-icon-row-below" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add row above"
                                                data-original-title="Add row above"
                                              >
                                                <i className="note-icon-row-above" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add column left"
                                                data-original-title="Add column left"
                                              >
                                                <i className="note-icon-col-before" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add column right"
                                                data-original-title="Add column right"
                                              >
                                                <i className="note-icon-col-after" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-delete">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete row"
                                                data-original-title="Delete row"
                                              >
                                                <i className="note-icon-row-remove" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete column"
                                                data-original-title="Delete column"
                                              >
                                                <i className="note-icon-col-remove" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete table"
                                                data-original-title="Delete table"
                                              >
                                                <i className="note-icon-trash" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Video"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Video
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group row-fluid">
                                                  <label
                                                    htmlFor="note-dialog-video-url-16775796737193"
                                                    className="note-form-label"
                                                  >
                                                    Video URL{" "}
                                                    <small className="text-muted">
                                                      (YouTube, Vimeo, Vine,
                                                      Instagram, DailyMotion or
                                                      Youku)
                                                    </small>
                                                  </label>
                                                  <input
                                                    id="note-dialog-video-url-16775796737193"
                                                    className="note-video-url form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                  defaultValue="Insert Video"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Help"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Help
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div
                                                className="modal-body"
                                                style={{
                                                  maxHeight: 300,
                                                  overflow: "scroll",
                                                }}
                                              >
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>ENTER</kbd>
                                                </label>
                                                <span>Insert Paragraph</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+Z</kbd>
                                                </label>
                                                <span>
                                                  Undoes the last command
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+Y</kbd>
                                                </label>
                                                <span>
                                                  Redoes the last command
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>TAB</kbd>
                                                </label>
                                                <span>Tab</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>SHIFT+TAB</kbd>
                                                </label>
                                                <span>Untab</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+B</kbd>
                                                </label>
                                                <span>Set a bold style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+I</kbd>
                                                </label>
                                                <span>Set a italic style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+U</kbd>
                                                </label>
                                                <span>
                                                  Set a underline style
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+S</kbd>
                                                </label>
                                                <span>
                                                  Set a strikethrough style
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+BACKSLASH</kbd>
                                                </label>
                                                <span>Clean a style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+L</kbd>
                                                </label>
                                                <span>Set left align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+E</kbd>
                                                </label>
                                                <span>Set center align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+R</kbd>
                                                </label>
                                                <span>Set right align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+J</kbd>
                                                </label>
                                                <span>Set full align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+NUM7</kbd>
                                                </label>
                                                <span>
                                                  Toggle unordered list
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+NUM8</kbd>
                                                </label>
                                                <span>Toggle ordered list</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+LEFTBRACKET</kbd>
                                                </label>
                                                <span>
                                                  Outdent on current paragraph
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+RIGHTBRACKET</kbd>
                                                </label>
                                                <span>
                                                  Indent on current paragraph
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM0</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as a paragraph(P tag)
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM1</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H1
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM2</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H2
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM3</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H3
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM4</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H4
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM5</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H5
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM6</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H6
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+ENTER</kbd>
                                                </label>
                                                <span>
                                                  Insert horizontal rule
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+K</kbd>
                                                </label>
                                                <span>Show Link Dialog</span>
                                              </div>
                                              <div className="modal-footer">
                                                <p className="text-center">
                                                  <a
                                                    href="http://summernote.org/"
                                                    target="_blank"
                                                  >
                                                    Summernote 0.8.16
                                                  </a>{" "}
                                                  ·{" "}
                                                  <a
                                                    href="https://github.com/summernote/summernote"
                                                    target="_blank"
                                                  >
                                                    Project
                                                  </a>{" "}
                                                  ·{" "}
                                                  <a
                                                    href="https://github.com/summernote/summernote/issues"
                                                    target="_blank"
                                                  >
                                                    Issues
                                                  </a>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <small className="form-text text-danger">
                                        **N.B : Do Not Change The Variables Like
                                        [[ ____ ]].**
                                      </small>
                                    </div>
                                  </div>
                                  <div className="form-group mb-3 text-right">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                      fdprocessedid="04xq6x"
                                    >
                                      Update Settings
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="Staff Account Opening Email">
                              <div
                                className="tab-content"
                                id="v-pills-tabContent"
                              >
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-1"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="account_oppening_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Account Opening"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                          fdprocessedid="tdsdwf"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]],\n</p><p>Thank you for registering at our site:&nbsp;[[sitename]].\n</p><p>Your account type is : [[account_type]]\n</p><p>Email is: [[email]]\n</p><p>Password is : [[password]]\n</p><p>You will be able to log in from here : [[url]]\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. Best wishes.\n</p><p>Thanks,\n</p><p>[[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div
                                                className="note-control-selection"
                                                style={{ display: "none" }}
                                              >
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]],</p>
                                              <p>
                                                Thank you for registering at our
                                                site:&nbsp;[[sitename]].
                                              </p>
                                              <p>
                                                Your account type is :
                                                [[account_type]]
                                              </p>
                                              <p>Email is: [[email]]</p>
                                              <p>Password is : [[password]]</p>
                                              <p>
                                                You will be able to log in from
                                                here : [[url]]
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions. Best wishes.
                                              </p>
                                              <p>Thanks,</p>
                                              <p>[[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796736751"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796736751"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796736751"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796736751"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="note-popover popover in note-link-popover bottom"
                                            style={{ display: "none" }}
                                          >
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796736751"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796736751"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796736751"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796736751"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="note-popover popover in note-image-popover bottom"
                                            style={{ display: "none" }}
                                          >
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="note-popover popover in note-table-popover bottom"
                                            style={{ display: "none" }}
                                          >
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796736751"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796736751"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        fdprocessedid="ztknp"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-2"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="account_opening_email_to_admin"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Member Registration"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                          fdprocessedid="wze4zl"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Congratulation!\n</p><p>You have a new Registered Member in your system.\n</p><p>Member Name : [[member_name]]\n</p><p>Email is : [[email]]\n</p><p>Member Profile: [[profile_link]]\n</p><p>Thanks,\n</p><p>[[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div
                                            className="note-dropzone"
                                            style={{
                                              width: "707.525px",
                                              height: "360.938px",
                                            }}
                                          >
                                            <div className="note-dropzone-message">
                                              Drag image or text here
                                            </div>
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Congratulation!</p>
                                              <p>
                                                You have a new Registered Member
                                                in your system.
                                              </p>
                                              <p>
                                                Member Name : [[member_name]]
                                              </p>
                                              <p>Email is : [[email]]</p>
                                              <p>
                                                Member Profile: [[profile_link]]
                                              </p>
                                              <p>Thanks,</p>
                                              <p>[[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737072"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737072"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737072"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737072"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737072"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737072"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737072"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737072"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737072"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737072"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        fdprocessedid="5u49yx"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-3"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="account_approval_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Member Approval"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                          fdprocessedid="928t7j"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Congratulations [[name]],\n</p><p>Your account has been approved for [[sitename]]</p><p>You will be able to log in from here : [[url]]\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. Best wishes.\n</p><p>Thanks,\n</p><p>[[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Congratulations [[name]],</p>
                                              <p>
                                                Your account has been approved
                                                for [[sitename]]
                                              </p>
                                              <p>
                                                You will be able to log in from
                                                here : [[url]]
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions. Best wishes.
                                              </p>
                                              <p>Thanks,</p>
                                              <p>[[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737193"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737193"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737193"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737193"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737193"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737193"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737193"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737193"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737193"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737193"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        fdprocessedid="04xq6x"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade active show"
                                  id="v-pills-4"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="staff_account_opening_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Account Opening"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                          fdprocessedid="5j7klnr"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]],\n</p><p>You have been added to&nbsp;[[site_name]] as Staff.\n</p><p>Your account role is : [[role_type]]\n</p><p>Email is : [[email]]\n</p><p>Password is : [[password]]\n</p><p>You will be able to log in from here : [[url]]\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. Best wishes.\n</p><p>Thanks,\n</p><p>[[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div
                                            className="note-dropzone"
                                            style={{
                                              width: "707.525px",
                                              height: "360.938px",
                                            }}
                                          >
                                            <div className="note-dropzone-message">
                                              Drag image or text here
                                            </div>
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]],</p>
                                              <p>
                                                You have been added
                                                to&nbsp;[[site_name]] as Staff.
                                              </p>
                                              <p>
                                                Your account role is :
                                                [[role_type]]
                                              </p>
                                              <p>Email is : [[email]]</p>
                                              <p>Password is : [[password]]</p>
                                              <p>
                                                You will be able to log in from
                                                here : [[url]]
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions. Best wishes.
                                              </p>
                                              <p>Thanks,</p>
                                              <p>[[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737334"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737334"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737334"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737334"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737334"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737334"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737334"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737334"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737334"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737334"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        fdprocessedid="x9tj29"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-6"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="manual_payment_approval_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Payment Approved"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\nManual payment for package purchases has been approved.\n</p><p>Your account type is : [[account_type]] </p><p>\nPayment code is: [[payment_code]]\n</p><p>Purchased Package is : [[package]] </p><p>\nThe amount is: $[[amount]] </p><p>\nPlease contact the&nbsp;administration&nbsp;team if you have any further questions. Best wishes. </p><p>\nThanks, </p><p>\n[[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                Manual payment for package
                                                purchases has been approved.
                                              </p>
                                              <p>
                                                Your account type is :
                                                [[account_type]]{" "}
                                              </p>
                                              <p>
                                                Payment code is:
                                                [[payment_code]]
                                              </p>
                                              <p>
                                                Purchased Package is :
                                                [[package]]{" "}
                                              </p>
                                              <p>The amount is: $[[amount]] </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions. Best wishes.{" "}
                                              </p>
                                              <p>Thanks, </p>
                                              <p>[[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737566"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737566"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737566"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737566"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737566"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737566"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737566"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737566"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737566"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737566"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-7"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="email_on_express_interest"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="You have a new Interest request"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                          fdprocessedid="kvcweg"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] has Expressed interest in you.\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div
                                            className="note-dropzone"
                                            style={{
                                              width: "707.525px",
                                              height: "360.938px",
                                            }}
                                          >
                                            <div className="note-dropzone-message">
                                              Drag image or text here
                                            </div>
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] has Expressed
                                                interest in you.
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737697"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737697"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737697"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737697"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737697"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737697"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737697"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737697"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737697"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737697"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        fdprocessedid="ib0cu"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-8"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="email_on_accepting_interest"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Interest Accepted"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] has accepted your interest. Now you can send messages to each other. </p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] has accepted
                                                your interest. Now you can send
                                                messages to each other.{" "}
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737868"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737868"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737868"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737868"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737868"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737868"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737868"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737868"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737868"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737868"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-9"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="password_reset_email"
                                    />
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Password Reset"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p></p><p>Hi [[name]], </p>Your Password reset Verification Code is : [[code]]<br><p><br></p><p>\nThanks,\n</p><p> [[from]]</p><p></p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p />
                                              <p>Hi [[name]], </p>Your Password
                                              reset Verification Code is :
                                              [[code]]
                                              <br />
                                              <p>
                                                <br />
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                              <p />
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737979"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737979"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737979"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737979"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737979"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737979"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737979"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737979"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737979"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737979"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-10"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="profile_picture_view_request_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Profile Picture View Request"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] sent you Profile Picture View Request.\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] sent you Profile
                                                Picture View Request.
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-167757967380810"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-167757967380810"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-167757967380810"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-167757967380810"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-167757967380810"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-167757967380810"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-167757967380810"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-167757967380810"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-167757967380810"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-167757967380810"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-11"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="profile_picture_view_request_accepted_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Profile Picture View Request Accepted"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] has accepted your Profile Picture View Request<br></p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] has accepted
                                                your Profile Picture View
                                                Request
                                                <br />
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-167757967381811"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-167757967381811"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-167757967381811"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-167757967381811"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-167757967381811"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-167757967381811"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-167757967381811"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-167757967381811"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-167757967381811"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-167757967381811"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-12"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="gallery_image_view_request_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Gallery Image View Request"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] sent you Gallery Image View Request.\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] sent you Gallery
                                                Image View Request.
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-167757967382812"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-167757967382812"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-167757967382812"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-167757967382812"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-167757967382812"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-167757967382812"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-167757967382812"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-167757967382812"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-167757967382812"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-167757967382812"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-13"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="gallery_image_view_request_accepted_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Gallery Image View Request Accepted."
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] has accepted your Gallery Image View Request<br></p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] has accepted
                                                your Gallery Image View Request
                                                <br />
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-167757967383813"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-167757967383813"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-167757967383813"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-167757967383813"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-167757967383813"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-167757967383813"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-167757967383813"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-167757967383813"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-167757967383813"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-167757967383813"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="Package Purchase Email">
                              <div
                                className="tab-pane fade show active"
                                id="v-pills-5"
                                role="tabpanel"
                                aria-labelledby="v-pills-tab-1"
                              >
                                <form
                                  action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                  method="POST"
                                >
                                  <input
                                    type="hidden"
                                    name="_token"
                                    defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                  />{" "}
                                  <input
                                    type="hidden"
                                    name="identifier"
                                    defaultValue="package_purchase_email"
                                  />
                                  <div className="form-group row">
                                    <div className="col-md-2">
                                      <label className="col-from-label">
                                        Activation
                                      </label>
                                    </div>
                                    <div className="col-md-10">
                                      <label className="aiz-switch aiz-switch-success mb-0">
                                        <input
                                          defaultValue={1}
                                          name="status"
                                          type="checkbox"
                                          defaultChecked
                                        />
                                        <span className="slider round" />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-2 col-form-label">
                                      Subject
                                    </label>
                                    <div className="col-md-10">
                                      <input
                                        type="text"
                                        name="subject"
                                        defaultValue="Package Purchase Successful"
                                        className="form-control"
                                        placeholder="Subject"
                                        required
                                        fdprocessedid="z2jjoo"
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-2 col-form-label">
                                      Email Body
                                    </label>
                                    <div className="col-md-10">
                                      <textarea
                                        name="body"
                                        className="form-control aiz-text-editor"
                                        data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                        placeholder="Type.."
                                        data-min-height={300}
                                        required
                                        style={{ display: "none" }}
                                        defaultValue={
                                          "<p>Hi [[name]], </p><p>\nThank you for purchasing premium packages from our site&nbsp;[[site_name]]. </p><p>\nYour account type is : [[account_type]] </p><p>\nPayment code is: [[payment_code]]\n</p><p>Purchased Package is : [[package]] </p><p>\nThe amount is: $[[amount]] </p><p>\nPlease contact the&nbsp;administration&nbsp;team if you have any further questions. Best wishes. </p><p>Thanks, </p><p>\n[[from]]</p>"
                                        }
                                      />
                                      <div className="note-editor note-frame card">
                                        <div className="note-dropzone">
                                          <div className="note-dropzone-message" />
                                        </div>
                                        <div
                                          className="note-toolbar card-header"
                                          role="toolbar"
                                        >
                                          <div className="note-btn-group btn-group note-font">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-bold"
                                              tabIndex={-1}
                                              title
                                              aria-label="Bold (CTRL+B)"
                                              data-original-title="Bold (CTRL+B)"
                                            >
                                              <i className="note-icon-bold" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-underline"
                                              tabIndex={-1}
                                              title
                                              aria-label="Underline (CTRL+U)"
                                              data-original-title="Underline (CTRL+U)"
                                            >
                                              <i className="note-icon-underline" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-italic"
                                              tabIndex={-1}
                                              title
                                              aria-label="Italic (CTRL+I)"
                                              data-original-title="Italic (CTRL+I)"
                                            >
                                              <i className="note-icon-italic" />
                                            </button>
                                          </div>
                                          <div className="note-btn-group btn-group note-para">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                              data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                            >
                                              <i className="note-icon-unorderedlist" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                              data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                            >
                                              <i className="note-icon-orderedlist" />
                                            </button>
                                          </div>
                                          <div className="note-btn-group btn-group note-view">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Undo (CTRL+Z)"
                                              data-original-title="Undo (CTRL+Z)"
                                            >
                                              <i className="note-icon-undo" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Redo (CTRL+Y)"
                                              data-original-title="Redo (CTRL+Y)"
                                            >
                                              <i className="note-icon-redo" />
                                            </button>
                                          </div>
                                        </div>
                                        <div className="note-editing-area">
                                          <div
                                            className="note-placeholder"
                                            style={{ display: "none" }}
                                          >
                                            Type..
                                          </div>
                                          <div className="note-handle">
                                            <div
                                              className="note-control-selection"
                                              style={{ display: "none" }}
                                            >
                                              <div className="note-control-selection-bg" />
                                              <div className="note-control-holder note-control-nw" />
                                              <div className="note-control-holder note-control-ne" />
                                              <div className="note-control-holder note-control-sw" />
                                              <div className="note-control-sizing note-control-se" />
                                              <div className="note-control-selection-info" />
                                            </div>
                                          </div>
                                          <textarea
                                            className="note-codable"
                                            aria-multiline="true"
                                            defaultValue={""}
                                          />
                                          <div
                                            className="note-editable card-block"
                                            contentEditable="true"
                                            role="textbox"
                                            aria-multiline="true"
                                            spellCheck="true"
                                            autoCorrect="true"
                                            style={{ height: 300 }}
                                          >
                                            <p>Hi [[name]], </p>
                                            <p>
                                              Thank you for purchasing premium
                                              packages from our
                                              site&nbsp;[[site_name]].{" "}
                                            </p>
                                            <p>
                                              Your account type is :
                                              [[account_type]]{" "}
                                            </p>
                                            <p>
                                              Payment code is: [[payment_code]]
                                            </p>
                                            <p>
                                              Purchased Package is : [[package]]{" "}
                                            </p>
                                            <p>The amount is: $[[amount]] </p>
                                            <p>
                                              Please contact
                                              the&nbsp;administration&nbsp;team
                                              if you have any further questions.
                                              Best wishes.{" "}
                                            </p>
                                            <p>Thanks, </p>
                                            <p>[[from]]</p>
                                          </div>
                                        </div>
                                        <output
                                          className="note-status-output"
                                          role="status"
                                          aria-live="polite"
                                        />
                                        <div
                                          className="note-statusbar"
                                          role="status"
                                        >
                                          <output
                                            className="note-status-output"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-resizebar"
                                            aria-label="Resize"
                                          >
                                            <div className="note-icon-bar" />
                                            <div className="note-icon-bar" />
                                            <div className="note-icon-bar" />
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal link-dialog"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Link"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Link
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group">
                                                  <label
                                                    htmlFor="note-dialog-link-txt-16775796737455"
                                                    className="note-form-label"
                                                  >
                                                    Text to display
                                                  </label>
                                                  <input
                                                    id="note-dialog-link-txt-16775796737455"
                                                    className="note-link-text form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                                <div className="form-group note-form-group">
                                                  <label
                                                    htmlFor="note-dialog-link-url-16775796737455"
                                                    className="note-form-label"
                                                  >
                                                    To what URL should this link
                                                    go?
                                                  </label>
                                                  <input
                                                    id="note-dialog-link-url-16775796737455"
                                                    className="note-link-url form-control note-form-control note-input"
                                                    type="text"
                                                    defaultValue="http://"
                                                  />
                                                </div>
                                                <div className="form-check sn-checkbox-open-in-new-window">
                                                  <label className="form-check-label">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      defaultChecked
                                                      aria-label="Open in new window"
                                                      aria-checked="true"
                                                    />{" "}
                                                    Open in new window
                                                  </label>
                                                </div>
                                                <div className="form-check sn-checkbox-use-protocol">
                                                  <label className="form-check-label">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      defaultChecked
                                                      aria-label="Use default protocol"
                                                      aria-checked="true"
                                                    />{" "}
                                                    Use default protocol
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                  defaultValue="Insert Link"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="note-popover popover in note-link-popover bottom"
                                          style={{ display: "none" }}
                                        >
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <span>
                                              <a target="_blank" />
                                              &nbsp;
                                            </span>
                                            <div className="note-btn-group btn-group note-link">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Edit"
                                                data-original-title="Edit"
                                              >
                                                <i className="note-icon-link" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unlink"
                                                data-original-title="Unlink"
                                              >
                                                <i className="note-icon-chain-broken" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Image"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Image
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group note-group-select-from-files">
                                                  <label
                                                    htmlFor="note-dialog-image-file-16775796737455"
                                                    className="note-form-label"
                                                  >
                                                    Select from files
                                                  </label>
                                                  <input
                                                    id="note-dialog-image-file-16775796737455"
                                                    className="note-image-input form-control-file note-form-control note-input"
                                                    type="file"
                                                    name="files"
                                                    accept="image/*"
                                                    multiple="multiple"
                                                  />
                                                </div>
                                                <div className="form-group note-group-image-url">
                                                  <label
                                                    htmlFor="note-dialog-image-url-16775796737455"
                                                    className="note-form-label"
                                                  >
                                                    Image URL
                                                  </label>
                                                  <input
                                                    id="note-dialog-image-url-16775796737455"
                                                    className="note-image-url form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                  defaultValue="Insert Image"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="note-popover popover in note-image-popover bottom"
                                          style={{ display: "none" }}
                                        >
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <div className="note-btn-group btn-group note-resize">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize full"
                                                data-original-title="Resize full"
                                              >
                                                <span className="note-fontsize-10">
                                                  100%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize half"
                                                data-original-title="Resize half"
                                              >
                                                <span className="note-fontsize-10">
                                                  50%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize quarter"
                                                data-original-title="Resize quarter"
                                              >
                                                <span className="note-fontsize-10">
                                                  25%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Original size"
                                                data-original-title="Original size"
                                              >
                                                <i className="note-icon-rollback" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-float">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Float Left"
                                                data-original-title="Float Left"
                                              >
                                                <i className="note-icon-float-left" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Float Right"
                                                data-original-title="Float Right"
                                              >
                                                <i className="note-icon-float-right" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Remove float"
                                                data-original-title="Remove float"
                                              >
                                                <i className="note-icon-rollback" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-remove">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Remove Image"
                                                data-original-title="Remove Image"
                                              >
                                                <i className="note-icon-trash" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="note-popover popover in note-table-popover bottom"
                                          style={{ display: "none" }}
                                        >
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <div className="note-btn-group btn-group note-add">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add row below"
                                                data-original-title="Add row below"
                                              >
                                                <i className="note-icon-row-below" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add row above"
                                                data-original-title="Add row above"
                                              >
                                                <i className="note-icon-row-above" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add column left"
                                                data-original-title="Add column left"
                                              >
                                                <i className="note-icon-col-before" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add column right"
                                                data-original-title="Add column right"
                                              >
                                                <i className="note-icon-col-after" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-delete">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete row"
                                                data-original-title="Delete row"
                                              >
                                                <i className="note-icon-row-remove" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete column"
                                                data-original-title="Delete column"
                                              >
                                                <i className="note-icon-col-remove" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete table"
                                                data-original-title="Delete table"
                                              >
                                                <i className="note-icon-trash" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Video"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Video
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group row-fluid">
                                                  <label
                                                    htmlFor="note-dialog-video-url-16775796737455"
                                                    className="note-form-label"
                                                  >
                                                    Video URL{" "}
                                                    <small className="text-muted">
                                                      (YouTube, Vimeo, Vine,
                                                      Instagram, DailyMotion or
                                                      Youku)
                                                    </small>
                                                  </label>
                                                  <input
                                                    id="note-dialog-video-url-16775796737455"
                                                    className="note-video-url form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                  defaultValue="Insert Video"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Help"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Help
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div
                                                className="modal-body"
                                                style={{
                                                  maxHeight: 300,
                                                  overflow: "scroll",
                                                }}
                                              >
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>ENTER</kbd>
                                                </label>
                                                <span>Insert Paragraph</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+Z</kbd>
                                                </label>
                                                <span>
                                                  Undoes the last command
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+Y</kbd>
                                                </label>
                                                <span>
                                                  Redoes the last command
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>TAB</kbd>
                                                </label>
                                                <span>Tab</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>SHIFT+TAB</kbd>
                                                </label>
                                                <span>Untab</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+B</kbd>
                                                </label>
                                                <span>Set a bold style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+I</kbd>
                                                </label>
                                                <span>Set a italic style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+U</kbd>
                                                </label>
                                                <span>
                                                  Set a underline style
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+S</kbd>
                                                </label>
                                                <span>
                                                  Set a strikethrough style
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+BACKSLASH</kbd>
                                                </label>
                                                <span>Clean a style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+L</kbd>
                                                </label>
                                                <span>Set left align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+E</kbd>
                                                </label>
                                                <span>Set center align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+R</kbd>
                                                </label>
                                                <span>Set right align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+J</kbd>
                                                </label>
                                                <span>Set full align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+NUM7</kbd>
                                                </label>
                                                <span>
                                                  Toggle unordered list
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+NUM8</kbd>
                                                </label>
                                                <span>Toggle ordered list</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+LEFTBRACKET</kbd>
                                                </label>
                                                <span>
                                                  Outdent on current paragraph
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+RIGHTBRACKET</kbd>
                                                </label>
                                                <span>
                                                  Indent on current paragraph
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM0</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as a paragraph(P tag)
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM1</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H1
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM2</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H2
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM3</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H3
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM4</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H4
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM5</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H5
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM6</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H6
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+ENTER</kbd>
                                                </label>
                                                <span>
                                                  Insert horizontal rule
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+K</kbd>
                                                </label>
                                                <span>Show Link Dialog</span>
                                              </div>
                                              <div className="modal-footer">
                                                <p className="text-center">
                                                  <a
                                                    href="http://summernote.org/"
                                                    target="_blank"
                                                  >
                                                    Summernote 0.8.16
                                                  </a>{" "}
                                                  ·{" "}
                                                  <a
                                                    href="https://github.com/summernote/summernote"
                                                    target="_blank"
                                                  >
                                                    Project
                                                  </a>{" "}
                                                  ·{" "}
                                                  <a
                                                    href="https://github.com/summernote/summernote/issues"
                                                    target="_blank"
                                                  >
                                                    Issues
                                                  </a>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <small className="form-text text-danger">
                                        **N.B : Do Not Change The Variables Like
                                        [[ ____ ]].**
                                      </small>
                                    </div>
                                  </div>
                                  <div className="form-group mb-3 text-right">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                      fdprocessedid="wxkzlg"
                                    >
                                      Update Settings
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="Manual Payment Approval Email">
                              <div
                                className="tab-content"
                                id="v-pills-tabContent"
                              >
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-1"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="account_oppening_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Account Opening"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                          fdprocessedid="tdsdwf"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]],\n</p><p>Thank you for registering at our site:&nbsp;[[sitename]].\n</p><p>Your account type is : [[account_type]]\n</p><p>Email is: [[email]]\n</p><p>Password is : [[password]]\n</p><p>You will be able to log in from here : [[url]]\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. Best wishes.\n</p><p>Thanks,\n</p><p>[[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div
                                                className="note-control-selection"
                                                style={{ display: "none" }}
                                              >
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]],</p>
                                              <p>
                                                Thank you for registering at our
                                                site:&nbsp;[[sitename]].
                                              </p>
                                              <p>
                                                Your account type is :
                                                [[account_type]]
                                              </p>
                                              <p>Email is: [[email]]</p>
                                              <p>Password is : [[password]]</p>
                                              <p>
                                                You will be able to log in from
                                                here : [[url]]
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions. Best wishes.
                                              </p>
                                              <p>Thanks,</p>
                                              <p>[[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796736751"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796736751"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796736751"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796736751"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="note-popover popover in note-link-popover bottom"
                                            style={{ display: "none" }}
                                          >
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796736751"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796736751"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796736751"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796736751"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="note-popover popover in note-image-popover bottom"
                                            style={{ display: "none" }}
                                          >
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="note-popover popover in note-table-popover bottom"
                                            style={{ display: "none" }}
                                          >
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796736751"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796736751"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        fdprocessedid="ztknp"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-2"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="account_opening_email_to_admin"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Member Registration"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                          fdprocessedid="wze4zl"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Congratulation!\n</p><p>You have a new Registered Member in your system.\n</p><p>Member Name : [[member_name]]\n</p><p>Email is : [[email]]\n</p><p>Member Profile: [[profile_link]]\n</p><p>Thanks,\n</p><p>[[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div
                                            className="note-dropzone"
                                            style={{
                                              width: "707.525px",
                                              height: "360.938px",
                                            }}
                                          >
                                            <div className="note-dropzone-message">
                                              Drag image or text here
                                            </div>
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Congratulation!</p>
                                              <p>
                                                You have a new Registered Member
                                                in your system.
                                              </p>
                                              <p>
                                                Member Name : [[member_name]]
                                              </p>
                                              <p>Email is : [[email]]</p>
                                              <p>
                                                Member Profile: [[profile_link]]
                                              </p>
                                              <p>Thanks,</p>
                                              <p>[[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737072"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737072"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737072"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737072"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737072"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737072"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737072"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737072"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737072"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737072"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        fdprocessedid="5u49yx"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-3"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="account_approval_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Member Approval"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                          fdprocessedid="928t7j"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Congratulations [[name]],\n</p><p>Your account has been approved for [[sitename]]</p><p>You will be able to log in from here : [[url]]\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. Best wishes.\n</p><p>Thanks,\n</p><p>[[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Congratulations [[name]],</p>
                                              <p>
                                                Your account has been approved
                                                for [[sitename]]
                                              </p>
                                              <p>
                                                You will be able to log in from
                                                here : [[url]]
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions. Best wishes.
                                              </p>
                                              <p>Thanks,</p>
                                              <p>[[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737193"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737193"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737193"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737193"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737193"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737193"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737193"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737193"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737193"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737193"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        fdprocessedid="04xq6x"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-4"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="staff_account_opening_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Account Opening"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                          fdprocessedid="5j7klnr"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]],\n</p><p>You have been added to&nbsp;[[site_name]] as Staff.\n</p><p>Your account role is : [[role_type]]\n</p><p>Email is : [[email]]\n</p><p>Password is : [[password]]\n</p><p>You will be able to log in from here : [[url]]\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. Best wishes.\n</p><p>Thanks,\n</p><p>[[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div
                                            className="note-dropzone"
                                            style={{
                                              width: "707.525px",
                                              height: "360.938px",
                                            }}
                                          >
                                            <div className="note-dropzone-message">
                                              Drag image or text here
                                            </div>
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]],</p>
                                              <p>
                                                You have been added
                                                to&nbsp;[[site_name]] as Staff.
                                              </p>
                                              <p>
                                                Your account role is :
                                                [[role_type]]
                                              </p>
                                              <p>Email is : [[email]]</p>
                                              <p>Password is : [[password]]</p>
                                              <p>
                                                You will be able to log in from
                                                here : [[url]]
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions. Best wishes.
                                              </p>
                                              <p>Thanks,</p>
                                              <p>[[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737334"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737334"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737334"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737334"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737334"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737334"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737334"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737334"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737334"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737334"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        fdprocessedid="x9tj29"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-5"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="package_purchase_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Package Purchase Successful"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                          fdprocessedid="z2jjoo"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\nThank you for purchasing premium packages from our site&nbsp;[[site_name]]. </p><p>\nYour account type is : [[account_type]] </p><p>\nPayment code is: [[payment_code]]\n</p><p>Purchased Package is : [[package]] </p><p>\nThe amount is: $[[amount]] </p><p>\nPlease contact the&nbsp;administration&nbsp;team if you have any further questions. Best wishes. </p><p>Thanks, </p><p>\n[[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div
                                                className="note-control-selection"
                                                style={{ display: "none" }}
                                              >
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                Thank you for purchasing premium
                                                packages from our
                                                site&nbsp;[[site_name]].{" "}
                                              </p>
                                              <p>
                                                Your account type is :
                                                [[account_type]]{" "}
                                              </p>
                                              <p>
                                                Payment code is:
                                                [[payment_code]]
                                              </p>
                                              <p>
                                                Purchased Package is :
                                                [[package]]{" "}
                                              </p>
                                              <p>The amount is: $[[amount]] </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions. Best wishes.{" "}
                                              </p>
                                              <p>Thanks, </p>
                                              <p>[[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737455"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737455"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737455"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737455"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="note-popover popover in note-link-popover bottom"
                                            style={{ display: "none" }}
                                          >
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737455"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737455"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737455"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737455"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="note-popover popover in note-image-popover bottom"
                                            style={{ display: "none" }}
                                          >
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="note-popover popover in note-table-popover bottom"
                                            style={{ display: "none" }}
                                          >
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737455"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737455"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        fdprocessedid="wxkzlg"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade active show"
                                  id="v-pills-6"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="manual_payment_approval_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Payment Approved"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                          fdprocessedid="h0ged"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\nManual payment for package purchases has been approved.\n</p><p>Your account type is : [[account_type]] </p><p>\nPayment code is: [[payment_code]]\n</p><p>Purchased Package is : [[package]] </p><p>\nThe amount is: $[[amount]] </p><p>\nPlease contact the&nbsp;administration&nbsp;team if you have any further questions. Best wishes. </p><p>\nThanks, </p><p>\n[[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                Manual payment for package
                                                purchases has been approved.
                                              </p>
                                              <p>
                                                Your account type is :
                                                [[account_type]]{" "}
                                              </p>
                                              <p>
                                                Payment code is:
                                                [[payment_code]]
                                              </p>
                                              <p>
                                                Purchased Package is :
                                                [[package]]{" "}
                                              </p>
                                              <p>The amount is: $[[amount]] </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions. Best wishes.{" "}
                                              </p>
                                              <p>Thanks, </p>
                                              <p>[[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737566"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737566"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737566"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737566"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737566"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737566"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737566"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737566"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737566"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737566"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        fdprocessedid="rmzikh"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-7"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="email_on_express_interest"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="You have a new Interest request"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                          fdprocessedid="kvcweg"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] has Expressed interest in you.\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div
                                            className="note-dropzone"
                                            style={{
                                              width: "707.525px",
                                              height: "360.938px",
                                            }}
                                          >
                                            <div className="note-dropzone-message">
                                              Drag image or text here
                                            </div>
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] has Expressed
                                                interest in you.
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737697"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737697"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737697"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737697"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737697"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737697"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737697"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737697"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737697"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737697"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        fdprocessedid="ib0cu"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-8"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="email_on_accepting_interest"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Interest Accepted"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] has accepted your interest. Now you can send messages to each other. </p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] has accepted
                                                your interest. Now you can send
                                                messages to each other.{" "}
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737868"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737868"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737868"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737868"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737868"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737868"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737868"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737868"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737868"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737868"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-9"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="password_reset_email"
                                    />
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Password Reset"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p></p><p>Hi [[name]], </p>Your Password reset Verification Code is : [[code]]<br><p><br></p><p>\nThanks,\n</p><p> [[from]]</p><p></p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p />
                                              <p>Hi [[name]], </p>Your Password
                                              reset Verification Code is :
                                              [[code]]
                                              <br />
                                              <p>
                                                <br />
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                              <p />
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-16775796737979"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-16775796737979"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-16775796737979"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-16775796737979"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-16775796737979"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-16775796737979"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-16775796737979"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-16775796737979"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-16775796737979"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-16775796737979"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-10"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="profile_picture_view_request_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Profile Picture View Request"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] sent you Profile Picture View Request.\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] sent you Profile
                                                Picture View Request.
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-167757967380810"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-167757967380810"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-167757967380810"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-167757967380810"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-167757967380810"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-167757967380810"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-167757967380810"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-167757967380810"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-167757967380810"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-167757967380810"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-11"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="profile_picture_view_request_accepted_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Profile Picture View Request Accepted"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] has accepted your Profile Picture View Request<br></p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] has accepted
                                                your Profile Picture View
                                                Request
                                                <br />
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-167757967381811"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-167757967381811"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-167757967381811"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-167757967381811"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-167757967381811"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-167757967381811"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-167757967381811"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-167757967381811"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-167757967381811"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-167757967381811"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-12"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="gallery_image_view_request_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Gallery Image View Request"
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] sent you Gallery Image View Request.\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] sent you Gallery
                                                Image View Request.
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-167757967382812"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-167757967382812"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-167757967382812"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-167757967382812"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-167757967382812"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-167757967382812"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-167757967382812"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-167757967382812"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-167757967382812"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-167757967382812"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div
                                  className="tab-pane fade"
                                  id="v-pills-13"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-tab-1"
                                >
                                  <form
                                    action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                    method="POST"
                                  >
                                    <input
                                      type="hidden"
                                      name="_token"
                                      defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                    />{" "}
                                    <input
                                      type="hidden"
                                      name="identifier"
                                      defaultValue="gallery_image_view_request_accepted_email"
                                    />
                                    <div className="form-group row">
                                      <div className="col-md-2">
                                        <label className="col-from-label">
                                          Activation
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <label className="aiz-switch aiz-switch-success mb-0">
                                          <input
                                            defaultValue={1}
                                            name="status"
                                            type="checkbox"
                                            defaultChecked
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Subject
                                      </label>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          name="subject"
                                          defaultValue="Gallery Image View Request Accepted."
                                          className="form-control"
                                          placeholder="Subject"
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-md-2 col-form-label">
                                        Email Body
                                      </label>
                                      <div className="col-md-10">
                                        <textarea
                                          name="body"
                                          className="form-control aiz-text-editor"
                                          data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                          placeholder="Type.."
                                          data-min-height={300}
                                          required
                                          style={{ display: "none" }}
                                          defaultValue={
                                            "<p>Hi [[name]], </p><p>\n[[member_name]] has accepted your Gallery Image View Request<br></p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                          }
                                        />
                                        <div className="note-editor note-frame card">
                                          <div className="note-dropzone">
                                            <div className="note-dropzone-message" />
                                          </div>
                                          <div
                                            className="note-toolbar card-header"
                                            role="toolbar"
                                          >
                                            <div className="note-btn-group btn-group note-font">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-bold"
                                                tabIndex={-1}
                                                title
                                                aria-label="Bold (CTRL+B)"
                                                data-original-title="Bold (CTRL+B)"
                                              >
                                                <i className="note-icon-bold" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-underline"
                                                tabIndex={-1}
                                                title
                                                aria-label="Underline (CTRL+U)"
                                                data-original-title="Underline (CTRL+U)"
                                              >
                                                <i className="note-icon-underline" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm note-btn-italic"
                                                tabIndex={-1}
                                                title
                                                aria-label="Italic (CTRL+I)"
                                                data-original-title="Italic (CTRL+I)"
                                              >
                                                <i className="note-icon-italic" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-para">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                                data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                              >
                                                <i className="note-icon-unorderedlist" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                                data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                              >
                                                <i className="note-icon-orderedlist" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-view">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Undo (CTRL+Z)"
                                                data-original-title="Undo (CTRL+Z)"
                                              >
                                                <i className="note-icon-undo" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Redo (CTRL+Y)"
                                                data-original-title="Redo (CTRL+Y)"
                                              >
                                                <i className="note-icon-redo" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="note-editing-area">
                                            <div
                                              className="note-placeholder"
                                              style={{ display: "none" }}
                                            >
                                              Type..
                                            </div>
                                            <div className="note-handle">
                                              <div className="note-control-selection">
                                                <div className="note-control-selection-bg" />
                                                <div className="note-control-holder note-control-nw" />
                                                <div className="note-control-holder note-control-ne" />
                                                <div className="note-control-holder note-control-sw" />
                                                <div className="note-control-sizing note-control-se" />
                                                <div className="note-control-selection-info" />
                                              </div>
                                            </div>
                                            <textarea
                                              className="note-codable"
                                              aria-multiline="true"
                                              defaultValue={""}
                                            />
                                            <div
                                              className="note-editable card-block"
                                              contentEditable="true"
                                              role="textbox"
                                              aria-multiline="true"
                                              spellCheck="true"
                                              autoCorrect="true"
                                              style={{ height: 300 }}
                                            >
                                              <p>Hi [[name]], </p>
                                              <p>
                                                [[member_name]] has accepted
                                                your Gallery Image View Request
                                                <br />
                                              </p>
                                              <p>
                                                Please contact
                                                the&nbsp;administration&nbsp;team
                                                if you have any further
                                                questions.{" "}
                                              </p>
                                              <p>Thanks,</p>
                                              <p> [[from]]</p>
                                            </div>
                                          </div>
                                          <output
                                            className="note-status-output"
                                            role="status"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-statusbar"
                                            role="status"
                                          >
                                            <output
                                              className="note-status-output"
                                              aria-live="polite"
                                            />
                                            <div
                                              className="note-resizebar"
                                              aria-label="Resize"
                                            >
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                              <div className="note-icon-bar" />
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal link-dialog"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Link"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Link
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-txt-167757967383813"
                                                      className="note-form-label"
                                                    >
                                                      Text to display
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-txt-167757967383813"
                                                      className="note-link-text form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                  <div className="form-group note-form-group">
                                                    <label
                                                      htmlFor="note-dialog-link-url-167757967383813"
                                                      className="note-form-label"
                                                    >
                                                      To what URL should this
                                                      link go?
                                                    </label>
                                                    <input
                                                      id="note-dialog-link-url-167757967383813"
                                                      className="note-link-url form-control note-form-control note-input"
                                                      type="text"
                                                      defaultValue="http://"
                                                    />
                                                  </div>
                                                  <div className="form-check sn-checkbox-open-in-new-window">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Open in new window"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Open in new window
                                                    </label>
                                                  </div>
                                                  <div className="form-check sn-checkbox-use-protocol">
                                                    <label className="form-check-label">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        aria-label="Use default protocol"
                                                        aria-checked="true"
                                                      />{" "}
                                                      Use default protocol
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                    defaultValue="Insert Link"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-link-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <span>
                                                <a target="_blank" />
                                                &nbsp;
                                              </span>
                                              <div className="note-btn-group btn-group note-link">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Edit"
                                                  data-original-title="Edit"
                                                >
                                                  <i className="note-icon-link" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Unlink"
                                                  data-original-title="Unlink"
                                                >
                                                  <i className="note-icon-chain-broken" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Image"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Image
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group note-group-select-from-files">
                                                    <label
                                                      htmlFor="note-dialog-image-file-167757967383813"
                                                      className="note-form-label"
                                                    >
                                                      Select from files
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-file-167757967383813"
                                                      className="note-image-input form-control-file note-form-control note-input"
                                                      type="file"
                                                      name="files"
                                                      accept="image/*"
                                                      multiple="multiple"
                                                    />
                                                  </div>
                                                  <div className="form-group note-group-image-url">
                                                    <label
                                                      htmlFor="note-dialog-image-url-167757967383813"
                                                      className="note-form-label"
                                                    >
                                                      Image URL
                                                    </label>
                                                    <input
                                                      id="note-dialog-image-url-167757967383813"
                                                      className="note-image-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                    defaultValue="Insert Image"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-image-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-resize">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize full"
                                                  data-original-title="Resize full"
                                                >
                                                  <span className="note-fontsize-10">
                                                    100%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize half"
                                                  data-original-title="Resize half"
                                                >
                                                  <span className="note-fontsize-10">
                                                    50%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Resize quarter"
                                                  data-original-title="Resize quarter"
                                                >
                                                  <span className="note-fontsize-10">
                                                    25%
                                                  </span>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Original size"
                                                  data-original-title="Original size"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-float">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Left"
                                                  data-original-title="Float Left"
                                                >
                                                  <i className="note-icon-float-left" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Float Right"
                                                  data-original-title="Float Right"
                                                >
                                                  <i className="note-icon-float-right" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove float"
                                                  data-original-title="Remove float"
                                                >
                                                  <i className="note-icon-rollback" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-remove">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Remove Image"
                                                  data-original-title="Remove Image"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="note-popover popover in note-table-popover bottom">
                                            <div className="arrow" />
                                            <div className="popover-content note-children-container">
                                              <div className="note-btn-group btn-group note-add">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row below"
                                                  data-original-title="Add row below"
                                                >
                                                  <i className="note-icon-row-below" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add row above"
                                                  data-original-title="Add row above"
                                                >
                                                  <i className="note-icon-row-above" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column left"
                                                  data-original-title="Add column left"
                                                >
                                                  <i className="note-icon-col-before" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Add column right"
                                                  data-original-title="Add column right"
                                                >
                                                  <i className="note-icon-col-after" />
                                                </button>
                                              </div>
                                              <div className="note-btn-group btn-group note-delete">
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete row"
                                                  data-original-title="Delete row"
                                                >
                                                  <i className="note-icon-row-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete column"
                                                  data-original-title="Delete column"
                                                >
                                                  <i className="note-icon-col-remove" />
                                                </button>
                                                <button
                                                  type="button"
                                                  className="note-btn btn btn-light btn-sm btn-md"
                                                  tabIndex={-1}
                                                  title
                                                  aria-label="Delete table"
                                                  data-original-title="Delete table"
                                                >
                                                  <i className="note-icon-trash" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Insert Video"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Insert Video
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <div className="form-group note-form-group row-fluid">
                                                    <label
                                                      htmlFor="note-dialog-video-url-167757967383813"
                                                      className="note-form-label"
                                                    >
                                                      Video URL{" "}
                                                      <small className="text-muted">
                                                        (YouTube, Vimeo, Vine,
                                                        Instagram, DailyMotion
                                                        or Youku)
                                                      </small>
                                                    </label>
                                                    <input
                                                      id="note-dialog-video-url-167757967383813"
                                                      className="note-video-url form-control note-form-control note-input"
                                                      type="text"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <input
                                                    type="button"
                                                    href="#"
                                                    className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                    defaultValue="Insert Video"
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="modal note-modal"
                                            aria-hidden="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-label="Help"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h4 className="modal-title">
                                                    Help
                                                  </h4>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    aria-hidden="true"
                                                  >
                                                    ×
                                                  </button>
                                                </div>
                                                <div
                                                  className="modal-body"
                                                  style={{
                                                    maxHeight: 300,
                                                    overflow: "scroll",
                                                  }}
                                                >
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>ENTER</kbd>
                                                  </label>
                                                  <span>Insert Paragraph</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Z</kbd>
                                                  </label>
                                                  <span>
                                                    Undoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+Y</kbd>
                                                  </label>
                                                  <span>
                                                    Redoes the last command
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>TAB</kbd>
                                                  </label>
                                                  <span>Tab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>SHIFT+TAB</kbd>
                                                  </label>
                                                  <span>Untab</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+B</kbd>
                                                  </label>
                                                  <span>Set a bold style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+I</kbd>
                                                  </label>
                                                  <span>
                                                    Set a italic style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+U</kbd>
                                                  </label>
                                                  <span>
                                                    Set a underline style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+S</kbd>
                                                  </label>
                                                  <span>
                                                    Set a strikethrough style
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+BACKSLASH</kbd>
                                                  </label>
                                                  <span>Clean a style</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+L</kbd>
                                                  </label>
                                                  <span>Set left align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+E</kbd>
                                                  </label>
                                                  <span>Set center align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+R</kbd>
                                                  </label>
                                                  <span>Set right align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+J</kbd>
                                                  </label>
                                                  <span>Set full align</span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM7</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle unordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+SHIFT+NUM8</kbd>
                                                  </label>
                                                  <span>
                                                    Toggle ordered list
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+LEFTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Outdent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+RIGHTBRACKET</kbd>
                                                  </label>
                                                  <span>
                                                    Indent on current paragraph
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM0</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as a paragraph(P tag)
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM1</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H1
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM2</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H2
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM3</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H3
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM4</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H4
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM5</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H5
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+NUM6</kbd>
                                                  </label>
                                                  <span>
                                                    Change current block's
                                                    format as H6
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+ENTER</kbd>
                                                  </label>
                                                  <span>
                                                    Insert horizontal rule
                                                  </span>
                                                  <div className="help-list-item" />
                                                  <label
                                                    style={{
                                                      width: 180,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <kbd>CTRL+K</kbd>
                                                  </label>
                                                  <span>Show Link Dialog</span>
                                                </div>
                                                <div className="modal-footer">
                                                  <p className="text-center">
                                                    <a
                                                      href="http://summernote.org/"
                                                      target="_blank"
                                                    >
                                                      Summernote 0.8.16
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote"
                                                      target="_blank"
                                                    >
                                                      Project
                                                    </a>{" "}
                                                    ·{" "}
                                                    <a
                                                      href="https://github.com/summernote/summernote/issues"
                                                      target="_blank"
                                                    >
                                                      Issues
                                                    </a>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <small className="form-text text-danger">
                                          **N.B : Do Not Change The Variables
                                          Like [[ ____ ]].**
                                        </small>
                                      </div>
                                    </div>
                                    <div className="form-group mb-3 text-right">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Update Settings
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="Email On Express Interest">
                              <div
                                className="tab-pane fade active show"
                                id="v-pills-7"
                                role="tabpanel"
                                aria-labelledby="v-pills-tab-1"
                              >
                                <form
                                  action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                  method="POST"
                                >
                                  <input
                                    type="hidden"
                                    name="_token"
                                    defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                  />{" "}
                                  <input
                                    type="hidden"
                                    name="identifier"
                                    defaultValue="email_on_express_interest"
                                  />
                                  <div className="form-group row">
                                    <div className="col-md-2">
                                      <label className="col-from-label">
                                        Activation
                                      </label>
                                    </div>
                                    <div className="col-md-10">
                                      <label className="aiz-switch aiz-switch-success mb-0">
                                        <input
                                          defaultValue={1}
                                          name="status"
                                          type="checkbox"
                                          defaultChecked
                                        />
                                        <span className="slider round" />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-2 col-form-label">
                                      Subject
                                    </label>
                                    <div className="col-md-10">
                                      <input
                                        type="text"
                                        name="subject"
                                        defaultValue="You have a new Interest request"
                                        className="form-control"
                                        placeholder="Subject"
                                        required
                                        fdprocessedid="kvcweg"
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-2 col-form-label">
                                      Email Body
                                    </label>
                                    <div className="col-md-10">
                                      <textarea
                                        name="body"
                                        className="form-control aiz-text-editor"
                                        data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                        placeholder="Type.."
                                        data-min-height={300}
                                        required
                                        style={{ display: "none" }}
                                        defaultValue={
                                          "<p>Hi [[name]], </p><p>\n[[member_name]] has Expressed interest in you.\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                        }
                                      />
                                      <div className="note-editor note-frame card">
                                        <div
                                          className="note-dropzone"
                                          style={{
                                            width: "707.525px",
                                            height: "360.938px",
                                          }}
                                        >
                                          <div className="note-dropzone-message">
                                            Drag image or text here
                                          </div>
                                        </div>
                                        <div
                                          className="note-toolbar card-header"
                                          role="toolbar"
                                        >
                                          <div className="note-btn-group btn-group note-font">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-bold"
                                              tabIndex={-1}
                                              title
                                              aria-label="Bold (CTRL+B)"
                                              data-original-title="Bold (CTRL+B)"
                                            >
                                              <i className="note-icon-bold" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-underline"
                                              tabIndex={-1}
                                              title
                                              aria-label="Underline (CTRL+U)"
                                              data-original-title="Underline (CTRL+U)"
                                            >
                                              <i className="note-icon-underline" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-italic"
                                              tabIndex={-1}
                                              title
                                              aria-label="Italic (CTRL+I)"
                                              data-original-title="Italic (CTRL+I)"
                                            >
                                              <i className="note-icon-italic" />
                                            </button>
                                          </div>
                                          <div className="note-btn-group btn-group note-para">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                              data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                            >
                                              <i className="note-icon-unorderedlist" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                              data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                            >
                                              <i className="note-icon-orderedlist" />
                                            </button>
                                          </div>
                                          <div className="note-btn-group btn-group note-view">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Undo (CTRL+Z)"
                                              data-original-title="Undo (CTRL+Z)"
                                            >
                                              <i className="note-icon-undo" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Redo (CTRL+Y)"
                                              data-original-title="Redo (CTRL+Y)"
                                            >
                                              <i className="note-icon-redo" />
                                            </button>
                                          </div>
                                        </div>
                                        <div className="note-editing-area">
                                          <div
                                            className="note-placeholder"
                                            style={{ display: "none" }}
                                          >
                                            Type..
                                          </div>
                                          <div className="note-handle">
                                            <div className="note-control-selection">
                                              <div className="note-control-selection-bg" />
                                              <div className="note-control-holder note-control-nw" />
                                              <div className="note-control-holder note-control-ne" />
                                              <div className="note-control-holder note-control-sw" />
                                              <div className="note-control-sizing note-control-se" />
                                              <div className="note-control-selection-info" />
                                            </div>
                                          </div>
                                          <textarea
                                            className="note-codable"
                                            aria-multiline="true"
                                            defaultValue={""}
                                          />
                                          <div
                                            className="note-editable card-block"
                                            contentEditable="true"
                                            role="textbox"
                                            aria-multiline="true"
                                            spellCheck="true"
                                            autoCorrect="true"
                                            style={{ height: 300 }}
                                          >
                                            <p>Hi [[name]], </p>
                                            <p>
                                              [[member_name]] has Expressed
                                              interest in you.
                                            </p>
                                            <p>
                                              Please contact
                                              the&nbsp;administration&nbsp;team
                                              if you have any further questions.{" "}
                                            </p>
                                            <p>Thanks,</p>
                                            <p> [[from]]</p>
                                          </div>
                                        </div>
                                        <output
                                          className="note-status-output"
                                          role="status"
                                          aria-live="polite"
                                        />
                                        <div
                                          className="note-statusbar"
                                          role="status"
                                        >
                                          <output
                                            className="note-status-output"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-resizebar"
                                            aria-label="Resize"
                                          >
                                            <div className="note-icon-bar" />
                                            <div className="note-icon-bar" />
                                            <div className="note-icon-bar" />
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal link-dialog"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Link"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Link
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group">
                                                  <label
                                                    htmlFor="note-dialog-link-txt-16775796737697"
                                                    className="note-form-label"
                                                  >
                                                    Text to display
                                                  </label>
                                                  <input
                                                    id="note-dialog-link-txt-16775796737697"
                                                    className="note-link-text form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                                <div className="form-group note-form-group">
                                                  <label
                                                    htmlFor="note-dialog-link-url-16775796737697"
                                                    className="note-form-label"
                                                  >
                                                    To what URL should this link
                                                    go?
                                                  </label>
                                                  <input
                                                    id="note-dialog-link-url-16775796737697"
                                                    className="note-link-url form-control note-form-control note-input"
                                                    type="text"
                                                    defaultValue="http://"
                                                  />
                                                </div>
                                                <div className="form-check sn-checkbox-open-in-new-window">
                                                  <label className="form-check-label">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      defaultChecked
                                                      aria-label="Open in new window"
                                                      aria-checked="true"
                                                    />{" "}
                                                    Open in new window
                                                  </label>
                                                </div>
                                                <div className="form-check sn-checkbox-use-protocol">
                                                  <label className="form-check-label">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      defaultChecked
                                                      aria-label="Use default protocol"
                                                      aria-checked="true"
                                                    />{" "}
                                                    Use default protocol
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                  defaultValue="Insert Link"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-link-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <span>
                                              <a target="_blank" />
                                              &nbsp;
                                            </span>
                                            <div className="note-btn-group btn-group note-link">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Edit"
                                                data-original-title="Edit"
                                              >
                                                <i className="note-icon-link" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unlink"
                                                data-original-title="Unlink"
                                              >
                                                <i className="note-icon-chain-broken" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Image"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Image
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group note-group-select-from-files">
                                                  <label
                                                    htmlFor="note-dialog-image-file-16775796737697"
                                                    className="note-form-label"
                                                  >
                                                    Select from files
                                                  </label>
                                                  <input
                                                    id="note-dialog-image-file-16775796737697"
                                                    className="note-image-input form-control-file note-form-control note-input"
                                                    type="file"
                                                    name="files"
                                                    accept="image/*"
                                                    multiple="multiple"
                                                  />
                                                </div>
                                                <div className="form-group note-group-image-url">
                                                  <label
                                                    htmlFor="note-dialog-image-url-16775796737697"
                                                    className="note-form-label"
                                                  >
                                                    Image URL
                                                  </label>
                                                  <input
                                                    id="note-dialog-image-url-16775796737697"
                                                    className="note-image-url form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                  defaultValue="Insert Image"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-image-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <div className="note-btn-group btn-group note-resize">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize full"
                                                data-original-title="Resize full"
                                              >
                                                <span className="note-fontsize-10">
                                                  100%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize half"
                                                data-original-title="Resize half"
                                              >
                                                <span className="note-fontsize-10">
                                                  50%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize quarter"
                                                data-original-title="Resize quarter"
                                              >
                                                <span className="note-fontsize-10">
                                                  25%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Original size"
                                                data-original-title="Original size"
                                              >
                                                <i className="note-icon-rollback" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-float">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Float Left"
                                                data-original-title="Float Left"
                                              >
                                                <i className="note-icon-float-left" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Float Right"
                                                data-original-title="Float Right"
                                              >
                                                <i className="note-icon-float-right" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Remove float"
                                                data-original-title="Remove float"
                                              >
                                                <i className="note-icon-rollback" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-remove">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Remove Image"
                                                data-original-title="Remove Image"
                                              >
                                                <i className="note-icon-trash" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-table-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <div className="note-btn-group btn-group note-add">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add row below"
                                                data-original-title="Add row below"
                                              >
                                                <i className="note-icon-row-below" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add row above"
                                                data-original-title="Add row above"
                                              >
                                                <i className="note-icon-row-above" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add column left"
                                                data-original-title="Add column left"
                                              >
                                                <i className="note-icon-col-before" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add column right"
                                                data-original-title="Add column right"
                                              >
                                                <i className="note-icon-col-after" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-delete">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete row"
                                                data-original-title="Delete row"
                                              >
                                                <i className="note-icon-row-remove" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete column"
                                                data-original-title="Delete column"
                                              >
                                                <i className="note-icon-col-remove" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete table"
                                                data-original-title="Delete table"
                                              >
                                                <i className="note-icon-trash" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Video"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Video
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group row-fluid">
                                                  <label
                                                    htmlFor="note-dialog-video-url-16775796737697"
                                                    className="note-form-label"
                                                  >
                                                    Video URL{" "}
                                                    <small className="text-muted">
                                                      (YouTube, Vimeo, Vine,
                                                      Instagram, DailyMotion or
                                                      Youku)
                                                    </small>
                                                  </label>
                                                  <input
                                                    id="note-dialog-video-url-16775796737697"
                                                    className="note-video-url form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                  defaultValue="Insert Video"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Help"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Help
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div
                                                className="modal-body"
                                                style={{
                                                  maxHeight: 300,
                                                  overflow: "scroll",
                                                }}
                                              >
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>ENTER</kbd>
                                                </label>
                                                <span>Insert Paragraph</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+Z</kbd>
                                                </label>
                                                <span>
                                                  Undoes the last command
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+Y</kbd>
                                                </label>
                                                <span>
                                                  Redoes the last command
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>TAB</kbd>
                                                </label>
                                                <span>Tab</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>SHIFT+TAB</kbd>
                                                </label>
                                                <span>Untab</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+B</kbd>
                                                </label>
                                                <span>Set a bold style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+I</kbd>
                                                </label>
                                                <span>Set a italic style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+U</kbd>
                                                </label>
                                                <span>
                                                  Set a underline style
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+S</kbd>
                                                </label>
                                                <span>
                                                  Set a strikethrough style
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+BACKSLASH</kbd>
                                                </label>
                                                <span>Clean a style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+L</kbd>
                                                </label>
                                                <span>Set left align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+E</kbd>
                                                </label>
                                                <span>Set center align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+R</kbd>
                                                </label>
                                                <span>Set right align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+J</kbd>
                                                </label>
                                                <span>Set full align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+NUM7</kbd>
                                                </label>
                                                <span>
                                                  Toggle unordered list
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+NUM8</kbd>
                                                </label>
                                                <span>Toggle ordered list</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+LEFTBRACKET</kbd>
                                                </label>
                                                <span>
                                                  Outdent on current paragraph
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+RIGHTBRACKET</kbd>
                                                </label>
                                                <span>
                                                  Indent on current paragraph
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM0</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as a paragraph(P tag)
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM1</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H1
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM2</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H2
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM3</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H3
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM4</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H4
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM5</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H5
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM6</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H6
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+ENTER</kbd>
                                                </label>
                                                <span>
                                                  Insert horizontal rule
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+K</kbd>
                                                </label>
                                                <span>Show Link Dialog</span>
                                              </div>
                                              <div className="modal-footer">
                                                <p className="text-center">
                                                  <a
                                                    href="http://summernote.org/"
                                                    target="_blank"
                                                  >
                                                    Summernote 0.8.16
                                                  </a>{" "}
                                                  ·{" "}
                                                  <a
                                                    href="https://github.com/summernote/summernote"
                                                    target="_blank"
                                                  >
                                                    Project
                                                  </a>{" "}
                                                  ·{" "}
                                                  <a
                                                    href="https://github.com/summernote/summernote/issues"
                                                    target="_blank"
                                                  >
                                                    Issues
                                                  </a>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <small className="form-text text-danger">
                                        **N.B : Do Not Change The Variables Like
                                        [[ ____ ]].**
                                      </small>
                                    </div>
                                  </div>
                                  <div className="form-group mb-3 text-right">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                      fdprocessedid="ib0cu"
                                    >
                                      Update Settings
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="Email On Accepting Interest">
                              <div
                                className="tab-pane fade active show"
                                id="v-pills-8"
                                role="tabpanel"
                                aria-labelledby="v-pills-tab-1"
                              >
                                <form
                                  action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                  method="POST"
                                >
                                  <input
                                    type="hidden"
                                    name="_token"
                                    defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                  />{" "}
                                  <input
                                    type="hidden"
                                    name="identifier"
                                    defaultValue="email_on_accepting_interest"
                                  />
                                  <div className="form-group row">
                                    <div className="col-md-2">
                                      <label className="col-from-label">
                                        Activation
                                      </label>
                                    </div>
                                    <div className="col-md-10">
                                      <label className="aiz-switch aiz-switch-success mb-0">
                                        <input
                                          defaultValue={1}
                                          name="status"
                                          type="checkbox"
                                          defaultChecked
                                        />
                                        <span className="slider round" />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-2 col-form-label">
                                      Subject
                                    </label>
                                    <div className="col-md-10">
                                      <input
                                        type="text"
                                        name="subject"
                                        defaultValue="Interest Accepted"
                                        className="form-control"
                                        placeholder="Subject"
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-2 col-form-label">
                                      Email Body
                                    </label>
                                    <div className="col-md-10">
                                      <textarea
                                        name="body"
                                        className="form-control aiz-text-editor"
                                        data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                        placeholder="Type.."
                                        data-min-height={300}
                                        required
                                        style={{ display: "none" }}
                                        defaultValue={
                                          "<p>Hi [[name]], </p><p>\n[[member_name]] has accepted your interest. Now you can send messages to each other. </p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                        }
                                      />
                                      <div className="note-editor note-frame card">
                                        <div className="note-dropzone">
                                          <div className="note-dropzone-message" />
                                        </div>
                                        <div
                                          className="note-toolbar card-header"
                                          role="toolbar"
                                        >
                                          <div className="note-btn-group btn-group note-font">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-bold"
                                              tabIndex={-1}
                                              title
                                              aria-label="Bold (CTRL+B)"
                                              data-original-title="Bold (CTRL+B)"
                                            >
                                              <i className="note-icon-bold" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-underline"
                                              tabIndex={-1}
                                              title
                                              aria-label="Underline (CTRL+U)"
                                              data-original-title="Underline (CTRL+U)"
                                            >
                                              <i className="note-icon-underline" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-italic"
                                              tabIndex={-1}
                                              title
                                              aria-label="Italic (CTRL+I)"
                                              data-original-title="Italic (CTRL+I)"
                                            >
                                              <i className="note-icon-italic" />
                                            </button>
                                          </div>
                                          <div className="note-btn-group btn-group note-para">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                              data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                            >
                                              <i className="note-icon-unorderedlist" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                              data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                            >
                                              <i className="note-icon-orderedlist" />
                                            </button>
                                          </div>
                                          <div className="note-btn-group btn-group note-view">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Undo (CTRL+Z)"
                                              data-original-title="Undo (CTRL+Z)"
                                            >
                                              <i className="note-icon-undo" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Redo (CTRL+Y)"
                                              data-original-title="Redo (CTRL+Y)"
                                            >
                                              <i className="note-icon-redo" />
                                            </button>
                                          </div>
                                        </div>
                                        <div className="note-editing-area">
                                          <div
                                            className="note-placeholder"
                                            style={{ display: "none" }}
                                          >
                                            Type..
                                          </div>
                                          <div className="note-handle">
                                            <div className="note-control-selection">
                                              <div className="note-control-selection-bg" />
                                              <div className="note-control-holder note-control-nw" />
                                              <div className="note-control-holder note-control-ne" />
                                              <div className="note-control-holder note-control-sw" />
                                              <div className="note-control-sizing note-control-se" />
                                              <div className="note-control-selection-info" />
                                            </div>
                                          </div>
                                          <textarea
                                            className="note-codable"
                                            aria-multiline="true"
                                            defaultValue={""}
                                          />
                                          <div
                                            className="note-editable card-block"
                                            contentEditable="true"
                                            role="textbox"
                                            aria-multiline="true"
                                            spellCheck="true"
                                            autoCorrect="true"
                                            style={{ height: 300 }}
                                          >
                                            <p>Hi [[name]], </p>
                                            <p>
                                              [[member_name]] has accepted your
                                              interest. Now you can send
                                              messages to each other.{" "}
                                            </p>
                                            <p>
                                              Please contact
                                              the&nbsp;administration&nbsp;team
                                              if you have any further questions.{" "}
                                            </p>
                                            <p>Thanks,</p>
                                            <p> [[from]]</p>
                                          </div>
                                        </div>
                                        <output
                                          className="note-status-output"
                                          role="status"
                                          aria-live="polite"
                                        />
                                        <div
                                          className="note-statusbar"
                                          role="status"
                                        >
                                          <output
                                            className="note-status-output"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-resizebar"
                                            aria-label="Resize"
                                          >
                                            <div className="note-icon-bar" />
                                            <div className="note-icon-bar" />
                                            <div className="note-icon-bar" />
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal link-dialog"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Link"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Link
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group">
                                                  <label
                                                    htmlFor="note-dialog-link-txt-16775796737868"
                                                    className="note-form-label"
                                                  >
                                                    Text to display
                                                  </label>
                                                  <input
                                                    id="note-dialog-link-txt-16775796737868"
                                                    className="note-link-text form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                                <div className="form-group note-form-group">
                                                  <label
                                                    htmlFor="note-dialog-link-url-16775796737868"
                                                    className="note-form-label"
                                                  >
                                                    To what URL should this link
                                                    go?
                                                  </label>
                                                  <input
                                                    id="note-dialog-link-url-16775796737868"
                                                    className="note-link-url form-control note-form-control note-input"
                                                    type="text"
                                                    defaultValue="http://"
                                                  />
                                                </div>
                                                <div className="form-check sn-checkbox-open-in-new-window">
                                                  <label className="form-check-label">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      defaultChecked
                                                      aria-label="Open in new window"
                                                      aria-checked="true"
                                                    />{" "}
                                                    Open in new window
                                                  </label>
                                                </div>
                                                <div className="form-check sn-checkbox-use-protocol">
                                                  <label className="form-check-label">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      defaultChecked
                                                      aria-label="Use default protocol"
                                                      aria-checked="true"
                                                    />{" "}
                                                    Use default protocol
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                  defaultValue="Insert Link"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-link-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <span>
                                              <a target="_blank" />
                                              &nbsp;
                                            </span>
                                            <div className="note-btn-group btn-group note-link">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Edit"
                                                data-original-title="Edit"
                                              >
                                                <i className="note-icon-link" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unlink"
                                                data-original-title="Unlink"
                                              >
                                                <i className="note-icon-chain-broken" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Image"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Image
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group note-group-select-from-files">
                                                  <label
                                                    htmlFor="note-dialog-image-file-16775796737868"
                                                    className="note-form-label"
                                                  >
                                                    Select from files
                                                  </label>
                                                  <input
                                                    id="note-dialog-image-file-16775796737868"
                                                    className="note-image-input form-control-file note-form-control note-input"
                                                    type="file"
                                                    name="files"
                                                    accept="image/*"
                                                    multiple="multiple"
                                                  />
                                                </div>
                                                <div className="form-group note-group-image-url">
                                                  <label
                                                    htmlFor="note-dialog-image-url-16775796737868"
                                                    className="note-form-label"
                                                  >
                                                    Image URL
                                                  </label>
                                                  <input
                                                    id="note-dialog-image-url-16775796737868"
                                                    className="note-image-url form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                  defaultValue="Insert Image"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-image-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <div className="note-btn-group btn-group note-resize">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize full"
                                                data-original-title="Resize full"
                                              >
                                                <span className="note-fontsize-10">
                                                  100%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize half"
                                                data-original-title="Resize half"
                                              >
                                                <span className="note-fontsize-10">
                                                  50%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize quarter"
                                                data-original-title="Resize quarter"
                                              >
                                                <span className="note-fontsize-10">
                                                  25%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Original size"
                                                data-original-title="Original size"
                                              >
                                                <i className="note-icon-rollback" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-float">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Float Left"
                                                data-original-title="Float Left"
                                              >
                                                <i className="note-icon-float-left" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Float Right"
                                                data-original-title="Float Right"
                                              >
                                                <i className="note-icon-float-right" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Remove float"
                                                data-original-title="Remove float"
                                              >
                                                <i className="note-icon-rollback" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-remove">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Remove Image"
                                                data-original-title="Remove Image"
                                              >
                                                <i className="note-icon-trash" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-table-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <div className="note-btn-group btn-group note-add">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add row below"
                                                data-original-title="Add row below"
                                              >
                                                <i className="note-icon-row-below" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add row above"
                                                data-original-title="Add row above"
                                              >
                                                <i className="note-icon-row-above" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add column left"
                                                data-original-title="Add column left"
                                              >
                                                <i className="note-icon-col-before" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add column right"
                                                data-original-title="Add column right"
                                              >
                                                <i className="note-icon-col-after" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-delete">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete row"
                                                data-original-title="Delete row"
                                              >
                                                <i className="note-icon-row-remove" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete column"
                                                data-original-title="Delete column"
                                              >
                                                <i className="note-icon-col-remove" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete table"
                                                data-original-title="Delete table"
                                              >
                                                <i className="note-icon-trash" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Video"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Video
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group row-fluid">
                                                  <label
                                                    htmlFor="note-dialog-video-url-16775796737868"
                                                    className="note-form-label"
                                                  >
                                                    Video URL{" "}
                                                    <small className="text-muted">
                                                      (YouTube, Vimeo, Vine,
                                                      Instagram, DailyMotion or
                                                      Youku)
                                                    </small>
                                                  </label>
                                                  <input
                                                    id="note-dialog-video-url-16775796737868"
                                                    className="note-video-url form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                  defaultValue="Insert Video"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Help"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Help
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div
                                                className="modal-body"
                                                style={{
                                                  maxHeight: 300,
                                                  overflow: "scroll",
                                                }}
                                              >
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>ENTER</kbd>
                                                </label>
                                                <span>Insert Paragraph</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+Z</kbd>
                                                </label>
                                                <span>
                                                  Undoes the last command
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+Y</kbd>
                                                </label>
                                                <span>
                                                  Redoes the last command
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>TAB</kbd>
                                                </label>
                                                <span>Tab</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>SHIFT+TAB</kbd>
                                                </label>
                                                <span>Untab</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+B</kbd>
                                                </label>
                                                <span>Set a bold style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+I</kbd>
                                                </label>
                                                <span>Set a italic style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+U</kbd>
                                                </label>
                                                <span>
                                                  Set a underline style
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+S</kbd>
                                                </label>
                                                <span>
                                                  Set a strikethrough style
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+BACKSLASH</kbd>
                                                </label>
                                                <span>Clean a style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+L</kbd>
                                                </label>
                                                <span>Set left align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+E</kbd>
                                                </label>
                                                <span>Set center align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+R</kbd>
                                                </label>
                                                <span>Set right align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+J</kbd>
                                                </label>
                                                <span>Set full align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+NUM7</kbd>
                                                </label>
                                                <span>
                                                  Toggle unordered list
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+NUM8</kbd>
                                                </label>
                                                <span>Toggle ordered list</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+LEFTBRACKET</kbd>
                                                </label>
                                                <span>
                                                  Outdent on current paragraph
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+RIGHTBRACKET</kbd>
                                                </label>
                                                <span>
                                                  Indent on current paragraph
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM0</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as a paragraph(P tag)
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM1</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H1
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM2</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H2
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM3</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H3
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM4</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H4
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM5</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H5
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM6</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H6
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+ENTER</kbd>
                                                </label>
                                                <span>
                                                  Insert horizontal rule
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+K</kbd>
                                                </label>
                                                <span>Show Link Dialog</span>
                                              </div>
                                              <div className="modal-footer">
                                                <p className="text-center">
                                                  <a
                                                    href="http://summernote.org/"
                                                    target="_blank"
                                                  >
                                                    Summernote 0.8.16
                                                  </a>{" "}
                                                  ·{" "}
                                                  <a
                                                    href="https://github.com/summernote/summernote"
                                                    target="_blank"
                                                  >
                                                    Project
                                                  </a>{" "}
                                                  ·{" "}
                                                  <a
                                                    href="https://github.com/summernote/summernote/issues"
                                                    target="_blank"
                                                  >
                                                    Issues
                                                  </a>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <small className="form-text text-danger">
                                        **N.B : Do Not Change The Variables Like
                                        [[ ____ ]].**
                                      </small>
                                    </div>
                                  </div>
                                  <div className="form-group mb-3 text-right">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      Update Settings
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="Password Reset Email">
                              <div
                                className="tab-pane fade active show"
                                id="v-pills-9"
                                role="tabpanel"
                                aria-labelledby="v-pills-tab-1"
                              >
                                <form
                                  action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                  method="POST"
                                >
                                  <input
                                    type="hidden"
                                    name="_token"
                                    defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                  />{" "}
                                  <input
                                    type="hidden"
                                    name="identifier"
                                    defaultValue="password_reset_email"
                                  />
                                  <div className="form-group row">
                                    <label className="col-md-2 col-form-label">
                                      Subject
                                    </label>
                                    <div className="col-md-10">
                                      <input
                                        type="text"
                                        name="subject"
                                        defaultValue="Password Reset"
                                        className="form-control"
                                        placeholder="Subject"
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-2 col-form-label">
                                      Email Body
                                    </label>
                                    <div className="col-md-10">
                                      <textarea
                                        name="body"
                                        className="form-control aiz-text-editor"
                                        data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                        placeholder="Type.."
                                        data-min-height={300}
                                        required
                                        style={{ display: "none" }}
                                        defaultValue={
                                          "<p></p><p>Hi [[name]], </p>Your Password reset Verification Code is : [[code]]<br><p><br></p><p>\nThanks,\n</p><p> [[from]]</p><p></p>"
                                        }
                                      />
                                      <div className="note-editor note-frame card">
                                        <div className="note-dropzone">
                                          <div className="note-dropzone-message" />
                                        </div>
                                        <div
                                          className="note-toolbar card-header"
                                          role="toolbar"
                                        >
                                          <div className="note-btn-group btn-group note-font">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-bold"
                                              tabIndex={-1}
                                              title
                                              aria-label="Bold (CTRL+B)"
                                              data-original-title="Bold (CTRL+B)"
                                            >
                                              <i className="note-icon-bold" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-underline"
                                              tabIndex={-1}
                                              title
                                              aria-label="Underline (CTRL+U)"
                                              data-original-title="Underline (CTRL+U)"
                                            >
                                              <i className="note-icon-underline" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-italic"
                                              tabIndex={-1}
                                              title
                                              aria-label="Italic (CTRL+I)"
                                              data-original-title="Italic (CTRL+I)"
                                            >
                                              <i className="note-icon-italic" />
                                            </button>
                                          </div>
                                          <div className="note-btn-group btn-group note-para">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                              data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                            >
                                              <i className="note-icon-unorderedlist" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                              data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                            >
                                              <i className="note-icon-orderedlist" />
                                            </button>
                                          </div>
                                          <div className="note-btn-group btn-group note-view">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Undo (CTRL+Z)"
                                              data-original-title="Undo (CTRL+Z)"
                                            >
                                              <i className="note-icon-undo" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Redo (CTRL+Y)"
                                              data-original-title="Redo (CTRL+Y)"
                                            >
                                              <i className="note-icon-redo" />
                                            </button>
                                          </div>
                                        </div>
                                        <div className="note-editing-area">
                                          <div
                                            className="note-placeholder"
                                            style={{ display: "none" }}
                                          >
                                            Type..
                                          </div>
                                          <div className="note-handle">
                                            <div className="note-control-selection">
                                              <div className="note-control-selection-bg" />
                                              <div className="note-control-holder note-control-nw" />
                                              <div className="note-control-holder note-control-ne" />
                                              <div className="note-control-holder note-control-sw" />
                                              <div className="note-control-sizing note-control-se" />
                                              <div className="note-control-selection-info" />
                                            </div>
                                          </div>
                                          <textarea
                                            className="note-codable"
                                            aria-multiline="true"
                                            defaultValue={""}
                                          />
                                          <div
                                            className="note-editable card-block"
                                            contentEditable="true"
                                            role="textbox"
                                            aria-multiline="true"
                                            spellCheck="true"
                                            autoCorrect="true"
                                            style={{ height: 300 }}
                                          >
                                            <p />
                                            <p>Hi [[name]], </p>Your Password
                                            reset Verification Code is :
                                            [[code]]
                                            <br />
                                            <p>
                                              <br />
                                            </p>
                                            <p>Thanks,</p>
                                            <p> [[from]]</p>
                                            <p />
                                          </div>
                                        </div>
                                        <output
                                          className="note-status-output"
                                          role="status"
                                          aria-live="polite"
                                        />
                                        <div
                                          className="note-statusbar"
                                          role="status"
                                        >
                                          <output
                                            className="note-status-output"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-resizebar"
                                            aria-label="Resize"
                                          >
                                            <div className="note-icon-bar" />
                                            <div className="note-icon-bar" />
                                            <div className="note-icon-bar" />
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal link-dialog"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Link"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Link
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group">
                                                  <label
                                                    htmlFor="note-dialog-link-txt-16775796737979"
                                                    className="note-form-label"
                                                  >
                                                    Text to display
                                                  </label>
                                                  <input
                                                    id="note-dialog-link-txt-16775796737979"
                                                    className="note-link-text form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                                <div className="form-group note-form-group">
                                                  <label
                                                    htmlFor="note-dialog-link-url-16775796737979"
                                                    className="note-form-label"
                                                  >
                                                    To what URL should this link
                                                    go?
                                                  </label>
                                                  <input
                                                    id="note-dialog-link-url-16775796737979"
                                                    className="note-link-url form-control note-form-control note-input"
                                                    type="text"
                                                    defaultValue="http://"
                                                  />
                                                </div>
                                                <div className="form-check sn-checkbox-open-in-new-window">
                                                  <label className="form-check-label">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      defaultChecked
                                                      aria-label="Open in new window"
                                                      aria-checked="true"
                                                    />{" "}
                                                    Open in new window
                                                  </label>
                                                </div>
                                                <div className="form-check sn-checkbox-use-protocol">
                                                  <label className="form-check-label">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      defaultChecked
                                                      aria-label="Use default protocol"
                                                      aria-checked="true"
                                                    />{" "}
                                                    Use default protocol
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                  defaultValue="Insert Link"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-link-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <span>
                                              <a target="_blank" />
                                              &nbsp;
                                            </span>
                                            <div className="note-btn-group btn-group note-link">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Edit"
                                                data-original-title="Edit"
                                              >
                                                <i className="note-icon-link" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unlink"
                                                data-original-title="Unlink"
                                              >
                                                <i className="note-icon-chain-broken" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Image"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Image
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group note-group-select-from-files">
                                                  <label
                                                    htmlFor="note-dialog-image-file-16775796737979"
                                                    className="note-form-label"
                                                  >
                                                    Select from files
                                                  </label>
                                                  <input
                                                    id="note-dialog-image-file-16775796737979"
                                                    className="note-image-input form-control-file note-form-control note-input"
                                                    type="file"
                                                    name="files"
                                                    accept="image/*"
                                                    multiple="multiple"
                                                  />
                                                </div>
                                                <div className="form-group note-group-image-url">
                                                  <label
                                                    htmlFor="note-dialog-image-url-16775796737979"
                                                    className="note-form-label"
                                                  >
                                                    Image URL
                                                  </label>
                                                  <input
                                                    id="note-dialog-image-url-16775796737979"
                                                    className="note-image-url form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                  defaultValue="Insert Image"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-image-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <div className="note-btn-group btn-group note-resize">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize full"
                                                data-original-title="Resize full"
                                              >
                                                <span className="note-fontsize-10">
                                                  100%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize half"
                                                data-original-title="Resize half"
                                              >
                                                <span className="note-fontsize-10">
                                                  50%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize quarter"
                                                data-original-title="Resize quarter"
                                              >
                                                <span className="note-fontsize-10">
                                                  25%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Original size"
                                                data-original-title="Original size"
                                              >
                                                <i className="note-icon-rollback" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-float">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Float Left"
                                                data-original-title="Float Left"
                                              >
                                                <i className="note-icon-float-left" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Float Right"
                                                data-original-title="Float Right"
                                              >
                                                <i className="note-icon-float-right" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Remove float"
                                                data-original-title="Remove float"
                                              >
                                                <i className="note-icon-rollback" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-remove">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Remove Image"
                                                data-original-title="Remove Image"
                                              >
                                                <i className="note-icon-trash" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-table-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <div className="note-btn-group btn-group note-add">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add row below"
                                                data-original-title="Add row below"
                                              >
                                                <i className="note-icon-row-below" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add row above"
                                                data-original-title="Add row above"
                                              >
                                                <i className="note-icon-row-above" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add column left"
                                                data-original-title="Add column left"
                                              >
                                                <i className="note-icon-col-before" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add column right"
                                                data-original-title="Add column right"
                                              >
                                                <i className="note-icon-col-after" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-delete">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete row"
                                                data-original-title="Delete row"
                                              >
                                                <i className="note-icon-row-remove" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete column"
                                                data-original-title="Delete column"
                                              >
                                                <i className="note-icon-col-remove" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete table"
                                                data-original-title="Delete table"
                                              >
                                                <i className="note-icon-trash" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Video"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Video
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group row-fluid">
                                                  <label
                                                    htmlFor="note-dialog-video-url-16775796737979"
                                                    className="note-form-label"
                                                  >
                                                    Video URL{" "}
                                                    <small className="text-muted">
                                                      (YouTube, Vimeo, Vine,
                                                      Instagram, DailyMotion or
                                                      Youku)
                                                    </small>
                                                  </label>
                                                  <input
                                                    id="note-dialog-video-url-16775796737979"
                                                    className="note-video-url form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                  defaultValue="Insert Video"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Help"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Help
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div
                                                className="modal-body"
                                                style={{
                                                  maxHeight: 300,
                                                  overflow: "scroll",
                                                }}
                                              >
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>ENTER</kbd>
                                                </label>
                                                <span>Insert Paragraph</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+Z</kbd>
                                                </label>
                                                <span>
                                                  Undoes the last command
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+Y</kbd>
                                                </label>
                                                <span>
                                                  Redoes the last command
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>TAB</kbd>
                                                </label>
                                                <span>Tab</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>SHIFT+TAB</kbd>
                                                </label>
                                                <span>Untab</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+B</kbd>
                                                </label>
                                                <span>Set a bold style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+I</kbd>
                                                </label>
                                                <span>Set a italic style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+U</kbd>
                                                </label>
                                                <span>
                                                  Set a underline style
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+S</kbd>
                                                </label>
                                                <span>
                                                  Set a strikethrough style
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+BACKSLASH</kbd>
                                                </label>
                                                <span>Clean a style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+L</kbd>
                                                </label>
                                                <span>Set left align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+E</kbd>
                                                </label>
                                                <span>Set center align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+R</kbd>
                                                </label>
                                                <span>Set right align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+J</kbd>
                                                </label>
                                                <span>Set full align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+NUM7</kbd>
                                                </label>
                                                <span>
                                                  Toggle unordered list
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+NUM8</kbd>
                                                </label>
                                                <span>Toggle ordered list</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+LEFTBRACKET</kbd>
                                                </label>
                                                <span>
                                                  Outdent on current paragraph
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+RIGHTBRACKET</kbd>
                                                </label>
                                                <span>
                                                  Indent on current paragraph
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM0</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as a paragraph(P tag)
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM1</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H1
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM2</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H2
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM3</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H3
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM4</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H4
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM5</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H5
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM6</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H6
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+ENTER</kbd>
                                                </label>
                                                <span>
                                                  Insert horizontal rule
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+K</kbd>
                                                </label>
                                                <span>Show Link Dialog</span>
                                              </div>
                                              <div className="modal-footer">
                                                <p className="text-center">
                                                  <a
                                                    href="http://summernote.org/"
                                                    target="_blank"
                                                  >
                                                    Summernote 0.8.16
                                                  </a>{" "}
                                                  ·{" "}
                                                  <a
                                                    href="https://github.com/summernote/summernote"
                                                    target="_blank"
                                                  >
                                                    Project
                                                  </a>{" "}
                                                  ·{" "}
                                                  <a
                                                    href="https://github.com/summernote/summernote/issues"
                                                    target="_blank"
                                                  >
                                                    Issues
                                                  </a>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <small className="form-text text-danger">
                                        **N.B : Do Not Change The Variables Like
                                        [[ ____ ]].**
                                      </small>
                                    </div>
                                  </div>
                                  <div className="form-group mb-3 text-right">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      Update Settings
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="Profile Picture View Request Accepted">
                              <div
                                className="tab-pane fade active show"
                                id="v-pills-10"
                                role="tabpanel"
                                aria-labelledby="v-pills-tab-1"
                              >
                                <form
                                  action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                  method="POST"
                                >
                                  <input
                                    type="hidden"
                                    name="_token"
                                    defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                  />{" "}
                                  <input
                                    type="hidden"
                                    name="identifier"
                                    defaultValue="profile_picture_view_request_email"
                                  />
                                  <div className="form-group row">
                                    <div className="col-md-2">
                                      <label className="col-from-label">
                                        Activation
                                      </label>
                                    </div>
                                    <div className="col-md-10">
                                      <label className="aiz-switch aiz-switch-success mb-0">
                                        <input
                                          defaultValue={1}
                                          name="status"
                                          type="checkbox"
                                          defaultChecked
                                        />
                                        <span className="slider round" />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-2 col-form-label">
                                      Subject
                                    </label>
                                    <div className="col-md-10">
                                      <input
                                        type="text"
                                        name="subject"
                                        defaultValue="Profile Picture View Request"
                                        className="form-control"
                                        placeholder="Subject"
                                        required
                                        fdprocessedid="af7i38e"
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-2 col-form-label">
                                      Email Body
                                    </label>
                                    <div className="col-md-10">
                                      <textarea
                                        name="body"
                                        className="form-control aiz-text-editor"
                                        data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                        placeholder="Type.."
                                        data-min-height={300}
                                        required
                                        style={{ display: "none" }}
                                        defaultValue={
                                          "<p>Hi [[name]], </p><p>\n[[member_name]] sent you Profile Picture View Request.\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                        }
                                      />
                                      <div className="note-editor note-frame card">
                                        <div className="note-dropzone">
                                          <div className="note-dropzone-message" />
                                        </div>
                                        <div
                                          className="note-toolbar card-header"
                                          role="toolbar"
                                        >
                                          <div className="note-btn-group btn-group note-font">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-bold"
                                              tabIndex={-1}
                                              title
                                              aria-label="Bold (CTRL+B)"
                                              data-original-title="Bold (CTRL+B)"
                                            >
                                              <i className="note-icon-bold" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-underline"
                                              tabIndex={-1}
                                              title
                                              aria-label="Underline (CTRL+U)"
                                              data-original-title="Underline (CTRL+U)"
                                            >
                                              <i className="note-icon-underline" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-italic"
                                              tabIndex={-1}
                                              title
                                              aria-label="Italic (CTRL+I)"
                                              data-original-title="Italic (CTRL+I)"
                                            >
                                              <i className="note-icon-italic" />
                                            </button>
                                          </div>
                                          <div className="note-btn-group btn-group note-para">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                              data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                            >
                                              <i className="note-icon-unorderedlist" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                              data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                            >
                                              <i className="note-icon-orderedlist" />
                                            </button>
                                          </div>
                                          <div className="note-btn-group btn-group note-view">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Undo (CTRL+Z)"
                                              data-original-title="Undo (CTRL+Z)"
                                            >
                                              <i className="note-icon-undo" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Redo (CTRL+Y)"
                                              data-original-title="Redo (CTRL+Y)"
                                            >
                                              <i className="note-icon-redo" />
                                            </button>
                                          </div>
                                        </div>
                                        <div className="note-editing-area">
                                          <div
                                            className="note-placeholder"
                                            style={{ display: "none" }}
                                          >
                                            Type..
                                          </div>
                                          <div className="note-handle">
                                            <div className="note-control-selection">
                                              <div className="note-control-selection-bg" />
                                              <div className="note-control-holder note-control-nw" />
                                              <div className="note-control-holder note-control-ne" />
                                              <div className="note-control-holder note-control-sw" />
                                              <div className="note-control-sizing note-control-se" />
                                              <div className="note-control-selection-info" />
                                            </div>
                                          </div>
                                          <textarea
                                            className="note-codable"
                                            aria-multiline="true"
                                            defaultValue={""}
                                          />
                                          <div
                                            className="note-editable card-block"
                                            contentEditable="true"
                                            role="textbox"
                                            aria-multiline="true"
                                            spellCheck="true"
                                            autoCorrect="true"
                                            style={{ height: 300 }}
                                          >
                                            <p>Hi [[name]], </p>
                                            <p>
                                              [[member_name]] sent you Profile
                                              Picture View Request.
                                            </p>
                                            <p>
                                              Please contact
                                              the&nbsp;administration&nbsp;team
                                              if you have any further questions.{" "}
                                            </p>
                                            <p>Thanks,</p>
                                            <p> [[from]]</p>
                                          </div>
                                        </div>
                                        <output
                                          className="note-status-output"
                                          role="status"
                                          aria-live="polite"
                                        />
                                        <div
                                          className="note-statusbar"
                                          role="status"
                                        >
                                          <output
                                            className="note-status-output"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-resizebar"
                                            aria-label="Resize"
                                          >
                                            <div className="note-icon-bar" />
                                            <div className="note-icon-bar" />
                                            <div className="note-icon-bar" />
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal link-dialog"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Link"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Link
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group">
                                                  <label
                                                    htmlFor="note-dialog-link-txt-167757967380810"
                                                    className="note-form-label"
                                                  >
                                                    Text to display
                                                  </label>
                                                  <input
                                                    id="note-dialog-link-txt-167757967380810"
                                                    className="note-link-text form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                                <div className="form-group note-form-group">
                                                  <label
                                                    htmlFor="note-dialog-link-url-167757967380810"
                                                    className="note-form-label"
                                                  >
                                                    To what URL should this link
                                                    go?
                                                  </label>
                                                  <input
                                                    id="note-dialog-link-url-167757967380810"
                                                    className="note-link-url form-control note-form-control note-input"
                                                    type="text"
                                                    defaultValue="http://"
                                                  />
                                                </div>
                                                <div className="form-check sn-checkbox-open-in-new-window">
                                                  <label className="form-check-label">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      defaultChecked
                                                      aria-label="Open in new window"
                                                      aria-checked="true"
                                                    />{" "}
                                                    Open in new window
                                                  </label>
                                                </div>
                                                <div className="form-check sn-checkbox-use-protocol">
                                                  <label className="form-check-label">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      defaultChecked
                                                      aria-label="Use default protocol"
                                                      aria-checked="true"
                                                    />{" "}
                                                    Use default protocol
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                  defaultValue="Insert Link"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-link-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <span>
                                              <a target="_blank" />
                                              &nbsp;
                                            </span>
                                            <div className="note-btn-group btn-group note-link">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Edit"
                                                data-original-title="Edit"
                                              >
                                                <i className="note-icon-link" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unlink"
                                                data-original-title="Unlink"
                                              >
                                                <i className="note-icon-chain-broken" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Image"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Image
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group note-group-select-from-files">
                                                  <label
                                                    htmlFor="note-dialog-image-file-167757967380810"
                                                    className="note-form-label"
                                                  >
                                                    Select from files
                                                  </label>
                                                  <input
                                                    id="note-dialog-image-file-167757967380810"
                                                    className="note-image-input form-control-file note-form-control note-input"
                                                    type="file"
                                                    name="files"
                                                    accept="image/*"
                                                    multiple="multiple"
                                                  />
                                                </div>
                                                <div className="form-group note-group-image-url">
                                                  <label
                                                    htmlFor="note-dialog-image-url-167757967380810"
                                                    className="note-form-label"
                                                  >
                                                    Image URL
                                                  </label>
                                                  <input
                                                    id="note-dialog-image-url-167757967380810"
                                                    className="note-image-url form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                  defaultValue="Insert Image"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-image-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <div className="note-btn-group btn-group note-resize">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize full"
                                                data-original-title="Resize full"
                                              >
                                                <span className="note-fontsize-10">
                                                  100%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize half"
                                                data-original-title="Resize half"
                                              >
                                                <span className="note-fontsize-10">
                                                  50%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize quarter"
                                                data-original-title="Resize quarter"
                                              >
                                                <span className="note-fontsize-10">
                                                  25%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Original size"
                                                data-original-title="Original size"
                                              >
                                                <i className="note-icon-rollback" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-float">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Float Left"
                                                data-original-title="Float Left"
                                              >
                                                <i className="note-icon-float-left" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Float Right"
                                                data-original-title="Float Right"
                                              >
                                                <i className="note-icon-float-right" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Remove float"
                                                data-original-title="Remove float"
                                              >
                                                <i className="note-icon-rollback" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-remove">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Remove Image"
                                                data-original-title="Remove Image"
                                              >
                                                <i className="note-icon-trash" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-table-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <div className="note-btn-group btn-group note-add">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add row below"
                                                data-original-title="Add row below"
                                              >
                                                <i className="note-icon-row-below" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add row above"
                                                data-original-title="Add row above"
                                              >
                                                <i className="note-icon-row-above" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add column left"
                                                data-original-title="Add column left"
                                              >
                                                <i className="note-icon-col-before" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add column right"
                                                data-original-title="Add column right"
                                              >
                                                <i className="note-icon-col-after" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-delete">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete row"
                                                data-original-title="Delete row"
                                              >
                                                <i className="note-icon-row-remove" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete column"
                                                data-original-title="Delete column"
                                              >
                                                <i className="note-icon-col-remove" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete table"
                                                data-original-title="Delete table"
                                              >
                                                <i className="note-icon-trash" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Video"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Video
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group row-fluid">
                                                  <label
                                                    htmlFor="note-dialog-video-url-167757967380810"
                                                    className="note-form-label"
                                                  >
                                                    Video URL{" "}
                                                    <small className="text-muted">
                                                      (YouTube, Vimeo, Vine,
                                                      Instagram, DailyMotion or
                                                      Youku)
                                                    </small>
                                                  </label>
                                                  <input
                                                    id="note-dialog-video-url-167757967380810"
                                                    className="note-video-url form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                  defaultValue="Insert Video"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Help"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Help
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div
                                                className="modal-body"
                                                style={{
                                                  maxHeight: 300,
                                                  overflow: "scroll",
                                                }}
                                              >
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>ENTER</kbd>
                                                </label>
                                                <span>Insert Paragraph</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+Z</kbd>
                                                </label>
                                                <span>
                                                  Undoes the last command
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+Y</kbd>
                                                </label>
                                                <span>
                                                  Redoes the last command
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>TAB</kbd>
                                                </label>
                                                <span>Tab</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>SHIFT+TAB</kbd>
                                                </label>
                                                <span>Untab</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+B</kbd>
                                                </label>
                                                <span>Set a bold style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+I</kbd>
                                                </label>
                                                <span>Set a italic style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+U</kbd>
                                                </label>
                                                <span>
                                                  Set a underline style
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+S</kbd>
                                                </label>
                                                <span>
                                                  Set a strikethrough style
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+BACKSLASH</kbd>
                                                </label>
                                                <span>Clean a style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+L</kbd>
                                                </label>
                                                <span>Set left align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+E</kbd>
                                                </label>
                                                <span>Set center align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+R</kbd>
                                                </label>
                                                <span>Set right align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+J</kbd>
                                                </label>
                                                <span>Set full align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+NUM7</kbd>
                                                </label>
                                                <span>
                                                  Toggle unordered list
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+NUM8</kbd>
                                                </label>
                                                <span>Toggle ordered list</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+LEFTBRACKET</kbd>
                                                </label>
                                                <span>
                                                  Outdent on current paragraph
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+RIGHTBRACKET</kbd>
                                                </label>
                                                <span>
                                                  Indent on current paragraph
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM0</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as a paragraph(P tag)
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM1</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H1
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM2</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H2
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM3</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H3
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM4</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H4
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM5</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H5
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM6</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H6
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+ENTER</kbd>
                                                </label>
                                                <span>
                                                  Insert horizontal rule
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+K</kbd>
                                                </label>
                                                <span>Show Link Dialog</span>
                                              </div>
                                              <div className="modal-footer">
                                                <p className="text-center">
                                                  <a
                                                    href="http://summernote.org/"
                                                    target="_blank"
                                                  >
                                                    Summernote 0.8.16
                                                  </a>{" "}
                                                  ·{" "}
                                                  <a
                                                    href="https://github.com/summernote/summernote"
                                                    target="_blank"
                                                  >
                                                    Project
                                                  </a>{" "}
                                                  ·{" "}
                                                  <a
                                                    href="https://github.com/summernote/summernote/issues"
                                                    target="_blank"
                                                  >
                                                    Issues
                                                  </a>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <small className="form-text text-danger">
                                        **N.B : Do Not Change The Variables Like
                                        [[ ____ ]].**
                                      </small>
                                    </div>
                                  </div>
                                  <div className="form-group mb-3 text-right">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                      fdprocessedid="tznjfu"
                                    >
                                      Update Settings
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="Profile Picture View Request Accepted Email">
                              <div
                                className="tab-pane fade active show"
                                id="v-pills-10"
                                role="tabpanel"
                                aria-labelledby="v-pills-tab-1"
                              >
                                <form
                                  action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                  method="POST"
                                >
                                  <input
                                    type="hidden"
                                    name="_token"
                                    defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                  />{" "}
                                  <input
                                    type="hidden"
                                    name="identifier"
                                    defaultValue="profile_picture_view_request_email"
                                  />
                                  <div className="form-group row">
                                    <div className="col-md-2">
                                      <label className="col-from-label">
                                        Activation
                                      </label>
                                    </div>
                                    <div className="col-md-10">
                                      <label className="aiz-switch aiz-switch-success mb-0">
                                        <input
                                          defaultValue={1}
                                          name="status"
                                          type="checkbox"
                                          defaultChecked
                                        />
                                        <span className="slider round" />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-2 col-form-label">
                                      Subject
                                    </label>
                                    <div className="col-md-10">
                                      <input
                                        type="text"
                                        name="subject"
                                        defaultValue="Profile Picture View Request"
                                        className="form-control"
                                        placeholder="Subject"
                                        required
                                        fdprocessedid="af7i38e"
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-2 col-form-label">
                                      Email Body
                                    </label>
                                    <div className="col-md-10">
                                      <textarea
                                        name="body"
                                        className="form-control aiz-text-editor"
                                        data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                        placeholder="Type.."
                                        data-min-height={300}
                                        required
                                        style={{ display: "none" }}
                                        defaultValue={
                                          "<p>Hi [[name]], </p><p>\n[[member_name]] sent you Profile Picture View Request.\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                        }
                                      />
                                      <div className="note-editor note-frame card">
                                        <div className="note-dropzone">
                                          <div className="note-dropzone-message" />
                                        </div>
                                        <div
                                          className="note-toolbar card-header"
                                          role="toolbar"
                                        >
                                          <div className="note-btn-group btn-group note-font">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-bold"
                                              tabIndex={-1}
                                              title
                                              aria-label="Bold (CTRL+B)"
                                              data-original-title="Bold (CTRL+B)"
                                            >
                                              <i className="note-icon-bold" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-underline"
                                              tabIndex={-1}
                                              title
                                              aria-label="Underline (CTRL+U)"
                                              data-original-title="Underline (CTRL+U)"
                                            >
                                              <i className="note-icon-underline" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-italic"
                                              tabIndex={-1}
                                              title
                                              aria-label="Italic (CTRL+I)"
                                              data-original-title="Italic (CTRL+I)"
                                            >
                                              <i className="note-icon-italic" />
                                            </button>
                                          </div>
                                          <div className="note-btn-group btn-group note-para">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                              data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                            >
                                              <i className="note-icon-unorderedlist" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                              data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                            >
                                              <i className="note-icon-orderedlist" />
                                            </button>
                                          </div>
                                          <div className="note-btn-group btn-group note-view">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Undo (CTRL+Z)"
                                              data-original-title="Undo (CTRL+Z)"
                                            >
                                              <i className="note-icon-undo" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Redo (CTRL+Y)"
                                              data-original-title="Redo (CTRL+Y)"
                                            >
                                              <i className="note-icon-redo" />
                                            </button>
                                          </div>
                                        </div>
                                        <div className="note-editing-area">
                                          <div
                                            className="note-placeholder"
                                            style={{ display: "none" }}
                                          >
                                            Type..
                                          </div>
                                          <div className="note-handle">
                                            <div className="note-control-selection">
                                              <div className="note-control-selection-bg" />
                                              <div className="note-control-holder note-control-nw" />
                                              <div className="note-control-holder note-control-ne" />
                                              <div className="note-control-holder note-control-sw" />
                                              <div className="note-control-sizing note-control-se" />
                                              <div className="note-control-selection-info" />
                                            </div>
                                          </div>
                                          <textarea
                                            className="note-codable"
                                            aria-multiline="true"
                                            defaultValue={""}
                                          />
                                          <div
                                            className="note-editable card-block"
                                            contentEditable="true"
                                            role="textbox"
                                            aria-multiline="true"
                                            spellCheck="true"
                                            autoCorrect="true"
                                            style={{ height: 300 }}
                                          >
                                            <p>Hi [[name]], </p>
                                            <p>
                                              [[member_name]] sent you Profile
                                              Picture View Request.
                                            </p>
                                            <p>
                                              Please contact
                                              the&nbsp;administration&nbsp;team
                                              if you have any further questions.{" "}
                                            </p>
                                            <p>Thanks,</p>
                                            <p> [[from]]</p>
                                          </div>
                                        </div>
                                        <output
                                          className="note-status-output"
                                          role="status"
                                          aria-live="polite"
                                        />
                                        <div
                                          className="note-statusbar"
                                          role="status"
                                        >
                                          <output
                                            className="note-status-output"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-resizebar"
                                            aria-label="Resize"
                                          >
                                            <div className="note-icon-bar" />
                                            <div className="note-icon-bar" />
                                            <div className="note-icon-bar" />
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal link-dialog"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Link"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Link
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group">
                                                  <label
                                                    htmlFor="note-dialog-link-txt-167757967380810"
                                                    className="note-form-label"
                                                  >
                                                    Text to display
                                                  </label>
                                                  <input
                                                    id="note-dialog-link-txt-167757967380810"
                                                    className="note-link-text form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                                <div className="form-group note-form-group">
                                                  <label
                                                    htmlFor="note-dialog-link-url-167757967380810"
                                                    className="note-form-label"
                                                  >
                                                    To what URL should this link
                                                    go?
                                                  </label>
                                                  <input
                                                    id="note-dialog-link-url-167757967380810"
                                                    className="note-link-url form-control note-form-control note-input"
                                                    type="text"
                                                    defaultValue="http://"
                                                  />
                                                </div>
                                                <div className="form-check sn-checkbox-open-in-new-window">
                                                  <label className="form-check-label">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      defaultChecked
                                                      aria-label="Open in new window"
                                                      aria-checked="true"
                                                    />{" "}
                                                    Open in new window
                                                  </label>
                                                </div>
                                                <div className="form-check sn-checkbox-use-protocol">
                                                  <label className="form-check-label">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      defaultChecked
                                                      aria-label="Use default protocol"
                                                      aria-checked="true"
                                                    />{" "}
                                                    Use default protocol
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                  defaultValue="Insert Link"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-link-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <span>
                                              <a target="_blank" />
                                              &nbsp;
                                            </span>
                                            <div className="note-btn-group btn-group note-link">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Edit"
                                                data-original-title="Edit"
                                              >
                                                <i className="note-icon-link" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unlink"
                                                data-original-title="Unlink"
                                              >
                                                <i className="note-icon-chain-broken" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Image"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Image
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group note-group-select-from-files">
                                                  <label
                                                    htmlFor="note-dialog-image-file-167757967380810"
                                                    className="note-form-label"
                                                  >
                                                    Select from files
                                                  </label>
                                                  <input
                                                    id="note-dialog-image-file-167757967380810"
                                                    className="note-image-input form-control-file note-form-control note-input"
                                                    type="file"
                                                    name="files"
                                                    accept="image/*"
                                                    multiple="multiple"
                                                  />
                                                </div>
                                                <div className="form-group note-group-image-url">
                                                  <label
                                                    htmlFor="note-dialog-image-url-167757967380810"
                                                    className="note-form-label"
                                                  >
                                                    Image URL
                                                  </label>
                                                  <input
                                                    id="note-dialog-image-url-167757967380810"
                                                    className="note-image-url form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                  defaultValue="Insert Image"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-image-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <div className="note-btn-group btn-group note-resize">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize full"
                                                data-original-title="Resize full"
                                              >
                                                <span className="note-fontsize-10">
                                                  100%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize half"
                                                data-original-title="Resize half"
                                              >
                                                <span className="note-fontsize-10">
                                                  50%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize quarter"
                                                data-original-title="Resize quarter"
                                              >
                                                <span className="note-fontsize-10">
                                                  25%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Original size"
                                                data-original-title="Original size"
                                              >
                                                <i className="note-icon-rollback" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-float">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Float Left"
                                                data-original-title="Float Left"
                                              >
                                                <i className="note-icon-float-left" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Float Right"
                                                data-original-title="Float Right"
                                              >
                                                <i className="note-icon-float-right" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Remove float"
                                                data-original-title="Remove float"
                                              >
                                                <i className="note-icon-rollback" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-remove">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Remove Image"
                                                data-original-title="Remove Image"
                                              >
                                                <i className="note-icon-trash" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-table-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <div className="note-btn-group btn-group note-add">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add row below"
                                                data-original-title="Add row below"
                                              >
                                                <i className="note-icon-row-below" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add row above"
                                                data-original-title="Add row above"
                                              >
                                                <i className="note-icon-row-above" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add column left"
                                                data-original-title="Add column left"
                                              >
                                                <i className="note-icon-col-before" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add column right"
                                                data-original-title="Add column right"
                                              >
                                                <i className="note-icon-col-after" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-delete">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete row"
                                                data-original-title="Delete row"
                                              >
                                                <i className="note-icon-row-remove" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete column"
                                                data-original-title="Delete column"
                                              >
                                                <i className="note-icon-col-remove" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete table"
                                                data-original-title="Delete table"
                                              >
                                                <i className="note-icon-trash" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Video"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Video
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group row-fluid">
                                                  <label
                                                    htmlFor="note-dialog-video-url-167757967380810"
                                                    className="note-form-label"
                                                  >
                                                    Video URL{" "}
                                                    <small className="text-muted">
                                                      (YouTube, Vimeo, Vine,
                                                      Instagram, DailyMotion or
                                                      Youku)
                                                    </small>
                                                  </label>
                                                  <input
                                                    id="note-dialog-video-url-167757967380810"
                                                    className="note-video-url form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                  defaultValue="Insert Video"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Help"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Help
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div
                                                className="modal-body"
                                                style={{
                                                  maxHeight: 300,
                                                  overflow: "scroll",
                                                }}
                                              >
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>ENTER</kbd>
                                                </label>
                                                <span>Insert Paragraph</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+Z</kbd>
                                                </label>
                                                <span>
                                                  Undoes the last command
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+Y</kbd>
                                                </label>
                                                <span>
                                                  Redoes the last command
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>TAB</kbd>
                                                </label>
                                                <span>Tab</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>SHIFT+TAB</kbd>
                                                </label>
                                                <span>Untab</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+B</kbd>
                                                </label>
                                                <span>Set a bold style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+I</kbd>
                                                </label>
                                                <span>Set a italic style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+U</kbd>
                                                </label>
                                                <span>
                                                  Set a underline style
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+S</kbd>
                                                </label>
                                                <span>
                                                  Set a strikethrough style
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+BACKSLASH</kbd>
                                                </label>
                                                <span>Clean a style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+L</kbd>
                                                </label>
                                                <span>Set left align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+E</kbd>
                                                </label>
                                                <span>Set center align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+R</kbd>
                                                </label>
                                                <span>Set right align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+J</kbd>
                                                </label>
                                                <span>Set full align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+NUM7</kbd>
                                                </label>
                                                <span>
                                                  Toggle unordered list
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+NUM8</kbd>
                                                </label>
                                                <span>Toggle ordered list</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+LEFTBRACKET</kbd>
                                                </label>
                                                <span>
                                                  Outdent on current paragraph
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+RIGHTBRACKET</kbd>
                                                </label>
                                                <span>
                                                  Indent on current paragraph
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM0</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as a paragraph(P tag)
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM1</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H1
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM2</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H2
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM3</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H3
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM4</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H4
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM5</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H5
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM6</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H6
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+ENTER</kbd>
                                                </label>
                                                <span>
                                                  Insert horizontal rule
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+K</kbd>
                                                </label>
                                                <span>Show Link Dialog</span>
                                              </div>
                                              <div className="modal-footer">
                                                <p className="text-center">
                                                  <a
                                                    href="http://summernote.org/"
                                                    target="_blank"
                                                  >
                                                    Summernote 0.8.16
                                                  </a>{" "}
                                                  ·{" "}
                                                  <a
                                                    href="https://github.com/summernote/summernote"
                                                    target="_blank"
                                                  >
                                                    Project
                                                  </a>{" "}
                                                  ·{" "}
                                                  <a
                                                    href="https://github.com/summernote/summernote/issues"
                                                    target="_blank"
                                                  >
                                                    Issues
                                                  </a>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <small className="form-text text-danger">
                                        **N.B : Do Not Change The Variables Like
                                        [[ ____ ]].**
                                      </small>
                                    </div>
                                  </div>
                                  <div className="form-group mb-3 text-right">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                      fdprocessedid="tznjfu"
                                    >
                                      Update Settings
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="Gallery Image View Request Email">
                              <div
                                className="tab-pane fade active show"
                                id="v-pills-12"
                                role="tabpanel"
                                aria-labelledby="v-pills-tab-1"
                              >
                                <form
                                  action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                  method="POST"
                                >
                                  <input
                                    type="hidden"
                                    name="_token"
                                    defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                  />{" "}
                                  <input
                                    type="hidden"
                                    name="identifier"
                                    defaultValue="gallery_image_view_request_email"
                                  />
                                  <div className="form-group row">
                                    <div className="col-md-2">
                                      <label className="col-from-label">
                                        Activation
                                      </label>
                                    </div>
                                    <div className="col-md-10">
                                      <label className="aiz-switch aiz-switch-success mb-0">
                                        <input
                                          defaultValue={1}
                                          name="status"
                                          type="checkbox"
                                          defaultChecked
                                        />
                                        <span className="slider round" />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-2 col-form-label">
                                      Subject
                                    </label>
                                    <div className="col-md-10">
                                      <input
                                        type="text"
                                        name="subject"
                                        defaultValue="Gallery Image View Request"
                                        className="form-control"
                                        placeholder="Subject"
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-2 col-form-label">
                                      Email Body
                                    </label>
                                    <div className="col-md-10">
                                      <textarea
                                        name="body"
                                        className="form-control aiz-text-editor"
                                        data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                        placeholder="Type.."
                                        data-min-height={300}
                                        required
                                        style={{ display: "none" }}
                                        defaultValue={
                                          "<p>Hi [[name]], </p><p>\n[[member_name]] sent you Gallery Image View Request.\n</p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                        }
                                      />
                                      <div className="note-editor note-frame card">
                                        <div className="note-dropzone">
                                          <div className="note-dropzone-message" />
                                        </div>
                                        <div
                                          className="note-toolbar card-header"
                                          role="toolbar"
                                        >
                                          <div className="note-btn-group btn-group note-font">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-bold"
                                              tabIndex={-1}
                                              title
                                              aria-label="Bold (CTRL+B)"
                                              data-original-title="Bold (CTRL+B)"
                                            >
                                              <i className="note-icon-bold" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-underline"
                                              tabIndex={-1}
                                              title
                                              aria-label="Underline (CTRL+U)"
                                              data-original-title="Underline (CTRL+U)"
                                            >
                                              <i className="note-icon-underline" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-italic"
                                              tabIndex={-1}
                                              title
                                              aria-label="Italic (CTRL+I)"
                                              data-original-title="Italic (CTRL+I)"
                                            >
                                              <i className="note-icon-italic" />
                                            </button>
                                          </div>
                                          <div className="note-btn-group btn-group note-para">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                              data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                            >
                                              <i className="note-icon-unorderedlist" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                              data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                            >
                                              <i className="note-icon-orderedlist" />
                                            </button>
                                          </div>
                                          <div className="note-btn-group btn-group note-view">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Undo (CTRL+Z)"
                                              data-original-title="Undo (CTRL+Z)"
                                            >
                                              <i className="note-icon-undo" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Redo (CTRL+Y)"
                                              data-original-title="Redo (CTRL+Y)"
                                            >
                                              <i className="note-icon-redo" />
                                            </button>
                                          </div>
                                        </div>
                                        <div className="note-editing-area">
                                          <div
                                            className="note-placeholder"
                                            style={{ display: "none" }}
                                          >
                                            Type..
                                          </div>
                                          <div className="note-handle">
                                            <div className="note-control-selection">
                                              <div className="note-control-selection-bg" />
                                              <div className="note-control-holder note-control-nw" />
                                              <div className="note-control-holder note-control-ne" />
                                              <div className="note-control-holder note-control-sw" />
                                              <div className="note-control-sizing note-control-se" />
                                              <div className="note-control-selection-info" />
                                            </div>
                                          </div>
                                          <textarea
                                            className="note-codable"
                                            aria-multiline="true"
                                            defaultValue={""}
                                          />
                                          <div
                                            className="note-editable card-block"
                                            contentEditable="true"
                                            role="textbox"
                                            aria-multiline="true"
                                            spellCheck="true"
                                            autoCorrect="true"
                                            style={{ height: 300 }}
                                          >
                                            <p>Hi [[name]], </p>
                                            <p>
                                              [[member_name]] sent you Gallery
                                              Image View Request.
                                            </p>
                                            <p>
                                              Please contact
                                              the&nbsp;administration&nbsp;team
                                              if you have any further questions.{" "}
                                            </p>
                                            <p>Thanks,</p>
                                            <p> [[from]]</p>
                                          </div>
                                        </div>
                                        <output
                                          className="note-status-output"
                                          role="status"
                                          aria-live="polite"
                                        />
                                        <div
                                          className="note-statusbar"
                                          role="status"
                                        >
                                          <output
                                            className="note-status-output"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-resizebar"
                                            aria-label="Resize"
                                          >
                                            <div className="note-icon-bar" />
                                            <div className="note-icon-bar" />
                                            <div className="note-icon-bar" />
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal link-dialog"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Link"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Link
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group">
                                                  <label
                                                    htmlFor="note-dialog-link-txt-167757967382812"
                                                    className="note-form-label"
                                                  >
                                                    Text to display
                                                  </label>
                                                  <input
                                                    id="note-dialog-link-txt-167757967382812"
                                                    className="note-link-text form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                                <div className="form-group note-form-group">
                                                  <label
                                                    htmlFor="note-dialog-link-url-167757967382812"
                                                    className="note-form-label"
                                                  >
                                                    To what URL should this link
                                                    go?
                                                  </label>
                                                  <input
                                                    id="note-dialog-link-url-167757967382812"
                                                    className="note-link-url form-control note-form-control note-input"
                                                    type="text"
                                                    defaultValue="http://"
                                                  />
                                                </div>
                                                <div className="form-check sn-checkbox-open-in-new-window">
                                                  <label className="form-check-label">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      defaultChecked
                                                      aria-label="Open in new window"
                                                      aria-checked="true"
                                                    />{" "}
                                                    Open in new window
                                                  </label>
                                                </div>
                                                <div className="form-check sn-checkbox-use-protocol">
                                                  <label className="form-check-label">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      defaultChecked
                                                      aria-label="Use default protocol"
                                                      aria-checked="true"
                                                    />{" "}
                                                    Use default protocol
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                  defaultValue="Insert Link"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-link-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <span>
                                              <a target="_blank" />
                                              &nbsp;
                                            </span>
                                            <div className="note-btn-group btn-group note-link">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Edit"
                                                data-original-title="Edit"
                                              >
                                                <i className="note-icon-link" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unlink"
                                                data-original-title="Unlink"
                                              >
                                                <i className="note-icon-chain-broken" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Image"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Image
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group note-group-select-from-files">
                                                  <label
                                                    htmlFor="note-dialog-image-file-167757967382812"
                                                    className="note-form-label"
                                                  >
                                                    Select from files
                                                  </label>
                                                  <input
                                                    id="note-dialog-image-file-167757967382812"
                                                    className="note-image-input form-control-file note-form-control note-input"
                                                    type="file"
                                                    name="files"
                                                    accept="image/*"
                                                    multiple="multiple"
                                                  />
                                                </div>
                                                <div className="form-group note-group-image-url">
                                                  <label
                                                    htmlFor="note-dialog-image-url-167757967382812"
                                                    className="note-form-label"
                                                  >
                                                    Image URL
                                                  </label>
                                                  <input
                                                    id="note-dialog-image-url-167757967382812"
                                                    className="note-image-url form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                  defaultValue="Insert Image"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-image-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <div className="note-btn-group btn-group note-resize">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize full"
                                                data-original-title="Resize full"
                                              >
                                                <span className="note-fontsize-10">
                                                  100%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize half"
                                                data-original-title="Resize half"
                                              >
                                                <span className="note-fontsize-10">
                                                  50%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize quarter"
                                                data-original-title="Resize quarter"
                                              >
                                                <span className="note-fontsize-10">
                                                  25%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Original size"
                                                data-original-title="Original size"
                                              >
                                                <i className="note-icon-rollback" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-float">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Float Left"
                                                data-original-title="Float Left"
                                              >
                                                <i className="note-icon-float-left" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Float Right"
                                                data-original-title="Float Right"
                                              >
                                                <i className="note-icon-float-right" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Remove float"
                                                data-original-title="Remove float"
                                              >
                                                <i className="note-icon-rollback" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-remove">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Remove Image"
                                                data-original-title="Remove Image"
                                              >
                                                <i className="note-icon-trash" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-table-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <div className="note-btn-group btn-group note-add">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add row below"
                                                data-original-title="Add row below"
                                              >
                                                <i className="note-icon-row-below" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add row above"
                                                data-original-title="Add row above"
                                              >
                                                <i className="note-icon-row-above" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add column left"
                                                data-original-title="Add column left"
                                              >
                                                <i className="note-icon-col-before" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add column right"
                                                data-original-title="Add column right"
                                              >
                                                <i className="note-icon-col-after" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-delete">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete row"
                                                data-original-title="Delete row"
                                              >
                                                <i className="note-icon-row-remove" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete column"
                                                data-original-title="Delete column"
                                              >
                                                <i className="note-icon-col-remove" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete table"
                                                data-original-title="Delete table"
                                              >
                                                <i className="note-icon-trash" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Video"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Video
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group row-fluid">
                                                  <label
                                                    htmlFor="note-dialog-video-url-167757967382812"
                                                    className="note-form-label"
                                                  >
                                                    Video URL{" "}
                                                    <small className="text-muted">
                                                      (YouTube, Vimeo, Vine,
                                                      Instagram, DailyMotion or
                                                      Youku)
                                                    </small>
                                                  </label>
                                                  <input
                                                    id="note-dialog-video-url-167757967382812"
                                                    className="note-video-url form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                  defaultValue="Insert Video"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Help"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Help
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div
                                                className="modal-body"
                                                style={{
                                                  maxHeight: 300,
                                                  overflow: "scroll",
                                                }}
                                              >
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>ENTER</kbd>
                                                </label>
                                                <span>Insert Paragraph</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+Z</kbd>
                                                </label>
                                                <span>
                                                  Undoes the last command
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+Y</kbd>
                                                </label>
                                                <span>
                                                  Redoes the last command
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>TAB</kbd>
                                                </label>
                                                <span>Tab</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>SHIFT+TAB</kbd>
                                                </label>
                                                <span>Untab</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+B</kbd>
                                                </label>
                                                <span>Set a bold style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+I</kbd>
                                                </label>
                                                <span>Set a italic style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+U</kbd>
                                                </label>
                                                <span>
                                                  Set a underline style
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+S</kbd>
                                                </label>
                                                <span>
                                                  Set a strikethrough style
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+BACKSLASH</kbd>
                                                </label>
                                                <span>Clean a style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+L</kbd>
                                                </label>
                                                <span>Set left align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+E</kbd>
                                                </label>
                                                <span>Set center align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+R</kbd>
                                                </label>
                                                <span>Set right align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+J</kbd>
                                                </label>
                                                <span>Set full align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+NUM7</kbd>
                                                </label>
                                                <span>
                                                  Toggle unordered list
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+NUM8</kbd>
                                                </label>
                                                <span>Toggle ordered list</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+LEFTBRACKET</kbd>
                                                </label>
                                                <span>
                                                  Outdent on current paragraph
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+RIGHTBRACKET</kbd>
                                                </label>
                                                <span>
                                                  Indent on current paragraph
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM0</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as a paragraph(P tag)
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM1</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H1
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM2</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H2
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM3</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H3
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM4</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H4
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM5</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H5
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM6</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H6
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+ENTER</kbd>
                                                </label>
                                                <span>
                                                  Insert horizontal rule
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+K</kbd>
                                                </label>
                                                <span>Show Link Dialog</span>
                                              </div>
                                              <div className="modal-footer">
                                                <p className="text-center">
                                                  <a
                                                    href="http://summernote.org/"
                                                    target="_blank"
                                                  >
                                                    Summernote 0.8.16
                                                  </a>{" "}
                                                  ·{" "}
                                                  <a
                                                    href="https://github.com/summernote/summernote"
                                                    target="_blank"
                                                  >
                                                    Project
                                                  </a>{" "}
                                                  ·{" "}
                                                  <a
                                                    href="https://github.com/summernote/summernote/issues"
                                                    target="_blank"
                                                  >
                                                    Issues
                                                  </a>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <small className="form-text text-danger">
                                        **N.B : Do Not Change The Variables Like
                                        [[ ____ ]].**
                                      </small>
                                    </div>
                                  </div>
                                  <div className="form-group mb-3 text-right">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      Update Settings
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="Gallery Image View Request Accepted Email">
                              <div
                                className="tab-pane fade active show"
                                id="v-pills-13"
                                role="tabpanel"
                                aria-labelledby="v-pills-tab-1"
                              >
                                <form
                                  action="https://weddemoadmin.abaris.in/admin/email-templates/update"
                                  method="POST"
                                >
                                  <input
                                    type="hidden"
                                    name="_token"
                                    defaultValue="TL7gVYMMi3cj9D3J88UFc4u0yeb2JH9IB6LAucU5"
                                  />{" "}
                                  <input
                                    type="hidden"
                                    name="identifier"
                                    defaultValue="gallery_image_view_request_accepted_email"
                                  />
                                  <div className="form-group row">
                                    <div className="col-md-2">
                                      <label className="col-from-label">
                                        Activation
                                      </label>
                                    </div>
                                    <div className="col-md-10">
                                      <label className="aiz-switch aiz-switch-success mb-0">
                                        <input
                                          defaultValue={1}
                                          name="status"
                                          type="checkbox"
                                          defaultChecked
                                        />
                                        <span className="slider round" />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-2 col-form-label">
                                      Subject
                                    </label>
                                    <div className="col-md-10">
                                      <input
                                        type="text"
                                        name="subject"
                                        defaultValue="Gallery Image View Request Accepted."
                                        className="form-control"
                                        placeholder="Subject"
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="col-md-2 col-form-label">
                                      Email Body
                                    </label>
                                    <div className="col-md-10">
                                      <textarea
                                        name="body"
                                        className="form-control aiz-text-editor"
                                        data-buttons='[["font", ["bold", "underline", "italic"]],["para", ["ul", "ol"]],["view", ["undo","redo"]]]'
                                        placeholder="Type.."
                                        data-min-height={300}
                                        required
                                        style={{ display: "none" }}
                                        defaultValue={
                                          "<p>Hi [[name]], </p><p>\n[[member_name]] has accepted your Gallery Image View Request<br></p><p>Please contact the&nbsp;administration&nbsp;team if you have any further questions. </p><p>\nThanks,\n</p><p> [[from]]</p>"
                                        }
                                      />
                                      <div className="note-editor note-frame card">
                                        <div className="note-dropzone">
                                          <div className="note-dropzone-message" />
                                        </div>
                                        <div
                                          className="note-toolbar card-header"
                                          role="toolbar"
                                        >
                                          <div className="note-btn-group btn-group note-font">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-bold"
                                              tabIndex={-1}
                                              title
                                              aria-label="Bold (CTRL+B)"
                                              data-original-title="Bold (CTRL+B)"
                                            >
                                              <i className="note-icon-bold" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-underline"
                                              tabIndex={-1}
                                              title
                                              aria-label="Underline (CTRL+U)"
                                              data-original-title="Underline (CTRL+U)"
                                            >
                                              <i className="note-icon-underline" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm note-btn-italic"
                                              tabIndex={-1}
                                              title
                                              aria-label="Italic (CTRL+I)"
                                              data-original-title="Italic (CTRL+I)"
                                            >
                                              <i className="note-icon-italic" />
                                            </button>
                                          </div>
                                          <div className="note-btn-group btn-group note-para">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Unordered list (CTRL+SHIFT+NUM7)"
                                              data-original-title="Unordered list (CTRL+SHIFT+NUM7)"
                                            >
                                              <i className="note-icon-unorderedlist" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Ordered list (CTRL+SHIFT+NUM8)"
                                              data-original-title="Ordered list (CTRL+SHIFT+NUM8)"
                                            >
                                              <i className="note-icon-orderedlist" />
                                            </button>
                                          </div>
                                          <div className="note-btn-group btn-group note-view">
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Undo (CTRL+Z)"
                                              data-original-title="Undo (CTRL+Z)"
                                            >
                                              <i className="note-icon-undo" />
                                            </button>
                                            <button
                                              type="button"
                                              className="note-btn btn btn-light btn-sm"
                                              tabIndex={-1}
                                              title
                                              aria-label="Redo (CTRL+Y)"
                                              data-original-title="Redo (CTRL+Y)"
                                            >
                                              <i className="note-icon-redo" />
                                            </button>
                                          </div>
                                        </div>
                                        <div className="note-editing-area">
                                          <div
                                            className="note-placeholder"
                                            style={{ display: "none" }}
                                          >
                                            Type..
                                          </div>
                                          <div className="note-handle">
                                            <div className="note-control-selection">
                                              <div className="note-control-selection-bg" />
                                              <div className="note-control-holder note-control-nw" />
                                              <div className="note-control-holder note-control-ne" />
                                              <div className="note-control-holder note-control-sw" />
                                              <div className="note-control-sizing note-control-se" />
                                              <div className="note-control-selection-info" />
                                            </div>
                                          </div>
                                          <textarea
                                            className="note-codable"
                                            aria-multiline="true"
                                            defaultValue={""}
                                          />
                                          <div
                                            className="note-editable card-block"
                                            contentEditable="true"
                                            role="textbox"
                                            aria-multiline="true"
                                            spellCheck="true"
                                            autoCorrect="true"
                                            style={{ height: 300 }}
                                          >
                                            <p>Hi [[name]], </p>
                                            <p>
                                              [[member_name]] has accepted your
                                              Gallery Image View Request
                                              <br />
                                            </p>
                                            <p>
                                              Please contact
                                              the&nbsp;administration&nbsp;team
                                              if you have any further questions.{" "}
                                            </p>
                                            <p>Thanks,</p>
                                            <p> [[from]]</p>
                                          </div>
                                        </div>
                                        <output
                                          className="note-status-output"
                                          role="status"
                                          aria-live="polite"
                                        />
                                        <div
                                          className="note-statusbar"
                                          role="status"
                                        >
                                          <output
                                            className="note-status-output"
                                            aria-live="polite"
                                          />
                                          <div
                                            className="note-resizebar"
                                            aria-label="Resize"
                                          >
                                            <div className="note-icon-bar" />
                                            <div className="note-icon-bar" />
                                            <div className="note-icon-bar" />
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal link-dialog"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Link"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Link
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group">
                                                  <label
                                                    htmlFor="note-dialog-link-txt-167757967383813"
                                                    className="note-form-label"
                                                  >
                                                    Text to display
                                                  </label>
                                                  <input
                                                    id="note-dialog-link-txt-167757967383813"
                                                    className="note-link-text form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                                <div className="form-group note-form-group">
                                                  <label
                                                    htmlFor="note-dialog-link-url-167757967383813"
                                                    className="note-form-label"
                                                  >
                                                    To what URL should this link
                                                    go?
                                                  </label>
                                                  <input
                                                    id="note-dialog-link-url-167757967383813"
                                                    className="note-link-url form-control note-form-control note-input"
                                                    type="text"
                                                    defaultValue="http://"
                                                  />
                                                </div>
                                                <div className="form-check sn-checkbox-open-in-new-window">
                                                  <label className="form-check-label">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      defaultChecked
                                                      aria-label="Open in new window"
                                                      aria-checked="true"
                                                    />{" "}
                                                    Open in new window
                                                  </label>
                                                </div>
                                                <div className="form-check sn-checkbox-use-protocol">
                                                  <label className="form-check-label">
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      defaultChecked
                                                      aria-label="Use default protocol"
                                                      aria-checked="true"
                                                    />{" "}
                                                    Use default protocol
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-link-btn"
                                                  defaultValue="Insert Link"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-link-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <span>
                                              <a target="_blank" />
                                              &nbsp;
                                            </span>
                                            <div className="note-btn-group btn-group note-link">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Edit"
                                                data-original-title="Edit"
                                              >
                                                <i className="note-icon-link" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Unlink"
                                                data-original-title="Unlink"
                                              >
                                                <i className="note-icon-chain-broken" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Image"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Image
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group note-group-select-from-files">
                                                  <label
                                                    htmlFor="note-dialog-image-file-167757967383813"
                                                    className="note-form-label"
                                                  >
                                                    Select from files
                                                  </label>
                                                  <input
                                                    id="note-dialog-image-file-167757967383813"
                                                    className="note-image-input form-control-file note-form-control note-input"
                                                    type="file"
                                                    name="files"
                                                    accept="image/*"
                                                    multiple="multiple"
                                                  />
                                                </div>
                                                <div className="form-group note-group-image-url">
                                                  <label
                                                    htmlFor="note-dialog-image-url-167757967383813"
                                                    className="note-form-label"
                                                  >
                                                    Image URL
                                                  </label>
                                                  <input
                                                    id="note-dialog-image-url-167757967383813"
                                                    className="note-image-url form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-image-btn"
                                                  defaultValue="Insert Image"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-image-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <div className="note-btn-group btn-group note-resize">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize full"
                                                data-original-title="Resize full"
                                              >
                                                <span className="note-fontsize-10">
                                                  100%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize half"
                                                data-original-title="Resize half"
                                              >
                                                <span className="note-fontsize-10">
                                                  50%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Resize quarter"
                                                data-original-title="Resize quarter"
                                              >
                                                <span className="note-fontsize-10">
                                                  25%
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Original size"
                                                data-original-title="Original size"
                                              >
                                                <i className="note-icon-rollback" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-float">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Float Left"
                                                data-original-title="Float Left"
                                              >
                                                <i className="note-icon-float-left" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Float Right"
                                                data-original-title="Float Right"
                                              >
                                                <i className="note-icon-float-right" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Remove float"
                                                data-original-title="Remove float"
                                              >
                                                <i className="note-icon-rollback" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-remove">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm"
                                                tabIndex={-1}
                                                title
                                                aria-label="Remove Image"
                                                data-original-title="Remove Image"
                                              >
                                                <i className="note-icon-trash" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="note-popover popover in note-table-popover bottom">
                                          <div className="arrow" />
                                          <div className="popover-content note-children-container">
                                            <div className="note-btn-group btn-group note-add">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add row below"
                                                data-original-title="Add row below"
                                              >
                                                <i className="note-icon-row-below" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add row above"
                                                data-original-title="Add row above"
                                              >
                                                <i className="note-icon-row-above" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add column left"
                                                data-original-title="Add column left"
                                              >
                                                <i className="note-icon-col-before" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Add column right"
                                                data-original-title="Add column right"
                                              >
                                                <i className="note-icon-col-after" />
                                              </button>
                                            </div>
                                            <div className="note-btn-group btn-group note-delete">
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete row"
                                                data-original-title="Delete row"
                                              >
                                                <i className="note-icon-row-remove" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete column"
                                                data-original-title="Delete column"
                                              >
                                                <i className="note-icon-col-remove" />
                                              </button>
                                              <button
                                                type="button"
                                                className="note-btn btn btn-light btn-sm btn-md"
                                                tabIndex={-1}
                                                title
                                                aria-label="Delete table"
                                                data-original-title="Delete table"
                                              >
                                                <i className="note-icon-trash" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Insert Video"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Insert Video
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <div className="form-group note-form-group row-fluid">
                                                  <label
                                                    htmlFor="note-dialog-video-url-167757967383813"
                                                    className="note-form-label"
                                                  >
                                                    Video URL{" "}
                                                    <small className="text-muted">
                                                      (YouTube, Vimeo, Vine,
                                                      Instagram, DailyMotion or
                                                      Youku)
                                                    </small>
                                                  </label>
                                                  <input
                                                    id="note-dialog-video-url-167757967383813"
                                                    className="note-video-url form-control note-form-control note-input"
                                                    type="text"
                                                  />
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <input
                                                  type="button"
                                                  href="#"
                                                  className="btn btn-primary note-btn note-btn-primary note-video-btn"
                                                  defaultValue="Insert Video"
                                                  disabled
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal note-modal"
                                          aria-hidden="false"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-label="Help"
                                        >
                                          <div className="modal-dialog">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h4 className="modal-title">
                                                  Help
                                                </h4>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                  aria-hidden="true"
                                                >
                                                  ×
                                                </button>
                                              </div>
                                              <div
                                                className="modal-body"
                                                style={{
                                                  maxHeight: 300,
                                                  overflow: "scroll",
                                                }}
                                              >
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>ENTER</kbd>
                                                </label>
                                                <span>Insert Paragraph</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+Z</kbd>
                                                </label>
                                                <span>
                                                  Undoes the last command
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+Y</kbd>
                                                </label>
                                                <span>
                                                  Redoes the last command
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>TAB</kbd>
                                                </label>
                                                <span>Tab</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>SHIFT+TAB</kbd>
                                                </label>
                                                <span>Untab</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+B</kbd>
                                                </label>
                                                <span>Set a bold style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+I</kbd>
                                                </label>
                                                <span>Set a italic style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+U</kbd>
                                                </label>
                                                <span>
                                                  Set a underline style
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+S</kbd>
                                                </label>
                                                <span>
                                                  Set a strikethrough style
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+BACKSLASH</kbd>
                                                </label>
                                                <span>Clean a style</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+L</kbd>
                                                </label>
                                                <span>Set left align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+E</kbd>
                                                </label>
                                                <span>Set center align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+R</kbd>
                                                </label>
                                                <span>Set right align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+J</kbd>
                                                </label>
                                                <span>Set full align</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+NUM7</kbd>
                                                </label>
                                                <span>
                                                  Toggle unordered list
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+SHIFT+NUM8</kbd>
                                                </label>
                                                <span>Toggle ordered list</span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+LEFTBRACKET</kbd>
                                                </label>
                                                <span>
                                                  Outdent on current paragraph
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+RIGHTBRACKET</kbd>
                                                </label>
                                                <span>
                                                  Indent on current paragraph
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM0</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as a paragraph(P tag)
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM1</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H1
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM2</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H2
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM3</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H3
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM4</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H4
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM5</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H5
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+NUM6</kbd>
                                                </label>
                                                <span>
                                                  Change current block's format
                                                  as H6
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+ENTER</kbd>
                                                </label>
                                                <span>
                                                  Insert horizontal rule
                                                </span>
                                                <div className="help-list-item" />
                                                <label
                                                  style={{
                                                    width: 180,
                                                    marginRight: 10,
                                                  }}
                                                >
                                                  <kbd>CTRL+K</kbd>
                                                </label>
                                                <span>Show Link Dialog</span>
                                              </div>
                                              <div className="modal-footer">
                                                <p className="text-center">
                                                  <a
                                                    href="http://summernote.org/"
                                                    target="_blank"
                                                  >
                                                    Summernote 0.8.16
                                                  </a>{" "}
                                                  ·{" "}
                                                  <a
                                                    href="https://github.com/summernote/summernote"
                                                    target="_blank"
                                                  >
                                                    Project
                                                  </a>{" "}
                                                  ·{" "}
                                                  <a
                                                    href="https://github.com/summernote/summernote/issues"
                                                    target="_blank"
                                                  >
                                                    Issues
                                                  </a>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <small className="form-text text-danger">
                                        **N.B : Do Not Change The Variables Like
                                        [[ ____ ]].**
                                      </small>
                                    </div>
                                  </div>
                                  <div className="form-group mb-3 text-right">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      Update Settings
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </div>
                      </Col>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
      </div>
    </>
  );
}
export default EmailTemplet;
